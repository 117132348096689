'use strict';

angular.module('nfseApp')
    .controller('MainController', function ($rootScope, $scope, $filter, Principal, localStorageService, $state, NotaFiscal, DeclaracaoMensalServico,
                                            TrocaEmpresa, MensagemContribuinteUsuarioService, $modal) {

        $scope.competencias = [];
        $scope.competenciaAtual = {};
        $scope.compenteciaAnterior = {};
        $scope.hoje = new Date();
        $scope.abrirTrocarEmpresa = abrirTrocarEmpresa;

        $scope.loadNotas = function () {
            DeclaracaoMensalServico.getEstatisticasDeclaradas({}, function (data) {
                $scope.competencias = data;
                for (var i = 0; i < $scope.competencias.length; i++) {
                    var competencia = $scope.competencias[i];
                    if (competencia.mes === $scope.hoje.getMonth() + 1) {
                        $scope.competenciaAtual = competencia;
                    }
                    if (competencia.mes === $scope.hoje.getMonth()) {
                        $scope.compenteciaAnterior = competencia;
                    }
                }
                if ($scope.prestadorPrincipal.prestador.enquadramentoFiscal &&
                    !$scope.prestadorPrincipal.prestador.enquadramentoFiscal.simplesNacional) {
                    $scope.montarGraficoFaturamento();
                } else {
                    $scope.montarGraficoRbt12();
                }
            });
        };

        $scope.role = function (role) {
            return Principal.isInRole(role);
        };


        $scope.calculaCompetencia = function () {
            if ($scope.competenciaAtual && $scope.compenteciaAnterior) {
                $scope.competenciaAtual.porcentagemEmissao =
                    $scope.calculaCrescimento($scope.compenteciaAnterior.emitidas, $scope.competenciaAtual.emitidas);
                $scope.competenciaAtual.porcentagemRetencao =
                    $scope.calculaCrescimento($scope.compenteciaAnterior.retidas, $scope.competenciaAtual.retidas);
                $scope.competenciaAtual.porcentagemCancelamento =
                    $scope.calculaCrescimento($scope.compenteciaAnterior.canceladas, $scope.competenciaAtual.canceladas);
                $scope.competenciaAtual.porcentagemValor =
                    $scope.calculaCrescimento($scope.compenteciaAnterior.totalServicos, $scope.competenciaAtual.totalServicos);
                $scope.competenciaAtual.porcentagemIss =
                    $scope.calculaCrescimento($scope.compenteciaAnterior.iss, $scope.competenciaAtual.iss);
            } else {
                $scope.competenciaAtual.porcentagemEmissao = 0;
                $scope.competenciaAtual.porcentagemCancelamento = 0;
                $scope.competenciaAtual.porcentagemRetencao = 0;
                $scope.competenciaAtual.porcentagemValor = 0;
                $scope.competenciaAtual.porcentagemIss = 0;
            }
        };

        $scope.calculaCrescimento = function (anterior, atual) {
            var diferenca = atual - anterior;
            if (diferenca !== 0) {
                diferenca = diferenca / anterior;
                return diferenca * 100;
            } else {
                return 0;
            }
        }

        $scope.valorMedioMensal = 0;
        $scope.quantidadeMediaMensal = 0;

        $scope.montarGraficoFaturamento = function () {
            var meses = [], valorServicos = [], valorIss = [];

            var valorTotal = 0;
            var quantidadeTotal = 0;
            var mesesQueTemNota = 0;
            angular.forEach($scope.competencias, function (nota) {
                meses.push(nota.ano + '/'+ $filter('translate')('nfseApp.Mes.' + nota.mes));

                valorServicos.push(nota.totalServicos);
                valorIss.push(nota.iss);
                if (nota.totalServicos && nota.totalServicos > 0) {
                    valorTotal = valorTotal + nota.totalServicos;
                    quantidadeTotal = quantidadeTotal + nota.emitidas;
                    mesesQueTemNota = mesesQueTemNota + 1;
                }
            });
            $scope.valorMedioMensal = valorTotal / mesesQueTemNota;
            $scope.quantidadeMediaMensal = quantidadeTotal / mesesQueTemNota;

            $scope.lineData = {
                labels: meses,
                datasets: [
                    {
                        label: "Faturamento",
                        fillColor: "rgba(26,179,148,0.5)",
                        strokeColor: "rgba(26,179,148,0.7)",
                        pointColor: "rgba(26,179,148,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(26,179,148,1)",
                        data: valorServicos
                    },
                    {
                        label: "ISS",
                        fillColor: "rgba(26, 123, 185, 1)",
                        strokeColor: "rgba(26,123,148,0.7)",
                        pointColor: "rgba(26,123,148,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(26,123,148,1)",
                        data: valorIss
                    }
                ]
            };

            $scope.calculaCompetencia();
        };

        $scope.montarGraficoRbt12 = function () {
            var receitas = $scope.prestadorPrincipal.prestador.ultimasReceitasTributariaBruta;

            var meses = [], valorRbt = [];

            angular.forEach(receitas, function (receita) {
                meses.push(receita.ano + '/' + $filter('translate')('nfseApp.Mes.' + receita.mes));
                valorRbt.push(receita.valorBruto);
            });

            $scope.lineData = {
                labels: meses,
                datasets: [
                    {
                        label: "Receita Bruta",
                        fillColor: "rgba(26,179,148,0.5)",
                        strokeColor: "rgba(26,179,148,0.7)",
                        pointColor: "rgba(26,179,148,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(26,179,148,1)",
                        data: valorRbt
                    }
                ]
            };
        };

        $scope.lineOptions = {
            scaleShowGridLines: true,
            scaleGridLineColor: "rgba(0,0,0,.05)",
            scaleGridLineWidth: 1,
            bezierCurve: true,
            bezierCurveTension: 0.4,
            pointDot: true,
            pointDotRadius: 4,
            pointDotStrokeWidth: 1,
            pointHitDetectionRadius: 20,
            datasetStroke: true,
            datasetStrokeWidth: 2,
            datasetFill: true
        };


        function abrirTrocarEmpresa() {
            TrocaEmpresa.open();
        }

        $scope.init = function () {
            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.isAuthenticated = Principal.isAuthenticated;
                $scope.prestadorPrincipal = localStorageService.get("prestadorPrincipal");
                if ($scope.prestadorPrincipal && !$scope.prestadorPrincipal.permitido && $scope.prestadorPrincipal.prestador) {
                    $state.go("documentacaoCredenciamento", {id: $scope.prestadorPrincipal.prestador.id});
                } else if ($scope.prestadorPrincipal) {
                    $scope.loadNotas();
                }
            });
        }

        $scope.init();
    });
