'use strict';

angular.module('nfseApp')
    .controller('ServicoDeclaradoController',
        function ($scope, $window, ServicoDeclarado, NotaFiscalSearch, ImpressaoPdf, $modal, ParseLinks, ExportarXls,
                  localStorageService, $http, SweetAlert) {
            $scope.per_page = 10;
            $scope.empresa = localStorageService.get("prestadorPrincipal");

            $scope.buscarOperacoes = function (tipo) {
                var operacoes = [];
                operacoes.push({nome: 'Igual', value: 'IGUAL'});
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Maior ou igual', value: 'MAIOR_IGUAL'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Menor ou igual', value: 'MENOR_IGUAL'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Menor', value: 'MENOR'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Maior', value: 'MAIOR'});
                }
                if (tipo === 'texto') {
                    operacoes.push({nome: 'Contém', value: 'LIKE'});
                }
                return operacoes;
            };

            $scope.filtrosDisponiveis = [
                {
                    nomeAtributo: 'lower(dadospessoaistomador.nomerazaosocial)',
                    tipo: 'texto',
                    descricao: 'Tomador - Nome/Razão Social',
                    operacoes: $scope.buscarOperacoes('texto')
                },
                {
                    nomeAtributo: 'dadospessoaistomador.cpfcnpj',
                    tipo: 'texto',
                    descricao: 'Tomador - CPF/CNPJ',
                    operacoes: $scope.buscarOperacoes('texto')
                },
                {
                    nomeAtributo: 'lower(dadospessoaisprestador.nomerazaosocial)',
                    tipo: 'texto',
                    descricao: 'Prestador - Nome/Razão Social',
                    operacoes: $scope.buscarOperacoes('texto')
                },
                {
                    nomeAtributo: 'dadospessoaisprestador.cpfcnpj',
                    tipo: 'texto',
                    descricao: 'Prestador - CPF/CNPJ',
                    operacoes: $scope.buscarOperacoes('texto')
                },
                {
                    nomeAtributo: 'coalesce(nota.numero, sd.numero)',
                    tipo: 'inteiro',
                    descricao: 'Número da Nota',
                    operacoes: $scope.buscarOperacoes('inteiro')
                },
                {
                    nomeAtributo: 'coalesce(nota.homologacao, false)',
                    tipo: 'logico',
                    descricao: 'Homologação',
                    operacoes: $scope.buscarOperacoes('logico')
                },
                {
                    nomeAtributo: 'dec.totalservicos',
                    tipo: 'decimal',
                    descricao: 'Valor dos Serviços',
                    operacoes: $scope.buscarOperacoes('decimal')
                },
                {
                    nomeAtributo: 'round(dec.isscalculado, 2)',
                    tipo: 'decimal',
                    descricao: 'Valor do Iss',
                    operacoes: $scope.buscarOperacoes('decimal')
                },
                {
                    nomeAtributo: "date_trunc('day', coalesce(nota.emissao, sd.emissao))",
                    tipo: 'data',
                    descricao: 'Emissão',
                    operacoes: $scope.buscarOperacoes('data')
                },
                {
                    nomeAtributo: "case when nota.id is not null then 'SERVICO_PRESTADO' else sd.tiposervicodeclarado end",
                    tipo: 'enum',
                    descricao: 'Tipo',
                    operacoes: $scope.buscarOperacoes('enum')
                }
            ];

            $scope.filtrosSelecionados = [];

            $scope.validarFiltros = function () {
                for (var i = 0; i < $scope.filtrosSelecionados.length; i++) {
                    if ($scope.filtrosSelecionados[i].atributo == null) {
                        SweetAlert.warning("Filtro Personalizado inválido!", "Selecione uma opção de filtro.");
                        return false;
                    }
                    if (!$scope.filtrosSelecionados[i].operacao) {
                        SweetAlert.warning("Filtro Personalizado inválido!", "Selecione um operador de filtro.");
                        return false;
                    }
                    if (!$scope.filtrosSelecionados[i].valorString && $scope.filtrosSelecionados[i].atributo.tipo === 'texto'
                     || !$scope.filtrosSelecionados[i].valorData && $scope.filtrosSelecionados[i].atributo.tipo === 'data'
                     || !$scope.filtrosSelecionados[i].valorDecimal && $scope.filtrosSelecionados[i].atributo.tipo === 'decimal'
                     || !$scope.filtrosSelecionados[i].valorInteger && $scope.filtrosSelecionados[i].atributo.tipo === 'inteiro') {
                        SweetAlert.warning("Filtro Personalizado inválido!", "Selecione um valor de filtro.");
                        return false;
                    }
                }
                return true;
            };

            $scope.montarConsultaServicoDeclarado = function () {
                var campos = [];

                if (!$scope.empresa.prestador.foraMunicipio) {
                    campos.push({
                        campo: "coalesce(nota.prestador_id, sd.cadastroeconomico_id)",
                        operador: "IGUAL",
                        valorLong: $scope.empresa.prestador.id
                    });
                } else {
                    campos.push({
                        campo: "coalesce(dadospessoaisprestador.pessoa_id, dadospessoaistomador.pessoa_id)",
                        operador: "IGUAL",
                        valorLong: $scope.empresa.prestador.id
                    });
                }
                var contemHomologacao = false;
                angular.forEach($scope.filtrosSelecionados, function (filtro) {
                    if (filtro.atributo.nomeAtributo === 'coalesce(nota.homologacao, false)')
                        contemHomologacao = true;
                    if (filtro.atributo.tipo === 'texto' && !filtro.valorString)
                        filtro.valorString = "";
                    campos.push(
                        {
                            operador: filtro.operacao,
                            campo: filtro.atributo.nomeAtributo,
                            valorString: (filtro.valorString ? filtro.atributo.tipo === 'enum' ? filtro.valorString.trim() : filtro.operacao !== 'LIKE' ? filtro.valorString.trim().toLowerCase() : '%' + filtro.valorString.trim().toLowerCase() + '%' : null),
                            valorDate: filtro.valorData,
                            valorLogico: filtro.valorLogico,
                            valorDecimal: filtro.valorDecimal,
                            valorInteger: filtro.valorInteger
                        }
                    );
                });

                if (!contemHomologacao)
                    campos.push({
                        operador: 'IGUAL',
                        campo: 'coalesce(nota.homologacao, false)',
                        valorLogico: false
                    });

                if (!$scope.orderBy) {
                    $scope.orderBy = "nota.numero";
                    $scope.sortReverse = true;
                }

                return {
                    offset: $scope.page,
                    limit: $scope.per_page,
                    parametrosQuery: [{
                        juncao: " and ",
                        parametroQueryCampos: campos
                    }],
                    orderBy: " order by " + $scope.orderBy + " " + ($scope.sortReverse ? 'desc' : 'asc')
                };
            };

            $scope.montarConsultaServicoDeclaradoRelatorios = function () {
                var campos = [];

                if (!$scope.empresa.prestador.foraMunicipio) {
                    campos.push({
                        campo: "coalesce(nota.prestador_id, sd.cadastroeconomico_id)",
                        operador: "IGUAL",
                        valorLong: $scope.empresa.prestador.id
                    });
                } else {
                    campos.push({
                        campo: "coalesce(dadospessoaisprestador.pessoa_id, dadospessoaistomador.pessoa_id)",
                        operador: "IGUAL",
                        valorLong: $scope.empresa.prestador.id
                    });
                }

                var contemHomologacao = false;
                angular.forEach($scope.filtrosSelecionados, function (filtro) {
                    if (filtro.atributo.nomeAtributo === 'coalesce(nota.homologacao, false)')
                        contemHomologacao = true;
                    if (filtro.atributo.tipo === 'texto' && !filtro.valorString)
                        filtro.valorString = "";
                    campos.push(
                        {
                            operador: filtro.operacao,
                            campo: filtro.atributo.nomeAtributo,
                            valorString: (filtro.valorString ? filtro.atributo.tipo === 'enum' ? filtro.valorString.trim() : filtro.operacao !== 'LIKE' ? filtro.valorString.trim().toLowerCase() : '%' + filtro.valorString.trim().toLowerCase() + '%' : null),
                            valorDate: filtro.valorData,
                            valorLogico: filtro.valorLogico,
                            valorDecimal: filtro.valorDecimal,
                            valorInteger: filtro.valorInteger
                        }
                    );
                });

                if (!contemHomologacao)
                    campos.push({
                        operador: 'IGUAL',
                        campo: 'coalesce(nota.homologacao, false)',
                        valorLogico: false
                    });
                return {
                    foraMunicipio: $scope.empresa.prestador.foraMunicipio,
                    idEmpresa: $scope.empresa.prestador.id,
                    parametros: [{
                        juncao: " and ",
                        parametroQueryCampos: campos
                    }]
                };
            };

            $scope.adicionarFiltro = function () {
                var filtro = {};
                $scope.filtrosSelecionados.push(filtro);
            };

            $scope.removerFiltro = function (index) {
                $scope.filtrosSelecionados.splice(index, 1);
                $scope.loadAll();
            };

            $scope.selecionouCampo = function (filtro) {
                filtro.valorString = null;
                filtro.valorDecimal = null;
                filtro.valorInteger = null;
                filtro.valorLogico = false;
                filtro.valorData = null;
            };


            $scope.loadAll = function () {
                if (!$scope.validarFiltros()) {
                    return;
                }
                var consultaServicos = $scope.montarConsultaServicoDeclarado();
                ServicoDeclarado.buscarServicosDeclarados(consultaServicos, function (result, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.servicosDeclarados = result;
                });
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.loadAll();

            $scope.imprimirNota = function (id) {
                ImpressaoPdf.imprimirPdfViaUrl('/publico/notaFiscal/imprime/' + id);
            };

            $scope.refresh = function () {
                $scope.loadAll();
                $scope.clear();
            };

            $scope.cancelarServicoDeclarado = function (servicoDeclarado) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/servico-declarado/cancelamento-servico-declarado.html',
                    controller: 'CancelamentoServicoDeclaradoController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return ServicoDeclarado.get({id: servicoDeclarado.id}).$promise;
                        }
                    }
                });
                modalInstance.result.then(function () {
                    $scope.loadAll();
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };

            $scope.baixarPDF = function () {
                $scope.sortReverse = false;
                var consultaServicos = $scope.montarConsultaServicoDeclaradoRelatorios();
                ImpressaoPdf.imprimirPdfViaPost('/api/servico-declarado/relatorio-pdf', consultaServicos);
            };

            $scope.baixarXls = function () {
                var consultaServicos = $scope.montarConsultaServicoDeclaradoRelatorios();
                $http.post('api/servico-declarado/relatorio-xls', consultaServicos, {responseType: 'arraybuffer'}).then(function (response) {
                    if (response.data.byteLength == 0) {
                        Notificacao.warn('Atenção', 'Nenhum registro para ser impresso.');
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/excel"
                        });
                        saveAs(blob, "ServicosDeclarados.xls");
                    }
                });
            };
        });
