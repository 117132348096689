'use strict';

angular.module('nfseApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('declaracaoMensalServicoCartorio', {
                parent: 'entity',
                url: '/dms-cartorio',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Declaração Mensal de Serviços (Cartório)'
                },
                ncyBreadcrumb: {
                    label: 'Consulta DMS (Cartório)'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServicoCartorio/declaracaoMensalServicoCartorio.html',
                        controller: 'DeclaracaoMensalServicoCartorioController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('situacaoDeclaracaoMensal');
                        $translatePartialLoader.addPart('situacaoParcela');
                        $translatePartialLoader.addPart('tipoDeclaracaoMensal');
                        $translatePartialLoader.addPart('tipoMovimentoMensal');
                        $translatePartialLoader.addPart('mes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('declaracaoMensalServicoCartorio.new', {
                parent: 'declaracaoMensalServicoCartorio',
                url: '/dms-cartorio/{mes}/{ano}',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Declaração Mensal de Serviços (Cartório)'
                },
                ncyBreadcrumb: {
                    label: 'Nova DMS (Cartório)'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServicoCartorio/declaracaoMensalServicoCartorio-edit.html',
                        controller: 'DeclaracaoMensalServicoCartorioEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'localStorageService', function ($stateParams, localStorageService) {
                        return {
                            tipoMovimento: 'CARTORIO',
                            prestador: localStorageService.get("prestadorPrincipal"),
                            mes: $stateParams.mes,
                            exercicio: $stateParams.ano,
                        };
                    }]
                }
            })
            .state('declaracaoMensalServicoCartorio.detail', {
                parent: 'declaracaoMensalServicoCartorio',
                url: '/detalhes/{id}',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Detalhes da Declaração Mensal de Serviços (Cartório)'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes da Declaração Mensal de Serviços (Cartório)'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServicoCartorio/declaracaoMensalServicoCartorio-detail.html',
                        controller: 'DeclaracaoMensalServicoCartorioDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['DeclaracaoMensalServico', '$stateParams', function (DeclaracaoMensalServico, $stateParams) {
                        return DeclaracaoMensalServico.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    });
