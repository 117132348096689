(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('BloqueioEmissaoNfseController',
            function ($scope, $sanitize, $modalInstance, bloqueio) {
                $scope.bloqueio = bloqueio;

                $scope.fechar = function () {
                    $modalInstance.dismiss('cancel');
                }

                $scope.trustAsHtml = function (value) {
                    return $sanitize(value);
                };
            });
})();
