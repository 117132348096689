angular.module('nfseApp')
    .controller('ServicoDeclaradoDetailController',
        function ($scope, $rootScope, $stateParams, entity) {
            $scope.servicoDeclarado = entity;

            $scope.definirDadosPessoais = function () {
                if (entity.$promise) {
                    entity.$promise.then(function (data) {
                        if (data.tipoServicoDeclarado == 'SERVICO_PRESTADO') {
                            $scope.servicoDeclarado.dadosPessoais = data.dadosPessoaisTomador;
                        } else {
                            $scope.servicoDeclarado.dadosPessoais = data.dadosPessoaisPrestador;
                        }
                    });
                }
            };

            $scope.definirDadosPessoais();

            $scope.isFisica = function () {
                return $scope.servicoDeclarado
                    && $scope.servicoDeclarado.dadosPessoais
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '').length == 11

            };
        });
