(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('PlanoGeralContasComentadoDetailController', function ($state, $scope,
                                                                           entity, Notificacao,
                                                                           PlanoGeralContasComentado,
                                                                           PrestadorServicos,
                                                                           localStorageService) {
            $scope.entity = entity;

            $scope.remove = function () {
                Notificacao.confirmDelete(function () {
                    PlanoGeralContasComentado.delete({id: $scope.entity.id}, function () {
                        $state.go('planoGeralContasComentado');
                    })
                })
            }

            $scope.init = function () {
                PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (data) {
                    $scope.prestador = data;
                })
            }

            $scope.init();
        });
})();
