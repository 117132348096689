'use strict';

angular.module('nfseApp').controller('ServicoDeclaradoEditController',
    ['$rootScope', '$scope', '$modal', '$state', '$filter', '$stateParams', 'entity', 'ServicoDeclarado',
        'localStorageService', 'Notificacao',
        'Tomador', 'Servico', 'Municipio', 'CEP', 'Pais', 'Configuracao', 'PrestadorServicos', 'ImpressaoPdf',
        function ($rootScope, $scope, $modal, $state, $filter, $stateParams, entity, ServicoDeclarado,
                  localStorageService, Notificacao,
                  Tomador, Servico, Municipio, CEP, Pais, Configuracao, PrestadorServicos, ImpressaoPdf) {

            $scope.mensagemCamposObrigatorios = [];
            $scope.itensDeclaracaoServico = [];
            $scope.aliquotas = [0.00, 2.00, 2.79, 3.00, 3.50, 3.84, 3.87, 4.23, 4.26, 4.31, 4.61, 4.65, 5.00];
            $scope.permiteExigibilidade = true;
            $scope.bloqueiaAliquota = false;
            $scope.mostraFormServicos = true;
            $scope.tiposServicosDeclarados = ServicoDeclarado.getAllTiposDocumentosServicoDeclarado();
            $scope.empresa = localStorageService.get("prestadorPrincipal");

            Configuracao.get({}, function (data) {
                $scope.municipio = data.municipio;
                $scope.permiteAlterarCompetenciaServicoDeclarado = data.permiteAlterarCompetenciaServicoDeclarado;
                $scope.clearServico();


                if ($scope.empresa.prestador.foraMunicipio) {
                    $scope.cpfCnpjDoTomador = $scope.empresa.prestador.pessoa.dadosPessoais.cpfCnpj;

                    if (entity.$promise) {
                        entity.$promise.then(function (nota) {
                            $scope.servicoDeclarado = nota;
                            $scope.calculaValoresDaNota();
                        });
                    } else {
                        $scope.servicoDeclarado = entity;
                        $scope.calculaValoresDaNota();
                    }

                } else {
                    PrestadorServicos.get({id: $scope.empresa.prestador.id}, function (data) {
                        $scope.tomador = data;
                        $scope.cpfCnpjDoTomador = $scope.tomador.pessoa.dadosPessoais.cpfCnpj;

                        if (entity.$promise) {
                            entity.$promise.then(function (nota) {
                                $scope.servicoDeclarado = nota;
                                $scope.calculaValoresDaNota();
                            });
                        } else {
                            $scope.servicoDeclarado = entity;
                            $scope.calculaValoresDaNota();
                        }
                    });
                }


            });


            $scope.$watch('editForm.cpfCnpj.$valid', function (newVal) {
                $scope.preLoadPrestador();
            });

            $scope.preLoadPrestador = function () {
                if ($scope.temCpfOuCnpj()) {
                    var cpfCnpj = $scope.servicoDeclarado.dadosPessoais.cpfCnpj;
                    $scope.servicoDeclarado.dadosPessoais = {};
                    $scope.servicoDeclarado.dadosPessoais.cpfCnpj = cpfCnpj;
                    if ($scope.cpfDigitadoIgualTomador()) {
                        Notificacao.error("Atenção", "Não é permitido que o Prestador de Serviços seja o próprio Tomador de Serviços.");
                    } else {
                        Tomador.getTomadorPorCpfCnpj({cpfCnpj: cpfCnpj}, function (result) {
                            if (!result.dadosPessoais) {
                                Tomador.getInPessoRepoByCpfCnpj({cpfCnpj: cpfCnpj}, function (result) {
                                    if (result.dadosPessoais && result.dadosPessoais.cpfCnpj) {
                                        result.prestador = localStorageService.get("prestadorPrincipal").prestador;
                                        $scope.servicoDeclarado.dadosPessoais = result.dadosPessoais;
                                    }
                                });
                            } else {
                                $scope.servicoDeclarado.dadosPessoais = result.dadosPessoais;
                            }
                        });
                    }
                }
            };


            $scope.searchPrestadorServico = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/tomador/tomador-search.html',
                    controller: 'TomadorSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (tomador) {

                    Tomador.get({id: tomador.id}, function (data) {
                        if (data && data.dadosPessoais) {
                            $scope.servicoDeclarado.dadosPessoais = {};
                            $scope.servicoDeclarado.dadosPessoais = data.dadosPessoais;
                            $scope.servicoDeclarado.dadosPessoais.id = null;
                            if ($scope.servicoDeclarado.dadosPessoais.endereco) {
                                $scope.servicoDeclarado.dadosPessoais.endereco.id = null;
                            }
                        }
                    });


                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };


            var onSaveFinished = function (result) {
                Notificacao.info("Registro salvo com sucesso!");
                $state.go("servicoDeclarado");
            };

            $scope.saveAndFinalize = function () {
                if ($scope.validarCamposObrigatorios()) {
                    if ($scope.servicoDeclarado.tipoServicoDeclarado == 'SERVICO_PRESTADO') {
                        $scope.servicoDeclarado.dadosPessoaisTomador = $scope.servicoDeclarado.dadosPessoais;
                    } else {
                        $scope.servicoDeclarado.dadosPessoaisPrestador = $scope.servicoDeclarado.dadosPessoais;
                    }
                    $scope.save();
                }
            };

            $scope.save = function () {
                if ($scope.validarCamposObrigatorios()) {
                    if ($scope.servicoDeclarado.id != null) {
                        ServicoDeclarado.update($scope.servicoDeclarado, onSaveFinished, function () {
                            $state.go('servicoDeclarado');
                        });
                    } else {
                        ServicoDeclarado.save($scope.servicoDeclarado, onSaveFinished, function () {
                            $state.go('servicoDeclarado');
                        });
                    }
                }
            };

            $scope.openCompetencia = function ($event) {
                $scope.status.openedCompetencia = true;
            };

            $scope.status = {openedCompetencia: false};

            $scope.clear = function () {
            };

            $scope.calculaValoresServico = function (servico) {
                servico.total = servico.valorServico * servico.quantidade;
                servico.baseCalculo = servico.total;

                if (servico.deducoes && servico.deducoes > 0) {
                    servico.baseCalculo = servico.baseCalculo - servico.deducoes;
                }
                servico.iss = servico.baseCalculo * (servico.aliquotaServico / 100);
            };

            $scope.clearServico = function () {
                $scope.servico = {
                    baseCalculo: 0.0,
                    valorServico: 0.0,
                    quantidade: 1,
                    iss: 0.0,
                    descontosCondicionados: 0.0,
                    descontosIncondicionados: 0.0,
                    deducoes: 0.0,
                    prestadoNoPais: true,
                    municipio: $scope.municipio
                };

            };


            $scope.validarAddServico = function () {
                $scope.mensagemCamposObrigatorios = [];
                if (!$scope.servico.nomeServico) {
                    $scope.mensagemCamposObrigatorios.push("O Serviço é obrigatório, informe o serviço prestado para continuar.");
                }
                if (!$scope.servico.descricao) {
                    $scope.mensagemCamposObrigatorios.push("O campo descrição do serviço é obrigatório.");
                }
                if (!$scope.servico.aliquotaServico || $scope.servico.aliquotaServico <= 0) {
                    $scope.mensagemCamposObrigatorios.push("O campo Alíquota é obrigatório e deve ser maior que zero.");
                }
                if ($scope.servico.deducoes > $scope.servico.total) {
                    $scope.mensagemCamposObrigatorios.push("Os valores de desconto não podem ser maiores que o valor total dos serviços.");
                }
                if (!$scope.servico.valorServico || $scope.servico.valorServico <= 0) {
                    $scope.mensagemCamposObrigatorios.push("O campo Valor do Serviço é obrigatório e deve ser maior que zero.");
                }
                if ($scope.mensagemCamposObrigatorios.length > 0) {
                    Notificacao.camposObrigatorios($scope.mensagemCamposObrigatorios, "warning");
                    return false;
                }
                return true;
            };


            $scope.continuarServico = function () {
                if ($scope.mostraFormServicos) {
                    $scope.addServico(true);
                } else {
                    $scope.calculaValoresDaNota();
                }
            };

            $scope.novoServico = function () {
                if ($scope.mostraFormServicos) {
                    $scope.addServico(false);
                } else {
                    $scope.mostraFormServicos = true
                }
            };

            $scope.addServico = function (continua) {
                if ($scope.validarAddServico()) {
                    if (!$scope.servicoDeclarado.itens) {
                        $scope.servicoDeclarado.itens = [];
                    }
                    $scope.servicoDeclarado.itens.push($scope.servico);
                    $scope.clearServico();
                    $scope.calculaValoresDaNota();
                    if (continua) {
                        $scope.mostrarValoresFinais = !$scope.mostrarValoresFinais;
                    }
                }

            };

            $scope.calculaValoresDaNota = function () {
                $scope.servicoDeclarado.totalServicos = 0.0;
                $scope.servicoDeclarado.baseCalculo = 0.0;
                $scope.servicoDeclarado.issCalculado = 0.0;
                $scope.servicoDeclarado.deducoes = 0.0;
                $scope.servicoDeclarado.valorLiquido = 0.0;
                if ($scope.servicoDeclarado.itens) {
                    angular.forEach($scope.servicoDeclarado.itens, function (servico) {
                        $scope.calculaValoresServico(servico);
                        $scope.servicoDeclarado.totalServicos = Number($scope.servicoDeclarado.totalServicos) + Number(servico.total);
                        $scope.servicoDeclarado.baseCalculo = Number($scope.servicoDeclarado.baseCalculo) + Number(servico.baseCalculo);
                        $scope.servicoDeclarado.issCalculado = Number($scope.servicoDeclarado.issCalculado) + Number(servico.iss);
                        $scope.servicoDeclarado.deducoes = Number($scope.servicoDeclarado.deducoes) + Number(servico.deducoes);
                    });
                    if ($scope.servicoDeclarado.issRetido) {
                        $scope.servicoDeclarado.valorLiquido = $scope.servicoDeclarado.totalServicos
                            - $scope.servicoDeclarado.issCalculado - $scope.servicoDeclarado.deducoes;
                        $scope.servicoDeclarado.issPagar = $scope.servicoDeclarado.issCalculado;
                    } else {
                        $scope.servicoDeclarado.valorLiquido = $scope.servicoDeclarado.totalServicos
                            - $scope.servicoDeclarado.deducoes;
                    }
                }
            };


            $scope.removeServico = function (index) {
                $scope.mostrarValoresFinais = false;
                $scope.servicoDeclarado.itens.splice(index, 1);
            };

            $scope.editaServico = function (index) {
                $scope.mostrarValoresFinais = false;
                $scope.servico = $scope.servicoDeclarado.itens[index];
                $scope.removeServico(index);
            };


            $scope.selecionouServico = function () {
                $scope.bloqueiaAliquota = false;
                if ($scope.servico && $scope.servico.servico) {
                    $scope.servico.nomeServico = $scope.servico.servico.descricao;
                    if (($scope.temExigibilidade() || $scope.exigibilidadeSuspensa()) && $scope.tomador.enquadramentoFiscal.tipoIss === "MENSAL") {
                        $scope.bloqueiaAliquota = true;
                        $scope.servico.aliquotaServico = $scope.servico.servico.aliquota;
                        $scope.calculaValoresServico($scope.servico);
                        return;
                    }
                    if ($scope.exigibilidadeNaoIncide()
                        || ($scope.tomador && $scope.tomador.enquadramentoFiscal.tipoIss === "MEI")
                        || (($scope.temExigibilidade() || $scope.exigibilidadeSuspensa())
                            && ($scope.tomador.enquadramentoFiscal.tipoIss === "FIXO" || $scope.tomador.enquadramentoFiscal.tipoIss === "ESTIMADO"))) {
                        $scope.bloqueiaAliquota = true;
                        $scope.servico.aliquotaServico = $scope.aliquotas[0];
                        $scope.calculaValoresServico($scope.servico);
                        return;
                    }
                    if ($scope.aliquotas.indexOf($scope.servico.servico.aliquota) >= 0) {
                        $scope.servico.aliquotaServico = $scope.servico.servico.aliquota;
                        $scope.calculaValoresServico($scope.servico);
                    }
                }
            };

            $scope.searchServico = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/servico/servico-search.html',
                    controller: 'ServicoSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (result) {
                    $scope.servico.servico = result;
                    $scope.selecionouServico();
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };

            $scope.validarCamposObrigatorios = function () {
                $scope.mensagemCamposObrigatorios = [];

                if ($scope.servicoDeclarado.tipoDocumentoServicoDeclarado === null) {
                    $scope.mensagemCamposObrigatorios.push("O campo Tipo de Documento é obrigatório.");
                }
                if ($scope.servicoDeclarado.dadosPessoais.cpfCnpj === null) {
                    $scope.mensagemCamposObrigatorios.push("O campo CPF ou CNPJ do Prestador de Serviço é obrigatório.");
                }
                if ($scope.servicoDeclarado.dadosPessoais.nomeRazaoSocial === null) {
                    $scope.mensagemCamposObrigatorios.push("O campo Nome ou Razão do Prestador Social é obrigatório.");
                }

                if ($scope.servicoDeclarado.itens.length === 0) {
                    $scope.mensagemCamposObrigatorios.push("É necessário adicionar ao menos um serviço.");
                }
                if ($scope.mensagemCamposObrigatorios.length > 0) {
                    Notificacao.camposObrigatorios($scope.mensagemCamposObrigatorios, "warning");
                    return false;
                } else {
                    return true;
                }
            };


            $scope.loadEnderecoByCEP = function (cep) {
                if (cep) {
                    CEP.getByCep({cep: cep}, function (endereco) {
                        if (endereco.municipio) {
                            $scope.servicoDeclarado.dadosPessoais.cep = endereco.cep;
                            $scope.servicoDeclarado.dadosPessoais.municipio = endereco.municipio;
                            $scope.servicoDeclarado.dadosPessoais.logradouro = endereco.logradouro;
                            $scope.servicoDeclarado.dadosPessoais.bairro = endereco.bairro;
                        }
                    });
                }
            };

            $scope.loadPaisByCodigo = function (codigo, donoDoServico) {
                if (codigo) {
                    Pais.getByCodigo({codigo: codigo}, function (pais) {
                        if (pais.id) {
                            donoDoServico.pais = pais;
                            donoDoServico.municipio = {};
                        } else {
                            Notificacao.warn("Atenção", "Não foi encontrado nenhum País com o código " + codigo);
                            donoDoServico.pais = {};
                        }
                    });
                }
            };

            $scope.searchMunicipio = function (caraQueTemMunicipio) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/municipio/municipio-search.html',
                    controller: 'MunicipioSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (municipio) {
                    caraQueTemMunicipio.municipio = municipio;
                    caraQueTemMunicipio.pais = {};
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };

            $scope.searchPais = function (donoDoServico) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/pais/pais-search.html',
                    controller: 'PaisSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (pais) {
                    donoDoServico.pais = pais;
                    if ($scope.servicoDeclarado.modalidade && $scope.servicoDeclarado.modalidade != 'SEM_CPF')
                        donoDoServico.municipio = {};
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };

            $scope.temCpfOuCnpj = function () {
                return $scope.servicoDeclarado
                    && $scope.servicoDeclarado.dadosPessoais
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj;
            };

            $scope.cpfDigitadoIgualTomador = function () {
                return $scope.temCpfOuCnpj()
                    && $scope.cpfCnpjDoTomador
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '')
                    === $scope.cpfCnpjDoTomador.replace(/[^\w\s]/gi, '');
            }

            $scope.temExigibilidade = function () {
                return $scope.notaFiscal
                    && $scope.servicoDeclarado
                    && $scope.servicoDeclarado.naturezaOperacao
                    && $scope.servicoDeclarado.naturezaOperacao === "TRIBUTACAO_MUNICIPAL";
            }

            $scope.exigibilidadeSuspensa = function () {
                return $scope.notaFiscal
                    && $scope.servicoDeclarado
                    && $scope.servicoDeclarado.naturezaOperacao
                    && ($scope.servicoDeclarado.naturezaOperacao === "EXIGIBILIDADE_SUSPENSA_DECISAO_JUDICIAL"
                        || $scope.servicoDeclarado.naturezaOperacao === "EXIGIBILIDADE_SUSPENSA_PROCESSO_ADMINISTRATIVO");
            };

            $scope.exigibilidadeNaoIncide = function () {
                return $scope.servicoDeclarado
                    && $scope.servicoDeclarado
                    && $scope.servicoDeclarado.naturezaOperacao
                    && ($scope.servicoDeclarado.naturezaOperacao === "NAO_INCIDENCIA"
                        || $scope.servicoDeclarado.naturezaOperacao === "ISENCAO"
                        || $scope.servicoDeclarado.naturezaOperacao === "IMUNIDADE"
                        || $scope.servicoDeclarado.naturezaOperacao === "EXPORTACAO");
            };

            $scope.isFisica = function () {
                return $scope.servicoDeclarado
                    && (($scope.servicoDeclarado.modalidade == 'SEM_CPF') ||
                        ($scope.servicoDeclarado.dadosPessoais
                            && $scope.servicoDeclarado.dadosPessoais.cpfCnpj
                            && $scope.servicoDeclarado.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '').length == 11));

            };

            $scope.isJuridica = function () {
                return $scope.servicoDeclarado
                    && $scope.servicoDeclarado.dadosPessoais
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj
                    && $scope.servicoDeclarado.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '').length == 14

            };

            $scope.seleciouTipoServicoDeclarado = function (tipoServicoDeclarado) {
                if (!$scope.servicoDeclarado.tipoServicoDeclarado) {
                    ServicoDeclarado.new({
                            tipoServicoDeclarado: tipoServicoDeclarado,
                            empresaId: $scope.empresa.prestador.id,
                            foraMunicipio: $scope.empresa.prestador.foraMunicipio
                        },
                        function (data) {
                            $scope.servicoDeclarado = data;
                        });
                } else {
                    Notificacao.confirm('Atenção!', 'Tem certeza que deseja alterar o tipo de serviço, os dados digitados serão apagados!', 'warning',
                        function () {
                            ServicoDeclarado.new({
                                tipoServicoDeclarado: tipoServicoDeclarado,
                                empresaId: $scope.empresa.prestador.id,
                                foraMunicipio: $scope.empresa.prestador.foraMunicipio
                            }, function (data) {
                                $scope.servicoDeclarado = data;
                            });
                        })
                }
            };

            $scope.mudouIdentificacao = function (modalidade) {
                $scope.servicoDeclarado.modalidade = modalidade;
                $scope.servicoDeclarado.dadosPessoais = {};
            };

            $scope.atribuirCompetencia = function () {
                if (!$scope.permiteAlterarCompetenciaServicoDeclarado)
                    $scope.servicoDeclarado.competencia = $scope.servicoDeclarado.emissao;
            };
        }]);
