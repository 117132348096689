'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoRetencaoEditController',
    function ($scope, $state, $timeout, $stateParams, DeclaracaoMensalServico, NotaFiscal,
              Notificacao, localStorageService, $modal, SweetAlert) {

        $scope.per_page = 10;
        $scope.ano = $stateParams.ano;
        $scope.mes = $stateParams.mes;
        $scope.tipoMovimento = 'RETENCAO';
        $scope.notas = [];
        $scope.meses = DeclaracaoMensalServico.getMeses();
        $scope.declaracaoMensalServico = null;

        function carregar() {
            $scope.prestador = localStorageService.get("prestadorPrincipal").prestador;

            DeclaracaoMensalServico.getMeses({},
                function (data) {
                    $scope.meses = data;
                    angular.forEach(data, function (mes) {
                        if (mes.numeroMes == $scope.mes) {
                            $scope.mes = mes;
                            $scope.acessarCompetencia();
                        }
                    });
                });
        }

        carregar();

        var onSaveFinished = function (result) {
            Notificacao.priority("Registro salvo com sucesso", "");
            $scope.$emit('nfseApp:declaracaoMensalServicoUpdate', result);
            $scope.$emit('nfseApp:navbarUpdate');
            $state.go('declaracaoMensalServicoRetencao');
        };

        $scope.save = function () {
            if ($scope.declaracaoMensalServico.qtdNotas === 0) {
                SweetAlert.swal({
                        title: "Declaração de Ausência de Movimento",
                        text: "Você tem certeza que deseja declarar ausência de movimento para o período " + $scope.declaracaoMensalServico.mes + "/" + $scope.declaracaoMensalServico.exercicio + "?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Sim, Declarar",
                        cancelButtonText: "Não, Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            DeclaracaoMensalServico.save($scope.declaracaoMensalServico, onSaveFinished);
                        }
                    });
            } else {
                DeclaracaoMensalServico.save($scope.declaracaoMensalServico, onSaveFinished);
            }
        };

        $scope.acessarCompetencia = function () {
            DeclaracaoMensalServico.novaDms({
                    mes: $scope.mes.numeroMes,
                    ano: $scope.ano,
                    tipoMovimento: $scope.tipoMovimento,
                    empresaId: $scope.prestador.id,
                    foraMunicipio: $scope.prestador.foraMunicipio
                },
                function (result) {
                    $scope.declaracaoMensalServico = result;
                }
            );
        };

        $scope.selecionarNotas = function () {
            var modal = $modal.open({
                templateUrl: 'app/entities/declaracaoMensalServicoRetencao/pesquisaNotasRecebidas.html',
                controller: 'PesquisaNotasRecebidasController',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tipoDocumento');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return $scope.declaracaoMensalServico;
                    }
                }
            });
            modal.result.then(function (data) {
                if (data) {
                    $scope.declaracaoMensalServico.notas = data;
                    $scope.declaracaoMensalServico.qtdNotas = 0;
                    $scope.declaracaoMensalServico.totalServicos = 0;
                    $scope.declaracaoMensalServico.totalIss = 0;
                    for (var i = 0; i < $scope.declaracaoMensalServico.notas.length; i++) {
                        $scope.declaracaoMensalServico.qtdNotas = $scope.declaracaoMensalServico.qtdNotas + 1;
                        $scope.declaracaoMensalServico.totalServicos = $scope.declaracaoMensalServico.totalServicos +
                            $scope.declaracaoMensalServico.notas[i].totalServicos;
                        $scope.declaracaoMensalServico.totalIss = $scope.declaracaoMensalServico.totalIss +
                            $scope.declaracaoMensalServico.notas[i].issCalculado;

                    }
                }
            });
        }
    });

