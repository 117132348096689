(function () {
    'use strict';

    angular.module('nfseApp')
        .factory('PlanoGeralContasComentado', function ($resource, DateUtils) {
            function transformResponseList(data) {
                data = angular.fromJson(data);
                for (var i = 0; i < data.length; i++) {
                    var item = data[i];
                    transformResponse(item);
                }
                return data;
            }

            function transformResponse(data) {
                data = angular.fromJson(data);
                if (data.inicioVigencia) {
                    data.inicioVigencia = DateUtils.convertLocaleDateFromServer(data.inicioVigencia);
                }
                if (data.fimVigencia) {
                    data.fimVigencia = DateUtils.convertLocaleDateFromServer(data.fimVigencia);
                }
                if (data.tarifaBancaria) {
                    data.tarifaBancaria.inicioVigencia = DateUtils.convertLocaleDateFromServer(data.tarifaBancaria.inicioVigencia);
                }
                return data;
            }

            var resource = $resource('api/plano-geral-contas-comentado/:id', {}, {
                'get': {
                    url: 'api/plano-geral-contas-comentado/:id',
                    method: 'GET',
                    transformResponse: function (data) {
                        return transformResponse(data);
                    }
                },
                'consultar': {
                    url: 'api/plano-geral-contas-comentado/consultar',
                    method: 'POST',
                    isArray: true,
                    transformResponse: function (data) {
                        return transformResponseList(data);
                    }
                },
                'buscarPorConta': {
                    url: 'api/plano-geral-contas-comentado/buscar-por-conta',
                    method: 'GET'
                },
                'buscarPorPrestador': {
                    url: 'api/plano-geral-contas-comentado/buscar-por-prestador',
                    method: 'GET',
                    isArray: true,
                    transformResponse: function (data) {
                        return transformResponseList(data);
                    }
                }
            });

            return resource;
        });
})();
