'use strict';

angular.module('nfseApp')
    .controller('ConstrucaoCivilEditController', function ($modal, $state, $scope, entity,
                                                           ConstrucaoCivil, localStorageService, CadastroImobiliario,
                                                           Notificacao, CEP, Configuracao, $timeout, Pessoa) {
        $scope.obra = entity;
        $scope.obra.prestador = localStorageService.get("prestadorPrincipal").prestador;
        $scope.inscricaoImovel = null;
        $scope.municipio;
        $scope.profissional;

        $timeout(function () {
            if ($scope.obra.imovel) {
                $scope.inscricaoImovel = $scope.obra.imovel.inscricaoCadastral;
            }
        }, 1000);

        $scope.municipio = Configuracao.get();


        $scope.save = function () {
            ConstrucaoCivil.save($scope.obra, function (data) {
                $state.go('construcaoCivil');
            });
        };

        $scope.buscarImovel = function () {
            if ($scope.inscricaoImovel) {
                CadastroImobiliario.byInscricao({inscricao: $scope.inscricaoImovel}, function (data) {
                    if (data.id) {
                        $scope.obra.imovel = data;
                    } else {
                        $scope.inscricaoImovel = null;
                        Notificacao.warn("Atenção!", "Imóvel não encontrado.");
                    }
                })
            }
        };

        $scope.searchImovel = function () {
            var modalInstance = $modal.open({
                templateUrl: 'app/entities/cadastro-imobiliario/cadastro-imobiliario-search.html',
                controller: 'CadastroImobiliarioSearchController',
                size: 'lg'
            });
            modalInstance.result.then(function (cadastro) {
                CadastroImobiliario.get({id: cadastro.id}, function (data) {
                    console.log(data);
                    if (data && data.id) {
                        $scope.obra.imovel = data;
                        $scope.inscricaoImovel = $scope.obra.imovel.inscricaoCadastral;
                    }
                });
            }, function () {

            });
        };

        $scope.loadEnderecoByCEP = function (cep) {
            if (cep) {
                CEP.getByCep({cep: cep}, function (endereco) {
                    if (endereco.municipio) {
                        $scope.obra.imovel = null;
                        $scope.obra.localizacao.cep = endereco.cep;
                        $scope.obra.localizacao.municipio = endereco.municipio;
                        $scope.obra.localizacao.logradouro = endereco.logradouro;
                        $scope.obra.localizacao.bairro = endereco.bairro;
                    }
                });
            }
        };

        $scope.searchMunicipio = function () {
            var modalInstance = $modal.open({
                templateUrl: 'app/entities/municipio/municipio-search.html',
                controller: 'MunicipioSearchController',
                size: 'lg'
            });
            modalInstance.result.then(function (municipio) {
                $scope.obra.imovel = null;
                $scope.obra.localizacao.municipio = municipio;
                $scope.obra.localizacao.pais = {};
            }, function () {

            });
        };

        $scope.habilitaImovel = function () {
            return $scope.obra &&
                $scope.obra.localizacao &&
                $scope.obra.localizacao.municipio &&
                $scope.obra.localizacao.municipio.id == $scope.municipio.id;
        };

        $scope.novoProfissional = function () {
            $scope.profissional = {};
        };

        $scope.adicionarProfissional = function () {
            if (!$scope.obra.profissionais) {
                $scope.obra.profissionais = [];
            }
            $scope.obra.profissionais.push($scope.profissional);
            $scope.profissional = null;
        };

        $scope.cancelarProfissional = function () {
            $scope.profissional = null;
        };

        $scope.removerProfissional = function (index) {
            Notificacao.confirmDelete(function () {
                $scope.obra.profissionais[index].remover = true;
            });

        };

        $scope.isFisica = function (dadosPessoais) {
            return dadosPessoais &&
                dadosPessoais.cpfCnpj &&
                dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '').length == 11;
        }

        $scope.preLoadProfissional = function () {
            if ($scope.profissional.profissional.cpfCnpj) {
                Pessoa.getPorCpfCnpj({cpfCnpj: $scope.profissional.profissional.cpfCnpj}, function (data) {
                    if (data.id) {
                        $scope.profissional.profissional = data.dadosPessoais;
                    }
                })
            }
        };
    });
