(function () {
    'use strict';

    angular.module('nfseApp')
        .factory('ArquivoDesif', function ($resource, DateUtils) {
            function transformResponseList(data) {
                data = angular.fromJson(data);
                for (var i = 0; i < data.length; i++) {
                    var nota = data[i];
                    transformResponse(nota);
                }
                return data;
            }

            function transformResponse(data) {
                data = angular.fromJson(data);
                if (data.enviadoEm) {
                    data.enviadoEm = DateUtils.convertLocaleDateFromServer(data.enviadoEm);
                }
                if (data.inicioCompetencia) {
                    data.inicioCompetencia = DateUtils.convertLocaleDateFromServer(data.inicioCompetencia);
                }
                if (data.fimCompetencia) {
                    data.fimCompetencia = DateUtils.convertLocaleDateFromServer(data.fimCompetencia);
                }
                return data;
            }

            var resource = $resource('/api/arquivo-desif/:id', {}, {
                'get': {
                    method: 'GET',
                    url: 'api/arquivo-desif/:id',
                    transformResponse: transformResponse
                },
                'consultar': {
                    method: 'POST',
                    url: 'api/arquivo-desif/consultar',
                    isArray: true,
                    transformResponse: transformResponseList
                },
                'validar': {
                    method: 'POST',
                    url: "api/arquivo-desif/validar"
                },
                'enviar': {
                    method: 'GET',
                    url: "api/arquivo-desif/enviar"
                },
                'consultarSituacao': {
                    method: 'GET',
                    url: "api/arquivo-desif/consultar-situacao"
                },
                'importarLegado': {
                    method: 'POST',
                    url: "api/arquivo-desif/importar-legado"
                }
            });

            return resource;
        });
})();
