'use strict';

angular.module('nfseApp')
    .controller('NotaFiscalAvulsaController',
        function ($rootScope, $scope, NotaFiscalAvulsa, ParseLinks, ImpressaoPdf) {

            $scope.notasFiscaisAvulsa = [];
            $scope.per_page = 10;
            $scope.searchQuery = "";

            $scope.loadAll = function () {
                NotaFiscalAvulsa.query({
                    page: $scope.page,
                    per_page: $scope.per_page,
                    filtro: $scope.searchQuery
                }, function (result, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.notasFiscaisAvulsa = result;
                });
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.loadAll();

            $scope.newNotaFiscalAvulsa = function () {
                $state.go("notaFiscalAvulsa.new");
            };

            $scope.imprimeNFSAvulsa = function (id) {
                console.log('veio aqui');
                ImpressaoPdf.imprimirPdfViaUrl('/api/imprimir-nota-fiscal-avulsa/' + id);
            };
        }
    );
