'use strict';

angular.module('nfseApp')
    .controller('CredenciamentoController', function ($scope, $modal, $cacheFactory, $rootScope, localStorageService,
                                                      Notificacao, Principal, $timeout,
                                                      Credenciamento, ParseLinks, $state, PrestadorServicos,
                                                      Pessoa, Municipio, CEP) {

        $scope.error = null;
        $scope.success = 'OK';

        $timeout(function () {
            angular.element('[ng-model="prestadorServicos.pessoa.dadosPessoais.cpfCnpj"]').focus();
        });


        var onSaveFinished = function (result) {
            Principal.identity(true);
            $scope.$emit('nfseApp:navbarUpdate', result);
            $state.go("home", {}, {reload: true});
            Notificacao.info("Operação Realizada", "Sua solicitação foi encaminhada para um fiscal e assim que aprovada você receberá um email com as instruções de como prosseguir");
        };


        $scope.save = function () {
            PrestadorServicos.vincularUsuarioEmpresa($scope.prestadorServicos, onSaveFinished);
        };

        $scope.loadEnderecoByCEP = function (cep) {
            if (cep) {
                CEP.getByCep({cep: cep}, function (endereco) {
                    $scope.prestadorServicos.pessoa.dadosPessoais.cep = endereco.cep;
                    $scope.prestadorServicos.pessoa.dadosPessoais.municipio = endereco.municipio;
                    $scope.prestadorServicos.pessoa.dadosPessoais.logradouro = endereco.logradouro;
                    $scope.prestadorServicos.pessoa.dadosPessoais.bairro = endereco.bairro;
                });
            }
        };

        $scope.loadMunicipioByCodigo = function (codigo) {
            if (codigo) {
                Municipio.getByCodigo({codigo: codigo}, function (municipio) {
                    if (municipio.id) {
                        $scope.prestadorServicos.pessoa.dadosPessoais.municipio = municipio;
                    } else {
                        Notificacao.warn("Atenção", "Não foi encontrado nenhum Municipio com o código " + codigo);
                        $scope.prestadorServicos.pessoa.dadosPessoais.municipio = {};
                    }
                });
            }
        };

        $scope.searchMunicipio = function () {
            var modalInstance = $modal.open({
                templateUrl: 'app/entities/municipio/municipio-search.html',
                controller: 'MunicipioSearchController',
                size: 'lg'
            });
            modalInstance.result.then(function (municipio) {
                $scope.prestadorServicos.pessoa.dadosPessoais.endereco.municipio = municipio;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.loadByCpfCnpj = function () {
            PrestadorServicos.getPorCpfCnpj({cpfCnpj: $scope.cnpj},
                function (result) {
                    $scope.editForm.cpfCnpj.$setValidity("exists", true);
                    $scope.prestadorServicos = result;
                    Notificacao.info("Atenção", "A empresa que você está solicitando acesso já existe como cadastro municipal. Ao continuar sua solicitação sará encaminhada para um fiscal e assim que aprovada você receberá um email com a confirmação");
                }, function (error) {
                    if (error.status === 404) {
                        $state.go("registrarEmpresa", {cpfCnpj: $scope.cnpj});
                        Notificacao.warn("Atenção", "A empresa que você está solicitando ainda não existe como cadastro municipal. Realize a solicitação de cadastro para obter acesso");
                    }

                });
        };

    });
