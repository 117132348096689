'use strict';

angular.module('nfseApp')
    .controller('RPSController', function ($scope, $state, RPS, RPSSearch, ParseLinks, SweetAlert, ExportarXls) {
        $scope.listaRps = [];
        $scope.page = 0;
        $scope.searchQuery = "";
        $scope.per_page = 20;

        $scope.buscarOperacoes = function (tipo) {
            var operacoes = [];
            operacoes.push({nome: 'Igual', value: 'IGUAL'});
            if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                operacoes.push({nome: 'Maior ou igual', value: 'MAIOR_IGUAL'});
            }
            if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                operacoes.push({nome: 'Menor ou igual', value: 'MENOR_IGUAL'});
            }
            if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                operacoes.push({nome: 'Menor', value: 'MENOR'});
            }
            if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                operacoes.push({nome: 'Maior', value: 'MAIOR'});
            }
            if (tipo === 'texto') {
                operacoes.push({nome: 'Contém', value: 'LIKE'});
            }
            console.log('operacoes', operacoes);
            return operacoes;
        };

        $scope.filtrosDisponiveis = [
            {
                nomeAtributo: 'cast(a.numero as integer)',
                tipo: 'inteiro',
                descricao: 'Número do RPS',
                operacoes: $scope.buscarOperacoes('inteiro')
            },
            {
                nomeAtributo: 'a.totalServicos',
                tipo: 'decimal',
                descricao: 'Valor dos Serviços',
                operacoes: $scope.buscarOperacoes('decimal')
            },
            {
                nomeAtributo: 'a.issPagar',
                tipo: 'decimal',
                descricao: 'Valor do Iss',
                operacoes: $scope.buscarOperacoes('decimal')

            },
            {
                nomeAtributo: 'nota.numero',
                tipo: 'decimal',
                descricao: 'Número da Nfse',
                operacoes: $scope.buscarOperacoes('decimal')
            },
            {
                nomeAtributo: 'cast(lote.numero as varchar)',
                tipo: 'inteiro',
                descricao: 'Número do Lote',
                operacoes: $scope.buscarOperacoes('inteiro')
            }
        ];

        $scope.filtrosSelecionados = [];

        $scope.selecionouCampo = function (filtro) {
            filtro.valorTexto = null;
            filtro.valorDecimal = null;
            filtro.valorInteiro = null;
            filtro.valorLogico = false;
            filtro.valorData = null;
        };

        $scope.validarFiltros = function () {
            for (var i = 0; i < $scope.filtrosSelecionados.length; i++) {
                if ($scope.filtrosSelecionados[i].atributo == null) {
                    SweetAlert.warning("Filtro Personalizado inválido!", "Selecione uma opção de filtro.");
                    return false;
                }
                if (!$scope.filtrosSelecionados[i].operacao) {
                    SweetAlert.warning("Filtro Personalizado inválido!", "Selecione um operador de filtro.");
                    return false;
                }
                if ($scope.filtrosSelecionados[i].atributo.tipo != 'logico' &&
                    !$scope.filtrosSelecionados[i].valorTexto &&
                    !$scope.filtrosSelecionados[i].valorData &&
                    !$scope.filtrosSelecionados[i].valorDecimal &&
                    !$scope.filtrosSelecionados[i].valorInteiro) {
                    SweetAlert.warning("Filtro Personalizado inválido!", "Defina um valor para o filtro.");
                    return false;
                }
            }
            return true;
        };

        $scope.montarFiltros = function () {
            var lista = [];

            angular.forEach($scope.filtrosSelecionados, function (filtro, index) {

                lista.push(
                    {
                        operacao: filtro.operacao,
                        campo: filtro.atributo.nomeAtributo,
                        valorTexto: (filtro.operacao !== 'LIKE' ? filtro.valorTexto : '%' + filtro.valorTexto.trim().toLowerCase() + '%'),
                        valorData: filtro.valorData,
                        valorLogico: filtro.valorLogico,
                        valorDecimal: filtro.valorDecimal,
                        valorInteiro: filtro.valorInteiro
                    }
                );
            });
            return lista;
        };

        $scope.loadAll = function () {
            if (!$scope.validarFiltros()) {
                return;
            }

            var lista = $scope.montarFiltros();

            RPS.queryComFiltros({
                page: $scope.page ? $scope.page : 0,
                per_page: $scope.per_page,
                filtros: lista
            }, function (result, headers) {
                if (headers)
                    $scope.links = ParseLinks.parse(headers('link'));
                $scope.listaRps = result;
            });
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadAll();

        $scope.delete = function (id) {
            RPS.get({id: id}, function (result) {
                $scope.aidfe = result;

                SweetAlert.swal({
                        title: "Confirme a exclusão",
                        text: "Você tem certeza que quer excluir esse RPS?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Sim, Excluir",
                        cancelButtonText: "Não, Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $scope.confirmDelete(id);
                        } else {
                            SweetAlert.info("Cancelado", "O RPS não foi removido :)", "error");
                        }
                    });
            });
        };

        $scope.confirmDelete = function (id) {
            RPS.delete({id: id},
                function () {
                    SweetAlert.info("Removido!", "O RPS foi removido com sucesso.", "success");
                    $state.reload();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
        };

        $scope.gerarNota = function (rps) {
            SweetAlert.swal({
                    title: "Confirme para gerar a Nota Fiscal",
                    text: "Tem certeza que quer gerar a nota fiscal  do RPS de N° " + rps.numero + " para a competência atual? ",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#F7A54A", confirmButtonText: "Sim, Copiar Nota Fiscal",
                    cancelButtonText: "Não, Cancelar",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $state.go('notaFiscal.byRps', ({numeroRps: rps.numero}));
                        SweetAlert.info("Nota Fiscal Copiada!", "Prossiga para a emissão da Nota Fiscal.", "success");
                    } else {
                        SweetAlert.info("Cancelado", "A Nota Fiscal não foi copiada.", "error");
                    }
                }
            )
        };

        $scope.adicionarFiltro = function () {
            var filtro = {
                campo1: ":param" + ($scope.filtrosSelecionados.length + 1),
                operacao: 'LIKE',
                local: 1,
                ehData: false
            };
            $scope.filtrosSelecionados.push(filtro);
        };

        $scope.removerFiltro = function (index) {
            $scope.filtrosSelecionados.splice(index, 1);
        };

        var colunas = [
            {descricao: 'Número', valor: 'numero'},
            {descricao: 'Série', valor: 'serie'},
            {descricao: 'Emissão', valor: 'dataEmissao'},
            {descricao: 'Valor Serviços', valor: 'totalServicos'},
            {descricao: 'Valor ISS', valor: 'iss'},
            {descricao: 'Núemro da Nfse', valor: 'numeroNotaFiscal'},
            {descricao: 'Núemro do Lote', valor: 'numeroLote'},
        ];

        $scope.baixarXls = function () {
            var xls = {
                colunas: colunas,
                linhas: $scope.listaRps
            };
            ExportarXls.getXls(xls);
        };
        $scope.baixarPDF = function () {
            var xls = {
                colunas: colunas,
                linhas: $scope.listaRps
            };
            ExportarXls.getPdf(xls);
        };


    });
