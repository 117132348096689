(function () {
    'use strict';

    angular.module('nfseApp')
        .factory('ArquivoDesifRegistro1000', function ($resource, DateUtils) {
            function transformResponseList(data) {
                data = angular.fromJson(data);
                for (var i = 0; i < data.length; i++) {
                    var nota = data[i];
                    transformResponse(nota);
                }
                return data;
            }

            function transformResponse(data) {
                data = angular.fromJson(data);
                if (data.dataLancamento) {
                    data.dataLancamento = DateUtils.convertLocaleDateFromServer(data.dataLancamento);
                }
                return data;
            }

            var resource = $resource('/api/arquivo-desif-registro-1000/:id', {}, {
                'consultar': {
                    method: 'POST',
                    url: 'api/arquivo-desif-registro-1000/consultar',
                    isArray: true,
                    transformResponse: transformResponseList
                }
            });

            return resource;
        });
})();
