'use strict';

angular.module('nfseApp')
    .controller('NavbarController',
        function ($scope, $rootScope, $location, $state, $window, Auth, Principal, localStorageService,
                  Imagem, NotaFiscal, DeclaracaoMensalServico, TrocaEmpresa, Configuracao, Sistema) {
            $scope.$state = $state;
            $scope.carregarDadosDoPrestador = carregarDadosDoPrestador;
            $scope.abrirTrocarEmpresa = abrirTrocarEmpresa;
            $scope.logout = logout;
            $scope.carregarDadosUsuario = carregarDadosUsuario;
            $scope.isPrestadorPermitido = isPrestadorPermitido;
            $scope.isCartorio = isCartorio;
            $scope.versaoSistema;

            Sistema.getVersaoSistema({}, function (data) {
                $scope.versaoSistema = data.versao;
            });

            $rootScope.$on('nfseApp:navbarUpdate', function (event, account) {
                $scope.carregarDadosUsuario();
            });

            function carregarDadosUsuario() {
                Principal.identity().then(function (account) {
                    if (account) {
                        $scope.account = account;
                        $scope.isAuthenticated = Principal.isAuthenticated;
                        carregarImagemUsuario();
                        $scope.carregarDadosDoPrestador();
                    }
                });

                Principal.config().then(function (configuracao) {
                    $rootScope.configuracao = configuracao;
                    $rootScope.localizacao = configuracao.municipio;
                    $rootScope.logoMunicipio = configuracao.logoMunicipio;
                });

                Configuracao.getPerfil({}, function (data) {
                    $scope.perfilApp = data.perfil;
                })

            };

            carregarDadosUsuario();

            function logout() {
                Auth.logout();
                $scope.account = null;
                $scope.prestadorPrincipal = null;
                $rootScope.logoPrestador = null;
                localStorageService.set("prestadorPrincipal", null);
                $state.go('login');
            };

            function carregarImagemUsuario() {
                if ($scope.account) {
                    Imagem.getImageFromUsuario({}, function (imagem) {
                        if (imagem && imagem.conteudo) {
                            $scope.account.fotoAjustada = imagem.conteudo;
                        }
                    }, function () {
                        if ($scope.account) {
                            $scope.account.fotoAjustada = "/assets/images/user-default.jpg";
                        }
                    })
                }
            }

            function carregarImagemPrestador() {
                if ($scope.prestadorPrincipal) {
                    Imagem.getImageFromPrestador({id: $scope.prestadorPrincipal.prestador.id}, function (result) {
                        $rootScope.logoPrestador = result
                    }, function (error) {
                        $rootScope.logoPrestador = null;
                    });
                } else {
                    $rootScope.logoPrestador = null;
                }
            }

            function carregarDadosDoPrestador() {

                $scope.prestadorPrincipal = localStorageService.get("prestadorPrincipal");
                if ($scope.prestadorPrincipal) {
                    carregarImagemPrestador();
                    $scope.alertas = [];
                }
            }

            function adicionarAlerta(type, text) {
                var items = {
                    type: type,
                    text: text
                };
                if ($scope.alertas.indexOf(items) < 0) {
                    $scope.alertas.push(items)
                }

                $rootScope.alertasDoSistema = $scope.alertas;
            }

            function abrirTrocarEmpresa() {
                TrocaEmpresa.open();
            }

            function isPrestadorPermitido() {
                return ($scope.prestadorPrincipal && $scope.prestadorPrincipal.permitido) || !$scope.account.pessoaFisica;
            }
            function isCartorio() {
                return $scope.prestadorPrincipal.prestador.enquadramentoFiscal &&
                    $scope.prestadorPrincipal.prestador.enquadramentoFiscal.cartorio;
            }

            $scope.emitirNotaFiscal = function () {
                NotaFiscal.emitirNotaFiscal();
            }
        })
;
