'use strict';

angular.module('nfseApp')
    .controller('ItemDeclaracaoServicoController', function ($scope, NotaFiscal, ParseLinks, SweetAlert, $modalInstance) {
        $scope.itensDeclaracaoServico = [];
        $scope.page = 1;
        $scope.per_page = 5;
        $scope.loadAll = function () {
            NotaFiscal.getItemDeclaracaoServicoEmitidosPorPrestador({
                page: $scope.page,
                per_page: $scope.per_page
            }, function (result, headers) {
                // $scope.links = ParseLinks.parse(headers('link'));
                $scope.itensDeclaracaoServico = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };


        $scope.ok = function (item) {
            $modalInstance.close(item);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
