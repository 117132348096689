'use strict';

angular.module('nfseApp')
    .controller('CancelamentoController', function ($scope, NotaFiscal, ParseLinks, Principal, localStorageService, Pessoa, SweetAlert, Account) {

        $scope.notaFiscals = [];
        $scope.per_page = 20;
        $scope.searchQuery = "";

        Account.get().$promise.then(function (account) {
            $scope.tomador = account.data;
            Pessoa.getPessoaDoLogin({login: account.data.login}).$promise.then(function (data) {
                $scope.cpf = data.dadosPessoais.cpfCnpj;
                $scope.cnpj = localStorageService.get("prestadorPrincipal").prestador.pessoa.dadosPessoais.cpfCnpj;
                $scope.loadAll();
            })
        });

        $scope.loadAll = function () {
            NotaFiscal.buscarNotasEmCancelamento({
                page: $scope.page,
                per_page: $scope.per_page,
                filtro: $scope.searchQuery,
                cpf: $scope.cpf,
                cnpj: $scope.cnpj
            }, function (result, headers) {
                if (headers) {
                    $scope.links = ParseLinks.parse(headers('link'));
                }
                $scope.notaFiscals = result;
                console.log($scope.notaFiscals);
            });
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.deferir = function (idNota) {
            $scope.submeter(true, idNota);
        };

        $scope.indeferir = function (idNota) {
            $scope.submeter(false, idNota);
        };

        $scope.submeter = function (isDeferido, idNota) {
            SweetAlert.swal({
                    title: "Confirme a operação",
                    text: "Você tem certeza que quer " + (isDeferido ? "deferir" : "indeferir") + " está solicitação de cancelamento",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        console.log(idNota);
                        NotaFiscal.submeterCancelamento({
                            idNota: idNota,
                            situacaoTomador: isDeferido ? 'DEFERIDO' : 'NAO_DEFERIDO',
                            tomador: $scope.tomador
                        }, function () {
                            SweetAlert.success("Operação realizada", "Cancelamento " + (isDeferido ? "deferir" : "indeferir") + " com sucesso!");
                            $scope.loadPage();
                            SweetAlert.close();
                        });
                    } else {
                        SweetAlert.close();
                    }
                });
        };
    });
