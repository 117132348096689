'use strict';

angular.module('nfseApp')
    .service('DateUtils', function ($filter) {
            this.convertLocaleDateToServer = function (date) {
                if (date) {
                    return $filter('date')(date, 'yyyy-MM-dd HH:mm:ss');
                } else {
                    return null;
                }
            };

            this.convertLocaleDateFromServer = function (date) {
                if (date) {
                    var dateHour = date.split(" ");
                    var date = dateHour[0].split("-");
                    var hour = dateHour[1] ? dateHour[1].split(":") : null;
                    var retorno;
                    if (hour) {
                        retorno = new Date(date[0], date[1] - 1, date[2], hour[0], hour[1], hour[2]);
                    } else {
                        retorno = new Date(date[0], date[1] - 1, date[2]);
                    }
                }
                return retorno;
            };

            this.convertDateTimeFromServer = function (date) {
                if (date) {
                    return new Date(date);
                } else {
                    return null;
                }
            };

            this.zerarTempo = function (date) {
                if (date) {
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                }
                return date;
            }
        }
    );
