(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('PesquisaNotasRecebidasController', function ($scope, $modalInstance, entity, NotaFiscal, ParseLinks) {

            $scope.page;
            $scope.per_page = 10;
            $scope.declaracaoMensalServico = entity;
            $scope.notas;
            $scope.notasSelecionadas;
            $scope.links;

            $scope.buscarNotas = function () {
                NotaFiscal.buscarNotasSemDeclaracaoPorCompetencia({
                    page: $scope.page - 1,
                    per_page: $scope.per_page,
                    idPrestador: $scope.declaracaoMensalServico.prestador.id,
                    foraMunicipio: $scope.declaracaoMensalServico.prestador.foraMunicipio,
                    ano: $scope.declaracaoMensalServico.exercicio,
                    mes: $scope.declaracaoMensalServico.mes,
                    tipoMovimento: $scope.declaracaoMensalServico.tipoMovimento
                }, function (result, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.notas = result;
                    console.log($scope.links);
                });
            };

            $scope.buscarNotas();

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.buscarNotas();
            };

            $scope.selecionarNota = function (nota) {
                console.log(nota.id);
                if (!$scope.notasSelecionadas) {
                    $scope.notasSelecionadas = [];
                }
                var index = $scope.indexNotaSelecionada(nota);
                if (index >= 0) {
                    $scope.notasSelecionadas.splice(index, 1);
                } else {
                    $scope.notasSelecionadas.push(nota);
                }
            };

            $scope.indexNotaSelecionada = function (nota) {
                if ($scope.notasSelecionadas) {
                    for (var i = 0; i < $scope.notasSelecionadas.length; i++) {
                        if ($scope.notasSelecionadas[i].id == nota.id) {
                            return i;
                        }
                    }
                }
                return -1;
            };

            $scope.ok = function () {
                $modalInstance.close($scope.notasSelecionadas);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };
        })
})();
