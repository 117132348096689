'use strict';

angular.module('nfseApp')
    .controller('RequestResetController', function ($rootScope, $scope, $state, $timeout, Auth, SweetAlert) {


        $scope.resetAccount = {};
        $timeout(function () {
            angular.element('[ng-model="resetAccount.login"]').focus();
        });

        $scope.requestReset = function () {
            Auth.resetPasswordInit($scope.resetAccount.login).then(function () {
                $state.go("login");
                SweetAlert.success("Operação Realizada", "Verifique a caixa de entrada de seu email e siga os passos indicados para criar uma nova senha");
            });
        }

    });
