'use strict';

angular.module('nfseApp')
    .controller('LoteRPSDetailController', function ($scope, $rootScope, $stateParams,
                                                     entity, LoteRPS, $timeout, RPS,ParseLinks) {

        $scope.loteRPS = entity;
        $scope.editable = false;
        $scope.page = 0;
        $scope.searchQuery = "";
        $scope.per_page = 10;

        $scope.editorOptions = {
            lineWrapping: true,
            lineNumbers: true,
            readOnly: 'nocursor',
            mode: 'htmlmixed',
            lineSeparator: '</br>'
        };

        $scope.load = function (id) {
            LoteRPS.get({id: id}, function (result) {
                $scope.loteRPS = result;
                verificarSituacaoLote();
            });
        };

        $rootScope.$on('nfseApp:loteRPSUpdate', function (event, result) {
            $scope.loteRPS = result;
        });

        function verificarSituacaoLote() {
            if ($scope.loteRPS.situacao === 'AGUARDANDO') {
                $timeout(function () {
                    $scope.load($scope.loteRPS.id);
                }, 2000)
            }
        }

        verificarSituacaoLote();

        $scope.loadAll = function () {
            RPS.query({
                page: $scope.page,
                per_page: $scope.per_page,
                loteId: $scope.loteRPS.id,
                filtro: $scope.searchQuery
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.listaRps = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

    });
