(function () {
    'use strict';

angular.module('nfseApp')
    .controller('LoteRpsGeralController', function ($scope, LoteRPS, ParseLinks, EnumCacheService, DataUtil, SweetAlert) {
        $scope.lotes = [];
        $scope.links;
        $scope.dataRecebimentoInicial;
        $scope.dataRecebimentoFinal;
        $scope.numeroLoteInicial;
        $scope.numeroLotFinal;
        $scope.inscricaoCadastral;
        $scope.versaoSistema;
        $scope.versaoAbrasf;
        $scope.situacao;
        $scope.reprocessar;
        $scope.page;
        $scope.per_page = 20;
        $scope.situacoes = EnumCacheService.carregarValuesEnum("br.com.webpublico.nfse.dto.enums.SituacaoLoteRps");


        $scope.montarConsulta = function () {
            var campos = [];
            var parametroQueryCampo = {
                campo: "l.id",
                operador: "DIFERENTE",
                valorLong: 0
            };
            campos.push(parametroQueryCampo);

            if ($scope.dataRecebimentoInicial) {
                var dataRecebimentoInicial = DataUtil.dateWithOutHoursMinutesSeconds($scope.dataRecebimentoInicial);
                parametroQueryCampo = {
                    campo: "l.dataRecebimento",
                    operador: "MAIOR_IGUAL",
                    valorDate: dataRecebimentoInicial
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.dataRecebimentoFinal) {
                var dataRecebimentoFinal = DataUtil.dateWithOutHoursMinutesSeconds($scope.dataRecebimentoFinal);
                parametroQueryCampo = {
                    campo: "l.dataRecebimento",
                    operador: "MENOR_IGUAL",
                    valorDate: dataRecebimentoFinal
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.inscricaoCadastral) {
                parametroQueryCampo = {
                    campo: "ce.inscricaocadastral",
                    operador: "IGUAL",
                    valorString: $scope.inscricaoCadastral
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.versaoSistema) {
                parametroQueryCampo = {
                    campo: "l.versaoSistema",
                    operador: "IGUAL",
                    valorString: $scope.versaoSistema
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.versaoAbrasf) {
                parametroQueryCampo = {
                    campo: "l.versaoAbrasf",
                    operador: "IGUAL",
                    valorString: $scope.versaoAbrasf
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.numeroLoteInicial) {
                parametroQueryCampo = {
                    campo: "cast(l.numero as bigint)",
                    operador: "MAIOR_IGUAL",
                    valorLong: $scope.numeroLoteInicial
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.numeroLoteFinal) {
                parametroQueryCampo = {
                    campo: "cast(l.numero as bigint)",
                    operador: "MENOR_IGUAL",
                    valorLong: $scope.numeroLoteFinal
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.situacao && $scope.situacao.name) {
                parametroQueryCampo = {
                    campo: "l.situacao",
                    operador: "IGUAL",
                    valorString: $scope.situacao.name
                };
                campos.push(parametroQueryCampo);
            }
            if ($scope.reprocessar) {
                parametroQueryCampo = {
                    campo: "l.reprocessar",
                    operador: "IGUAL",
                    valorLogico: $scope.reprocessar
                };
                campos.push(parametroQueryCampo);
            }
            return {
                offset: $scope.page,
                limit: $scope.per_page,
                parametrosQuery: [{
                    juncao: " and ",
                    parametroQueryCampos: campos
                }],
                orderBy: " "
            };
        };

        $scope.loadAll = function () {
            var consultaLoteRps = $scope.montarConsulta();
            LoteRPS.buscarLotesRps(consultaLoteRps,
                function (result, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.lotes = result;
                });
        };

        $scope.limparFiltros = function () {
            $scope.dataRecebimentoInicial = null;
            $scope.dataRecebimentoFinal = null;
            $scope.numeroLoteInicial = null;
            $scope.numeroLotFinal = null;
            $scope.inscricaoCadastral = null;
            $scope.versaoSistema = null;
            $scope.versaoAbrasf = null;
            $scope.situacao = null;
            $scope.reprocessar = null;

        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadAll();

        $scope.inverterReprocessar = function (lote) {
            LoteRPS.inverterReprocessar(lote, function () {
                lote.reprocessar = !lote.reprocessar;
            });
        };

        $scope.dispararReprocessamento = function () {
            SweetAlert.swal({
                    title: 'Atenção',
                    text: 'Tem certeza que deseja disparar o reprocessamento de lotes de rps nesse momento?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        LoteRPS.dispararReprocessamento(null, function () {
                            SweetAlert.info("Informação", "Reprocessamento disparado com sucesso!");
                        });
                    }
                });
        }
    });
})();
