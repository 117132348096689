'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoCartorioDetailController',
    function ($scope, entity, DeclaracaoMensalServico) {
        $scope.declaracaoMensalServico = entity;
        $scope.itens = [];
        $scope.totalDeclarado = 0;
        $scope.totalIss = 0;

        $scope.buscarItensDeclaracaoServico = function () {
            DeclaracaoMensalServico.buscarItensDeclaracaoServico({
                    idDeclaracaoMensalServico: $scope.declaracaoMensalServico.id
                },
                function (data) {
                    $scope.itens = data;
                    $scope.itens.forEach(function (item) {
                        $scope.totalDeclarado = $scope.totalDeclarado + item.valorServico;
                        $scope.totalIss = $scope.totalIss + item.iss;
                    });
                }
            );
        };

        $scope.buscarItensDeclaracaoServico();
    });

