'use strict';

angular.module('nfseApp')
    .controller('LegislacaoController', function ($scope, LegislacaoService, $modal) {

        $scope.legislacoes = [];

        function buscarLegislacoesParaExibicao() {
            LegislacaoService.legislacoesParaExibicao(function (data) {
                $scope.legislacoes = data;
            });
        }

        buscarLegislacoesParaExibicao();


        $scope.verDetalhes = function (legislacao) {
            var modalInstance = $modal.open({
                templateUrl: 'app/externo/legislacao/legislacao-detalhes.html',
                controller: 'LegislacaoDetalhesController',
                size: 'lg',
                resolve: {
                    legislacao: function () {
                      return legislacao;
                    }
                }
            });

        };

    });

angular.module('nfseApp')
    .controller('LegislacaoDetalhesController', function ($scope, $modalInstance, legislacao) {

        $scope.legislacao = legislacao;

        $scope.ok = function (legislacao) {
            $modalInstance.close(legislacao);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };

    });
