'use strict';

angular.module('nfseApp')
    .controller('EscritorioContabilSearchController', function ($scope, EscritorioContabil, ParseLinks, SweetAlert, $modalInstance) {
        $scope.escritorios = [];
        $scope.per_page = 10;
        $scope.searchQuery = "";

        $scope.loadAll = function () {
            EscritorioContabil.query({
                page: $scope.page,
                per_page: $scope.per_page,
                query: $scope.searchQuery
            }, function (result, headers) {
                if (headers)
                    $scope.links = ParseLinks.parse(headers('link'));
                $scope.escritorios = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadAll();


        $scope.ok = function (escritorio) {
            $modalInstance.close(escritorio);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
