'use strict';

angular.module('nfseApp')
    .factory('BloqueioEmissaoNfse', function ($resource) {
        return $resource('api/bloqueio-emissao-nfse/:id', {}, {
            'buscarBloqueioPrestador': {
                url: 'api/bloqueio-emissao-nfse/buscar-bloqueio-prestador',
                method: 'GET'
            }
        });
    });
