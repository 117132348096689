'use strict';

angular.module('nfseApp')
    .controller('GuiaDetailController', function ($scope, $rootScope, $stateParams, entity, Guia, DeclaracaoMensalServico) {
        $scope.guia = entity;
        $scope.load = function (id) {
            Guia.get({id: id}, function(result) {
                $scope.guia = result;
            });
        };
        $rootScope.$on('nfseApp:guiaUpdate', function(event, result) {
            $scope.guia = result;
        });
    });
