'use strict';

angular.module('nfseApp')
    .factory('Servico', function ($resource, DateUtils) {
        return $resource('api/servicos/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'queryByPrestador': {method: 'GET', isArray: true, url: '/api/servicos-prestador'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getPorCodigo': {
                method: 'GET',
                url: "api/servico_por_codigo/:codigo"
            },
            'getPorCnae': {
                method: 'GET',
                isArray: true,
                url: "api/servicos-por-cnae/:id",
            },
            'getPorCnaePrestador': {
                method: 'GET',
                isArray: true,
                url: "api/servicos-por-cnae-prestador",
            },
            'getPorCodigoPorPrestador': {
                method: 'GET',
                url: "api/servico_por_codigo_prestador/:codigo",
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'buscarPorCnae': {
                method: 'GET',
                url: "api/servico-por-cnae",
                isArray: true
            },
            'update': {method: 'PUT'}
        });
    });
