'use strict';

angular.module('nfseApp')
    .controller('ManualController', function ($scope, ManualService, $modal) {

        $scope.manuais = [];

        function buscarManuaisParaExibicao() {
            ManualService.manuaisParaExibicao(function (data) {
                $scope.manuais = data;
            });
        }

        buscarManuaisParaExibicao();


        $scope.verDetalhes = function (manual) {
            var modalInstance = $modal.open({
                templateUrl: 'app/externo/manual/manual-detalhes.html',
                controller: 'ManualDetalhesController',
                size: 'lg',
                resolve: {
                    manual: function () {
                        return manual;
                    }
                }
            });

        };

    });

angular.module('nfseApp')
    .controller('ManualDetalhesController', function ($scope, $modalInstance, manual) {

        $scope.manual = manual;

        $scope.ok = function (manual) {
            $modalInstance.close(manual);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };

    });
