'use strict';

angular.module('nfseApp')
    .controller('DebitosController', function ($scope, PrestadorServicos, ImpressaoPdf, $modal) {

        $scope.debitos = [];
        $scope.page = 1;
        $scope.searchQuery = "";
        $scope.per_page = 10;
        $scope.agrupador = {parcelas: []};
        $scope.hoje = new Date();

        $scope.situacoes = [
            {descricao: 'Em Aberto', value: 'EM_ABERTO'},
            {descricao: 'Pago', value: 'PAGO'},
            {descricao: 'Cancelado', value: 'CANCELAMENTO'},
            {descricao: 'Sem Movimento', value: 'SEM_MOVIMENTO'}
        ];

        $scope.filtro = {situacoes: []};
        $scope.filtro.situacoes.push($scope.situacoes[0]);

        $scope.loadAll = function () {
            $scope.valorImposto = 0;
            $scope.valorTaxa = 0;
            $scope.valorJuros = 0;
            $scope.valorMulta = 0;
            $scope.valorCorrecao = 0;
            $scope.valorHonorarios = 0;
            $scope.valorDesconto = 0;
            $scope.valorPago = 0;
            $scope.total = 0;
            PrestadorServicos.getDebitos(
                {
                    vencimentoInicio: $scope.filtro.vencimentoInicial,
                    vencimentoFim: $scope.filtro.vencimentoFinal,
                    situacoes: $scope.filtro.situacoes.map(function (a) {
                        return a.value;
                    })
                }, function (result, headers) {
                    // $scope.links = ParseLinks.parse(headers('link'));
                    $scope.debitos = result;
                    for (var i = 0; i < result.length; i++) {
                        var debito = result[i];
                        $scope.valorImposto += debito.valorImposto;
                        $scope.valorTaxa += debito.valorTaxa;
                        $scope.valorJuros += debito.valorJuros;
                        $scope.valorMulta += debito.valorMulta;
                        $scope.valorCorrecao += debito.valorCorrecao;
                        $scope.valorHonorarios += debito.valorHonorarios;
                        $scope.valorDesconto += debito.valorDesconto;
                        $scope.valorPago += debito.valorPago;
                        $scope.total += debito.total;

                    }
                }
            )
            ;
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.refresh = function () {
            $scope.loadAll();
        };

        $scope.imprimirDam = function () {
            PrestadorServicos.buscarDamDaParcela($scope.agrupador, function (dam) {
                console.log(dam);
                ImpressaoPdf.imprimirPdfViaPost('/api/imprimir-dam', dam);
            });

        };

        $scope.adicionarParcela = function (servico) {
            $scope.agrupador.parcelas.push(servico);
        };

        $scope.removerParcela = function (index) {
            $scope.agrupador.parcelas.splice(index, 1);
        };

        $scope.enviarDam = function () {
            var modalEnviarNota = $modal.open({
                templateUrl: 'app/entities/debitos/enviar-dam-email.html',
                controller: 'EnviarDamEmailController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return $scope.agrupador
                    }
                }
            });
            modalEnviarNota.result.then(function (data) {
                if (data) {
                    console.log(data)
                }
            });
        };

    });


angular.module('nfseApp')
    .controller('EnviarDamEmailController', function ($scope, entity, PrestadorServicos, ParseLinks, SweetAlert, $modalInstance) {
        $scope.agrupador = entity;

        $scope.ok = function () {
            PrestadorServicos.enviarDamDaParcela($scope.agrupador, function (data) {
                $modalInstance.close();
                SweetAlert.info("Enviado", "O email foi enviado aos destinatários");
            }, function (err) {
                SweetAlert.error("Operação não realizada", "não foi possível enviar o email aos destinatários");
            });
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
