'use strict';

angular.module('nfseApp')
    .controller('ReciboRetencaoIssController',
        function ($scope, NotaFiscal, ParseLinks, Notificacao, ImpressaoPdf) {
            $scope.notaFiscals = [];
            $scope.per_page = 20;
            $scope.cpfCnpj = null;
            $scope.pesquisou = false;
            $scope.recaptcha = null;

            $scope.loadAll = function () {
                if (!$scope.cpfCnpj) {
                    Notificacao.warn('Atenção', 'Informe o CPF/CNPJ.');
                    return;
                }
                if (!$scope.recaptcha) {
                    Notificacao.error('Erro', 'Captcha Inválido.');
                    return;
                }
                NotaFiscal.getServicosDeclaradosIssRetido({
                    page: $scope.page ? $scope.page - 1 : 0,
                    per_page: $scope.per_page,
                    cpfCnpj: $scope.cpfCnpj
                }, function (result, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.notaFiscals = result;
                    $scope.pesquisou = true;
                });
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.novaConsulta = function () {
                $scope.pesquisou = false;
                $scope.cpfCnpj = null;
            };

            $scope.imprimirRecibo = function (id) {
                ImpressaoPdf.imprimirPdfViaUrl('/api/notaFiscals/imprime-recibo-retencao/' + id);
            };
        });

