'use strict';

angular.module('nfseApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('declaracaoMensalServico', {
                parent: 'entity',
                url: '/dms',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Declaração Mensal de Serviços'
                },
                ncyBreadcrumb: {
                    label: 'Consulta DMS'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServico/declaracaoMensalServicos.html',
                        controller: 'DeclaracaoMensalServicoController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('situacaoDeclaracaoMensal');
                        $translatePartialLoader.addPart('situacaoParcela');
                        $translatePartialLoader.addPart('tipoDeclaracaoMensal');
                        $translatePartialLoader.addPart('tipoMovimentoMensal');
                        $translatePartialLoader.addPart('mes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('declaracaoMensalServico.new', {
                parent: 'declaracaoMensalServico',
                url: '/declarar/{mes}/{ano}',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Declaração Mensal de Serviços'
                },
                ncyBreadcrumb: {
                    label: 'Nova DMS'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServico/declaracaoMensalServico-edit.html',
                        controller: 'DeclaracaoMensalServicoEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('tipoDocumento');
                        return $translate.refresh();
                    }]
                }
            })
            .state('declaracaoMensalServico.detail', {
                parent: 'declaracaoMensalServico',
                url: '/detalhes/{id}',
                data: {
                    roles: ['ROLE_EMPRESA_DMS'],
                    pageTitle: 'Detalhes da Declaração Mensal de Serviços'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes da Declaração Mensal de Serviços'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/declaracaoMensalServico/declaracaoMensalServico-detail.html',
                        controller: 'DeclaracaoMensalServicoDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('declaracaoMensalServico');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('tipoDocumento');
                        return $translate.refresh();
                    }],
                    entity: ['DeclaracaoMensalServico', '$stateParams', function (DeclaracaoMensalServico, $stateParams) {
                        return DeclaracaoMensalServico.get({id: $stateParams.id}).$promise;
                    }]
                }
            });
    });
