'use strict';

angular.module('nfseApp')
    .service('ImpressaoPdf', function ($modal, $http, Notificacao) {

        this.imprimirPdfViaUrl = function (url) {
            $http.get(url, {responseType: 'arraybuffer'}).then(function (data) {
                abrirModal(data);
            }).catch(function (error) {
                tratarErro(error);
            });
        };

        this.imprimirPdfViaPost = function (url, data) {
            $http.post(url, data, {responseType: 'arraybuffer'}).then(function (data) {
                abrirModal(data);
            }).catch(function (error) {
                tratarErro(error);
            });
        };

        function abrirModal(data) {
            $modal.open({
                templateUrl: 'app/entities/impressaoPdf/dialog.html',
                controller: 'ImpressaoPDFController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'modal-giant',
                resolve: {
                    data: [function () {
                        return data;
                    }]
                }
            })
        }

        function tratarErro(error) {
            console.log(error);
            Notificacao.error("Esse serviço está indisponível no momento, por favor tente novamente mais tarde.");
        }
    });
