'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoCartorioEditController',
    function ($scope, entity, PrestadorServicos, localStorageService,
              DeclaracaoMensalServico, $timeout, Notificacao, $state) {
        $scope.declaracaoMensalServico = entity;
        $scope.meses = [];
        $scope.itensCartorio = [];
        $scope.quantidadeServicosDeclarados = 0;
        $scope.totalDeclarado = 0;
        $scope.totalIss = 0;

        $scope.acessarCompetencia = acessarCompetencia;
        $scope.atualizarContadores = atualizarContadores;
        $scope.save = save;
        $scope.getTextoBotaoSalvar = getTextoBotaoSalvar;
        $scope.meses = [];

        function carregar() {
            PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (prestador) {
                $scope.prestador = prestador;
                DeclaracaoMensalServico.getMeses({},
                    function (data) {
                        $scope.meses = data;
                        angular.forEach(data, function (mes) {
                            if (mes.numeroMes == $scope.declaracaoMensalServico.mes) {
                                $scope.declaracaoMensalServico.mes = mes;
                                acessarCompetencia();
                            }
                        });
                    });
            });
        }

        carregar();

        function onSaveFinished() {
            Notificacao.priority("Registro salvo com sucesso", "");
            $state.go('declaracaoMensalServicoCartorio');
        }

        function save() {
            var editDTO = {
                id: $scope.declaracaoMensalServico.id,
                mes: $scope.declaracaoMensalServico.mes.numeroMes,
                exercicio: $scope.declaracaoMensalServico.exercicio,
                tipo: 'PRINCIPAL',
                itensCartorio: [],
                prestador: $scope.prestador,
                qtdNotas: $scope.quantidadeServicosDeclarados,
                totalServicos: $scope.totalDeclarado,
                totalIss: $scope.totalIss,
                abertura: new Date(),
                tipoMovimento: 'CARTORIO',
                encerramento: new Date()
            };

            for (var i = 0; i < $scope.itensCartorio.length; i++) {
                if ($scope.itensCartorio[i].valor > 0) {
                    editDTO.itensCartorio.push($scope.itensCartorio[i]);
                }
            }

            if (!editDTO.itensCartorio || editDTO.itensCartorio.length === 0) {
                SweetAlert.swal({
                        title: "Declaração de Ausência de Movimento",
                        text: "Você tem certeza que deseja declarar ausência de movimento para o período " +
                            $scope.declaracaoMensalServico.mes.descricao + "/" + $scope.declaracaoMensalServico.exercicio + "?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Sim, Declarar",
                        cancelButtonText: "Não, Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $timeout(DeclaracaoMensalServico.save(editDTO, onSaveFinished), 1000);
                        }
                    });
            } else {
                $timeout(DeclaracaoMensalServico.save(editDTO, onSaveFinished), 1000);
            }
        }

        function acessarCompetencia() {
            PrestadorServicos.buscarServicosCartorio({}, function (result) {
                $scope.itensCartorio = [];
                for (var i = 0; i < result.length; i++) {
                    $scope.itensCartorio.push({tipoCartorioServico: result[i], valor: 0.0});
                }
            });
            atualizarContadores();
        }

        function atualizarContadores() {
            $scope.quantidadeServicosDeclarados = 0;
            $scope.totalDeclarado = 0;
            $scope.totalIss = 0;
            for (var i = 0; i < $scope.itensCartorio.length; i++) {
                if ($scope.itensCartorio[i].valor > 0) {
                    $scope.quantidadeServicosDeclarados = $scope.quantidadeServicosDeclarados + 1;
                    $scope.totalDeclarado = $scope.totalDeclarado + $scope.itensCartorio[i].valor;
                    $scope.totalIss = $scope.totalIss + ($scope.itensCartorio[i].valor *
                        ($scope.itensCartorio[i].tipoCartorioServico.servico.aliquota / 100));
                }
            }
        }

        function getTextoBotaoSalvar() {
            if ($scope.totalIss === 0) {
                return "Declarar Ausência de Movimento";
            }
            return "Encerrar Declaração";
        }
    });

