'use strict';

angular.module('nfseApp').controller('AutenticarNotaController',
    ['$rootScope', '$scope', '$modal', '$state', '$filter', '$stateParams', 'NotaFiscal', 'Notificacao', 'ImpressaoPdf', 'id',
        function ($rootScope, $scope, $modal, $state, $filter, $stateParams, NotaFiscal, Notificacao, ImpressaoPdf, id) {

            $scope.nfse = {};
            $scope.tipos = [
                {value: "NOTA_FISCAL", label: "Nota Fiscal"},
                {value: "CARTA_CORRECAO", label: "Carta de Correção"}
            ];

            $scope.limpar = function () {
                $scope.autenticar = {cpfCnpjPrestador: "", numeroNfse: "", codigoAutenticacao: "", tipo: "NOTA_FISCAL"};
                $scope.valido = null;
             };

            function recuperarNotaFiscal() {
                $scope.valido = null;
                if (id) {
                    console.log('bora');
                    NotaFiscal.getPublica({id: $stateParams.id}, function (nota) {
                        if (nota.id) {
                            $scope.autenticar = {
                                tipo: "NOTA_FISCAL",
                                cpfCnpjPrestador: nota.prestador.pessoa.dadosPessoais.cpfCnpj,
                                numero: nota.numero,
                                codigoVerificacao: nota.codigoVerificacao
                            };
                            $scope.valido = true;
                            $scope.nfse = nota;
                        } else {
                            NotaFiscal.getPublicaCartaCorrecao({id: $stateParams.id}, function (carta) {
                                if (carta.id) {
                                    $scope.autenticar = {
                                        tipo: "CARTA_CORRECAO",
                                        cpfCnpjPrestador: carta.notaFiscal.prestador.pessoa.dadosPessoais.cpfCnpj,
                                        numero: carta.sequencialCartaCorrecao,
                                        codigoVerificacao: carta.codigoVerificacao
                                    };
                                    $scope.valido = true;
                                } else {
                                    $scope.valido = false;
                                }
                            });
                        }
                    });
                } else {
                    $scope.limpar();
                }
            }

            recuperarNotaFiscal();

            $scope.autenticarNota = function () {
                console.log("Autenticar", $scope.autenticar);
                NotaFiscal.autenticar($scope.autenticar,
                    function (data) {
                        $scope.nfse = data;
                        Notificacao.info("Válido", "Os valores representam uma " +
                            ($scope.autenticar.tipo == 'NOTA_FISCAL' ? "NFS-e" : "Carta de Correção") + " emitida válida");
                        $scope.valido = true;
                        $scope.nfse = data;
                    }, function (error) {
                        Notificacao.error("Inválido", "Os valores informardos não representam uma " +
                            ($scope.autenticar.tipo == 'NOTA_FISCAL' ? "NFS-e" : "Carta de Correção") + " emitida válida");
                        $scope.valido = false;
                    });
            };

            $scope.imprimirNota = function () {
                ImpressaoPdf.imprimirPdfViaUrl('/publico/notaFiscal/imprime/' +  $scope.nfse.id);
            };


        }])
;
