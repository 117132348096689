'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoInstituicaoFinanceiraEditController',
    function ($scope, $state, $timeout, $stateParams, entity, DeclaracaoMensalServico, NotaFiscal, Notificacao,
              localStorageService, SweetAlert, PrestadorServicos, PlanoGeralContasComentado) {

        $scope.declaracaoMensalServico = entity;
        $scope.meses = [];
        $scope.contasBancarias = [];
        $scope.quantidadeContasBancariasDeclaradas = 0;
        $scope.totalSaldoAnterior = 0;
        $scope.totalCredito = 0;
        $scope.totalDebito = 0;
        $scope.totalDeclarado = 0;
        $scope.totalIss = 0;

        $scope.acessarCompetencia = acessarCompetencia;
        $scope.atualizarContadores = atualizarContadores;
        $scope.save = save;
        $scope.getTextoBotaoSalvar = getTextoBotaoSalvar;
        $scope.meses = [];

        function carregar() {
            PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (prestador) {
                $scope.prestador = prestador;
                DeclaracaoMensalServico.getMeses({},
                    function (data) {
                        $scope.meses = data;
                        angular.forEach(data, function (mes) {
                            if (mes.numeroMes == $scope.declaracaoMensalServico.mes) {
                                $scope.declaracaoMensalServico.mes = mes;
                                acessarCompetencia();
                            }
                        });
                    });
            });
        }

        carregar();

        function onSaveFinished() {
            Notificacao.priority("Registro salvo com sucesso", "");
            $state.go('declaracaoMensalServicoInstituicaoFinanceira');
        }


        function save() {
            var editDTO = {
                id: $scope.declaracaoMensalServico.id,
                mes: $scope.declaracaoMensalServico.mes.numeroMes,
                exercicio: $scope.declaracaoMensalServico.exercicio,
                tipo: 'PRINCIPAL',
                contasBancarias: [],
                prestador: $scope.prestador,
                qtdNotas: $scope.quantidadeContasBancariasDeclaradas,
                totalServicos: $scope.totalDeclarado,
                totalIss: $scope.totalIss,
                abertura: new Date(),
                tipoMovimento: 'INSTITUICAO_FINANCEIRA',
                encerramento: new Date()
            };

            for (var i = 0; i < $scope.contasBancarias.length; i++) {
                if ($scope.contasBancarias[i].baseCalculo > 0) {
                    editDTO.contasBancarias.push($scope.contasBancarias[i]);
                }
            }

            if (!editDTO.contasBancarias || editDTO.contasBancarias.length === 0) {
                SweetAlert.swal({
                        title: "Declaração de Ausência de Movimento",
                        text: "Você tem certeza que deseja declarar ausência de movimento para o período " +
                            $scope.declaracaoMensalServico.mes.descricao + "/" + $scope.declaracaoMensalServico.exercicio + "?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Sim, Declarar",
                        cancelButtonText: "Não, Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $timeout(DeclaracaoMensalServico.save(editDTO, onSaveFinished), 1000);
                        }
                    });
            } else {
                $timeout(DeclaracaoMensalServico.save(editDTO, onSaveFinished), 1000);
            }
        }

        function acessarCompetencia() {
            $scope.contasBancarias = [];
            PlanoGeralContasComentado.buscarPorPrestador({
                prestador: $scope.prestador.id
            }, function (contas) {
                for (var i = 0; i < contas.length; i++) {
                    $scope.declaracaoContaBancaria = {};
                    $scope.declaracaoContaBancaria.conta = contas[i];
                    $scope.declaracaoContaBancaria.saldoInicial = 0;
                    $scope.declaracaoContaBancaria.valorDebito = 0;
                    $scope.declaracaoContaBancaria.valorCredito = 0;
                    $scope.declaracaoContaBancaria.baseCalculo = 0;
                    $scope.declaracaoContaBancaria.aliquotaIssqn = contas[i].aliquotaIssqn;
                    $scope.contasBancarias.push($scope.declaracaoContaBancaria);
                }
            });
            atualizarContadores();
        }

        function atualizarContadores() {
            $scope.quantidadeContasBancariasDeclaradas = 0;
            $scope.totalSaldoAnterior = 0;
            $scope.totalCredito = 0;
            $scope.totalDebito = 0;
            $scope.totalDeclarado = 0;
            $scope.totalIss = 0;
            for (var i = 0; i < $scope.contasBancarias.length; i++) {
                if ($scope.contasBancarias[i].baseCalculo > 0) {
                    $scope.quantidadeContasBancariasDeclaradas = $scope.quantidadeContasBancariasDeclaradas + 1;
                    $scope.totalDeclarado = $scope.totalDeclarado + $scope.contasBancarias[i].baseCalculo;
                    $scope.totalIss = $scope.totalIss + ($scope.contasBancarias[i].baseCalculo * ($scope.contasBancarias[i].aliquotaIssqn / 100));
                }
                $scope.totalSaldoAnterior = $scope.totalSaldoAnterior + $scope.contasBancarias[i].saldoInicial;
                $scope.totalCredito = $scope.totalCredito + $scope.contasBancarias[i].valorCredito;
                $scope.totalDebito = $scope.totalDebito + $scope.contasBancarias[i].valorDebito;
            }
        }

        function getTextoBotaoSalvar() {
            if ($scope.totalIss === 0) {
                return "Declarar Ausência de Movimento";
            }
            return "Encerrar Declaração";
        }
    }
);

