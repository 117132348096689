'use strict';

angular.module('nfseApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('notaFiscal', {
                parent: 'entity',
                url: '/nota-fiscal',
                data: {
                    roles: ['ROLE_CONTRIBUINTE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Consulta de Notas Fiscais Emitidas'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscals.html',
                        controller: 'NotaFiscalController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('modalidadeNota');
                        $translatePartialLoader.addPart('situacaoNota');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('naturezaOperacao');
                        $translatePartialLoader.addPart('motivoCancelamento');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cancelamento-nota-fiscal', {
                parent: 'notaFiscal',
                url: '/cancelamento',
                ncyBreadcrumb: {
                    label: 'Cancelamentos Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/cancelamento/cancelamento.html',
                        controller: 'CancelamentoController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('notaFiscal.detail', {
                parent: 'notaFiscal',
                url: '/detalhes/{id}',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes da Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-detail.html',
                        controller: 'NotaFiscalDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('situacaoParcela');
                        $translatePartialLoader.addPart('modalidadeNota');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', '$state', function ($stateParams, NotaFiscal, $state) {
                        return NotaFiscal.get({id: $stateParams.id},
                            function (sucess) {
                                return sucess;
                            }, function (error) {
                                $state.go('notaFiscal');
                            });
                    }]
                }
            })
            .state('imprimeNota', {
                url: '/impressao/{id}',
                parent: 'entity',
                data: {
                    roles: [],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes da Nota Fiscal'
                },
                onEnter: ['$stateParams', '$state', '$modal', 'NotaFiscal', '$rootScope', function ($stateParams, $state, $modal, NotaFiscal, $rootScope) {
                    $modal.open({
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-impressao.html',
                        controller: 'NotaFiscalImpressaoController',
                        size: 'lg',
                        resolve: {
                            id: function () {
                                return $stateParams.id;
                            }
                        }
                    }).result.then(function (result) {
                        if ($rootScope.previousStateName && $rootScope.previousStateName != 'notaFiscal.edit') {
                            $state.go($rootScope.previousStateName, null, {reload: true});
                        } else {
                            $state.go('notaFiscal', null, {reload: true});
                        }
                    }, function () {
                        if ($rootScope.previousStateName && ($rootScope.previousStateName != 'notaFiscal.edit' && $rootScope.previousStateName != 'notaFiscal.copy')) {
                            $state.go($rootScope.previousStateName);
                        } else {
                            $state.go('notaFiscal');
                        }
                    })
                }]
            })
            .state('notaFiscal.new', {
                parent: 'notaFiscal',
                url: '/novo',
                data: {
                    roles: ['ROLE_EMPRESA_NFSE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Emissão de nova Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-edit.html',
                        controller: 'NotaFiscalEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('tomador');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('servico');
                        $translatePartialLoader.addPart('modalidadeNota');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', 'localStorageService', '$state', function ($stateParams, NotaFiscal, localStorageService, $state) {
                        return NotaFiscal.new({}, function (data) {
                            return data;
                        }, function (error) {
                            $state.go('notaFiscal');
                        });
                    }]
                }
            })
            .state('notaFiscal.newSubstituicao', {
                parent: 'notaFiscal',
                url: '/novo/{idNotaSubstituida}',
                data: {
                    roles: ['ROLE_EMPRESA_NFSE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Emissão de nova Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-edit.html',
                        controller: 'NotaFiscalEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('tomador');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('servico');
                        $translatePartialLoader.addPart('modalidadeNota');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', 'localStorageService', '$state', function ($stateParams, NotaFiscal, localStorageService, $state) {
                        return NotaFiscal.newSubstituicao({idNotaSubstituida: $stateParams.idNotaSubstituida}, function (data) {
                            return data;
                        }, function () {
                            $state.go('notaFiscal');
                        });
                    }]
                }
            })
            .state('notaFiscal.copy', {
                parent: 'notaFiscal',
                url: '/nova-nota-copiada/{id}',
                data: {
                    roles: ['ROLE_EMPRESA_NFSE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Emissão de nova Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-edit.html',
                        controller: 'NotaFiscalEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('tomador');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('servico');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', 'localStorageService', '$state', 'SweetAlert', function ($stateParams, NotaFiscal, localStorageService, $state, SweetAlert) {
                        return NotaFiscal.copy({id: $stateParams.id}, function (data) {
                            return data;
                        }, function (error) {
                            console.log('erro na copia de nfse {}', error);
                            $state.go('notaFiscal');
                        });
                    }]
                }
            })
            .state('notaFiscal.byRps', {
                parent: 'notaFiscal',
                url: '/nova-nota-rps/{numeroRps}',
                data: {
                    roles: ['ROLE_EMPRESA_NFSE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Emissão de nova Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-edit.html',
                        controller: 'NotaFiscalEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('tomador');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('servico');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', 'localStorageService', '$state', 'SweetAlert', function ($stateParams, NotaFiscal, localStorageService, $state, SweetAlert) {
                        return NotaFiscal.copyPorRps({numeroRps: $stateParams.numeroRps}, function (data) {
                            data.notaPai = $stateParams.id;
                            return data;
                        }, function (error) {
                            $state.go('notaFiscal');
                        });
                    }]
                }
            })
            .state('notaFiscal.edit', {

                parent: 'notaFiscal',
                url: '/edicao/{id}',
                data: {
                    roles: ['ROLE_EMPRESA_NFSE'],
                    pageTitle: 'Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Edição da Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/notaFiscal-edit.html',
                        controller: 'NotaFiscalEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'NotaFiscal', '$state', function ($stateParams, NotaFiscal, $state) {
                        return NotaFiscal.get({id: $stateParams.id},
                            function (sucess) {
                                return sucess;
                            }, function (error) {
                                $state.go('notaFiscal');
                            });
                    }]
                }
            })
            .state('notaFiscal.autenticar', {
                parent: 'entity',
                url: '/notas-autenticar',
                data: {
                    roles: [],
                    pageTitle: 'Verifique a Autenticidade da NFS-e '
                },
                ncyBreadcrumb: {
                    label: 'Autenticar Nfse'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/autenticar-nota.html',
                        controller: 'AutenticarNotaController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    id: function () {
                        return null;
                    }
                }
            })
            .state('exportarXml', {
                parent: 'entity',
                url: '/exportar-xml',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'Exportar XML de Nota Fiscal'
                },
                ncyBreadcrumb: {
                    label: 'Exportar XML de Nota Fiscal'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/exportacaoxml/exportacao-xml.html',
                        controller: 'ExportacaoXmlController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('notaFiscal.autenticar-qrcode', {
                parent: 'entity',
                url: '/notas-autenticar-qr-code/{id}',
                data: {
                    roles: [],
                    pageTitle: 'Verifique a Autenticidade da NFS-e '
                },
                ncyBreadcrumb: {
                    label: 'Autenticar Nfse'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/notaFiscal/autenticar-nota.html',
                        controller: 'AutenticarNotaController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notaFiscal');
                        $translatePartialLoader.addPart('declaracaoPrestacaoServico');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('localExecucao');
                        $translatePartialLoader.addPart('regimeTributario');
                        $translatePartialLoader.addPart('naturezaOperacao');

                        return $translate.refresh();
                    }],
                    id: ['$stateParams', function ($stateParams) {
                        return $stateParams.id;
                    }]
                }
            })
    });
