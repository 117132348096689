'use strict';

angular.module('nfseApp')
    .controller('LoteRPSController', function ($scope, LoteRPS, LoteRPSSearch, ParseLinks) {
        $scope.lotes = [];
        $scope.page = 1;
        $scope.searchQuery = "";
        $scope.per_page = 10;

        $scope.loadAll = function () {
            LoteRPS.query({
                page: $scope.page,
                per_page: $scope.per_page,
                filtro: $scope.searchQuery
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.lotes = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.loteRPS = {codigo: null, id: null};
        };
    });
