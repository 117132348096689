'use strict';

angular.module('nfseApp').controller('NotaFiscalEditController',
    ['$rootScope', '$scope', '$modal', '$state', '$filter', '$stateParams', 'entity', 'NotaFiscal',
        'localStorageService', 'Notificacao', 'Tomador', 'Servico', 'Municipio', 'CEP', 'Pais',
        'Configuracao', 'PrestadorServicos', 'ImpressaoPdf', 'Cnae', 'SweetAlert', 'ConstrucaoCivil', 'Util',

        function ($rootScope, $scope, $modal, $state, $filter, $stateParams, entity, NotaFiscal,
                  localStorageService, Notificacao, Tomador, Servico, Municipio, CEP, Pais,
                  Configuracao, PrestadorServicos, ImpressaoPdf, Cnae, SweetAlert, ConstrucaoCivil,
                  Util) {

            $scope.mensagemCamposObrigatorios = [];
            $scope.mostraFormServicos = true;
            $scope.itensDeclaracaoServico = [];
            $scope.aliquotas = [0.00, 2.00, 2.79, 3.00, 3.50, 3.84, 3.87, 4.23, 4.26, 4.31, 4.61, 4.65, 5.00];
            $scope.mostrarDadosNota = false;
            $scope.permiteExigibilidade = true;
            $scope.bloqueiaAliquota = false;
            $scope.passos = {tipoTomador: 1, servico: 2, itensNota: 3, outrasInfos: 4, resumo: 5};
            $scope.passoAtual = $scope.passos.tipoTomador;
            $scope.cpfCnpjTomadorAnterior = "";
            $scope.emissaoRetroativa = false;
            $scope.emissaoRetroativaUltimaEmissao = false;
            $scope.ultimaDataEmissao = null;
            $scope.naturezasOperacao = [];
            $scope.art;
            $scope.aliquotaServicoExcedente;

            Configuracao.get({}, function (data) {

                $scope.municipio = data.municipio;
                $scope.configuracao = data;
                $scope.emissaoRetroativa = data.emissaoRetroativa;
                $scope.emissaoRetroativaUltimaEmissao = data.emissaoRetroativaUltimaEmitida;

                PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (data) {
                    $scope.prestador = data;
                    $scope.cpfCnpjDoPrestador = $scope.prestador.pessoa.dadosPessoais.cpfCnpj;
                    if (!$scope.prestador.enquadramentoFiscal || !$scope.prestador.enquadramentoFiscal.regimeTributario || !$scope.prestador.emiteNfs) {
                        SweetAlert.error("Atenção", $scope.prestador.pessoa.dadosPessoais.nomeRazaoSocial + " Não tem permissão para emissão de NFS-e");
                        $state.go("notaFiscal");

                    }

                    if (entity.$promise) {
                        entity.$promise.then(function (nota) {
                            $scope.notaFiscal = nota;
                            $scope.notaFiscal.emails = "";
                            if (!$scope.notaFiscal.id) {
                                $scope.notaFiscal.municipio = $scope.municipio;
                                $scope.notaFiscal.prestadoNoPais = true;
                            }
                            if ($scope.temCpfOuCnpj()) {
                                $scope.cpfCnpjTomadorAnterior = $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj;
                            }

                            if ($scope.notaFiscal.copiada) {
                                $scope.tratarNotaFiscalCopiada();
                            }
                            $scope.verificarExigibilidadeIss();
                            $scope.verificarRetencaoIss();
                            $scope.verificarDadosSimplesNacional();
                            $scope.calculaValoresDaNota(true);
                        });
                    } else {
                        $scope.notaFiscal = entity;
                        $scope.notaFiscal.declaracaoPrestacaoServico.modalidade = "IDENTIFICADO";
                        $scope.verificarExigibilidadeIss();
                        $scope.verificarRetencaoIss();
                        $scope.verificarDadosSimplesNacional();
                        $scope.calculaValoresDaNota(true);
                    }


                    Cnae.teByPrestador({id: $scope.prestador.id}, function (data) {
                        $scope.cnaes = data;
                    });

                    ConstrucaoCivil.buscarConstrucaoCivilFromPrestador({
                        prestador: $scope.prestador.id,
                        page: 0,
                        per_page: 999,
                        filtro: ""
                    }, function (result) {
                        $scope.obras = result;
                    });

                });
            });

            $scope.tratarNotaFiscalCopiada = function () {
                if ($scope.notaFiscal &&
                    $scope.notaFiscal.declaracaoPrestacaoServico &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.itens &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.itens.length > 0) {
                    $scope.codigoCnae = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].cnae.codigo;
                    $scope.notaFiscal.cnae = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].cnae;
                    $scope.loadServico();
                    $scope.codigoServico = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].servico.codigo;
                    $scope.notaFiscal.servico = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].servico;
                    $scope.notaFiscal.aliquotaServico = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].aliquotaServico;
                    $scope.notaFiscal.prestadoNoPais = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].prestadoNoPais;
                    $scope.notaFiscal.municipio = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].municipio;
                    $scope.notaFiscal.pais = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].pais;
                    $scope.notaFiscal.nomeServico = $scope.notaFiscal.declaracaoPrestacaoServico.itens[0].nomeServico;
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais) {
                    $scope.mostrarTributosFederais = true;
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.intermediario) {
                    $scope.mostrarIntermediario = true;
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil) {
                    $scope.mostrarConstrucaoCivil = true;
                }
                if ($scope.notaFiscal.rps && $scope.notaFiscal.rps.numero) {
                    $scope.possuiRps = true;
                }
                $scope.passoAtual = $scope.passos.resumo;
            }

            $scope.calcularAliquotaServicoSNA = function () {

                $scope.aliquotaEfetiva = (($scope.notaFiscal.prestador.rbt12
                    * ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.aliquota / 100)) - $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.valorDeduzir) / $scope.notaFiscal.prestador.rbt12;
                if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.iss) {
                    $scope.notaFiscal.aliquotaServico = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.iss / 100) * $scope.aliquotaEfetiva;
                } else if ($scope.notaFiscal.servico) {
                    $scope.notaFiscal.aliquotaServico = $scope.notaFiscal.servico.aliquota / 100;
                }
                $scope.aliquotaServicoExcedente = 0;
                if ($scope.notaFiscal.aliquotaServico > 0) {
                    if ($scope.notaFiscal.aliquotaServico > 0.05) {
                        $scope.aliquotaServicoExcedente = $scope.notaFiscal.aliquotaServico - 0.05;
                        $scope.notaFiscal.aliquotaServico = 0.05;
                    } else if ($scope.notaFiscal.aliquotaServico < 0.02) {
                        $scope.notaFiscal.aliquotaServico = 0.02;
                    }
                }
                $scope.notaFiscal.aliquotaServico = Number(parseFloat($scope.notaFiscal.aliquotaServico * 100).toFixed(2));

            };

            $scope.verificarDadosSimplesNacional = function () {
                if ($scope.isPrestadorSimplesNacional()) {
                    if ($scope.notaFiscal.prestador.rbt12 &&
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006 &&
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa) {
                        $scope.calcularAliquotaServicoSNA();
                    }
                }
            };

            $scope.buscarAnexoLei1232006FaixaDoPrestador = function () {
                $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.faixas.sort(function (a, b) {
                    return a.valorMaximo - b.valorMaximo;
                });

                for (var i = 0; i < $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.faixas.length; i++) {
                    if ($scope.notaFiscal.prestador.rbt12 < $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.faixas[i].valorMaximo) {
                        return $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.faixas[i];
                    }
                }
                return null;
            };

            $scope.isPrestadorSimplesNacional = function () {
                return $scope.prestador && $scope.prestador.enquadramentoFiscal &&
                    $scope.prestador.enquadramentoFiscal.simplesNacional;
            };

            $scope.isMei = function () {
                return $scope.prestador && $scope.prestador.enquadramentoFiscal &&
                    $scope.prestador.enquadramentoFiscal.mei;
            };

            $scope.proximoPasso = function () {
                $scope.passoAtual = $scope.passoAtual + 1;
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                if ($scope.passoAtual === $scope.passos.outrasInfos) {
                    $scope.calculaValoresDaNota(true);
                }
            };

            $scope.passoAnterior = function () {
                $scope.passoAtual = $scope.passoAtual - 1;
                document.body.scrollTop = document.documentElement.scrollTop = 0;

            };

            $scope.adicionarEmail = function (email) {
                if (!$scope.notaFiscal.emails) {
                    $scope.notaFiscal.emails = "";
                }
                if ($scope.notaFiscal.emails.length > 0) {
                    $scope.notaFiscal.emails += ', ';
                }
                $scope.notaFiscal.emails += email;
            };

            $scope.searchItemDeclaracaoServico = function () {
                var modalDescriminacaoServico = $modal.open({
                    templateUrl: 'app/entities/notaFiscal/item-declaracao-servico-search.html',
                    controller: 'ItemDeclaracaoServicoController',
                    size: 'lg'
                });
                modalDescriminacaoServico.result.then(function (data) {
                    if (data) {
                        $scope.notaFiscal.descriminacaoServico = data;
                    }
                });
            };

            $scope.preLoadIntermediario = function () {
                if ($scope.notaFiscal && $scope.notaFiscal.declaracaoPrestacaoServico && $scope.notaFiscal.declaracaoPrestacaoServico.intermediario) {
                    var cpfCnpj = $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.cpfCnpj;
                    $scope.notaFiscal.declaracaoPrestacaoServico.intermediario = {};
                    $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.cpfCnpj = cpfCnpj;
                    Tomador.getTomadorPorCpfCnpj({cpfCnpj: cpfCnpj}, function (result) {
                        if (!result.dadosPessoais) {
                            Tomador.getInPessoRepoByCpfCnpj({cpfCnpj: cpfCnpj}, function (result) {
                                if (result.dadosPessoais && result.dadosPessoais.cpfCnpj) {
                                    $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.nomeRazaoSocial = result.dadosPessoais.nomeRazaoSocial;
                                }
                            });
                        } else {
                            $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.nomeRazaoSocial = result.dadosPessoais.nomeRazaoSocial;
                        }
                    });
                }
            };

            $scope.$watch('editForm.cpfCnpjIntermediario.$valid', function (newVal) {
                $scope.preLoadIntermediario();
            });

            $scope.preLoadTomador = function () {
                if ($scope.temCpfOuCnpj() && $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj !== $scope.cpfCnpjTomadorAnterior) {
                    var cpfCnpj = $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj;
                    $scope.cpfCnpjTomadorAnterior = cpfCnpj;
                    $scope.notaFiscal.tomador = {dadosPessoais: {}};
                    $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj = cpfCnpj;
                    if ($scope.cpfDigitadoIgualPrestador()) {
                        Notificacao.error("Atenção", "Não é permitido que o Tomador de Serviços seja o próprio Prestador de Serviços.");
                    } else {

                        Tomador.getTomadorPorCpfCnpj({cpfCnpj: cpfCnpj}, function (result) {
                            if (!result.dadosPessoais) {
                                Tomador.getInPessoRepoByCpfCnpj({cpfCnpj: cpfCnpj}, function (data) {
                                    if (data.dadosPessoais && data.dadosPessoais.cpfCnpj) {
                                        $scope.notaFiscal.tomador = data;
                                    }
                                });
                                $scope.notaFiscal.tomador.prestador = $scope.prestador;
                            } else {
                                $scope.notaFiscal.tomador = result;
                            }
                        });
                    }
                    $scope.verificarRetencaoIss();
                }
            };


            $scope.selecionouObra = function () {
                if ($scope.notaFiscal.servico.construcaoCivil &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil.incorporacao &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.modalidade === 'NAO_IDENTIFICADO') {
                    SweetAlert.error("Atenção", "É necessário identificar o tomador para selecionar uma obra de incorporação");
                    $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil = null;
                    return;
                } else {
                    $scope.verificarExigibilidadeIss();
                }
            };

            $scope.verificarExigibilidadeIss = function () {
                $scope.naturezasOperacao = [];

                $scope.permiteExigibilidade = true;
                $scope.permiteMudarPais = false;
                $scope.permiteMudarCidade = true;

                if (!$scope.notaFiscal.servico) {
                    return;
                }

                if (!$scope.notaFiscal.servico.localExecucaoTrabalho) {
                    $scope.notaFiscal.servico.localExecucaoTrabalho = 'LOCAL';
                }

                if ($scope.notaFiscal.servico.permiteExportacao) {
                    $scope.permiteExigibilidade = true;
                    $scope.permiteMudarPais = true;
                }

                if ($scope.notaFiscal.servico.localExecucaoTrabalho === 'DOMICIO_PRESTADOR'
                    || $scope.notaFiscal.declaracaoPrestacaoServico.modalidade === 'NAO_IDENTIFICADO') {
                    $scope.permiteMudarCidade = false;
                    $scope.notaFiscal.municipio = $scope.municipio;
                }

                if ($scope.notaFiscal.servico.localExecucaoTrabalho === 'LOCAL' && $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil != null) {
                    $scope.notaFiscal.municipio = $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil.localizacao.municipio;
                    $scope.permiteMudarCidade = false;
                }

                if ($scope.notaFiscal.servico.exigibilidade && $scope.notaFiscal.servico.exigibilidade !== 'TRIBUTACAO_MUNICIPAL') {
                    $scope.permiteExigibilidade = false;
                    $scope.naturezasOperacao.push($scope.notaFiscal.servico.exigibilidade);
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = $scope.notaFiscal.servico.exigibilidade;
                    return;
                }

                if ($scope.notaFiscal.servico.vetadoLC1162003) {
                    $scope.permiteExigibilidade = false;
                    $scope.naturezasOperacao.push("IMUNIDADE")
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "IMUNIDADE";
                    return;
                }

                if (!$scope.notaFiscal.prestadoNoPais) {
                    $scope.permiteExigibilidade = false;
                    $scope.naturezasOperacao.push("EXPORTACAO");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "EXPORTACAO";
                    return;
                }

                if ($scope.prestador.enquadramentoFiscal.regimeTributario === 'COOPERATIVA') {
                    $scope.permiteExigibilidade = true;
                    $scope.naturezasOperacao.push("ISENCAO");
                }

                if ($scope.notaFiscal.servico.construcaoCivil &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil.incorporacao) {
                    $scope.permiteExigibilidade = false;
                    $scope.naturezasOperacao.push("ISENCAO");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "ISENCAO";
                    return
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade === 'NAO_IDENTIFICADO') {
                    $scope.naturezasOperacao.push("TRIBUTACAO_MUNICIPAL");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_MUNICIPAL";
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'NAO_IDENTIFICADO'
                    && $scope.notaFiscal.servico.localExecucaoTrabalho === 'DOMICIO_PRESTADOR') {
                    $scope.naturezasOperacao.push("TRIBUTACAO_MUNICIPAL");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_MUNICIPAL";
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'NAO_IDENTIFICADO'
                    && $scope.notaFiscal.servico.localExecucaoTrabalho === 'LOCAL'
                    && $scope.notaFiscal.municipio.id === $scope.municipio.id) {
                    $scope.naturezasOperacao.push("TRIBUTACAO_MUNICIPAL");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_MUNICIPAL";
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'NAO_IDENTIFICADO'
                    && $scope.notaFiscal.servico.localExecucaoTrabalho === 'LOCAL'
                    && $scope.notaFiscal.municipio.id !== $scope.municipio.id) {
                    $scope.naturezasOperacao.push("TRIBUTACAO_FORA_MUNICIPIO");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_FORA_MUNICIPIO";
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'NAO_IDENTIFICADO'
                    && $scope.notaFiscal.servico.localExecucaoTrabalho === 'ESTABELECIMENTO_PRESTADOR') {
                    $scope.naturezasOperacao.push("TRIBUTACAO_MUNICIPAL");
                    $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_MUNICIPAL";
                }

                if ($scope.naturezasOperacao.length === 0) {
                    $scope.naturezasOperacao.push("TRIBUTACAO_MUNICIPAL");
                    if (!$scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = "TRIBUTACAO_MUNICIPAL";
                    }
                }

                if ($scope.naturezasOperacao.length === 1) {
                    $scope.permiteExigibilidade = false;
                }
            };


            $scope.verificarRetencaoIss = function () {
                $scope.permiteRetencao = true;

                var regimeTributario = $scope.prestador.enquadramentoFiscal.regimeTributario;
                if (regimeTributario === "MICROEMPRESARIO_INDIVIDUAL" || regimeTributario === "ISS_FIXO_PESSOA_JURIDICA" || regimeTributario === "ISS_FIXO_PESSOA_FISICA"
                    || regimeTributario === "ESTIMATIVA") {
                    $scope.permiteRetencao = false;
                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = false;
                }
                if ($scope.temCpfOuCnpj() && $scope.isFisica()) {
                    $scope.permiteRetencao = false;
                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = false;
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade === 'NAO_IDENTIFICADO') {
                    $scope.permiteRetencao = false;
                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = false;
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'NAO_IDENTIFICADO'
                    && $scope.notaFiscal.servico
                    && $scope.notaFiscal.servico.localExecucaoTrabalho === 'LOCAL'
                    && $scope.notaFiscal.municipio.id === $scope.municipio.id) {
                    $scope.permiteRetencao = false;
                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = false;
                }

                if (!$scope.notaFiscal.prestadoNoPais) {
                    $scope.permiteRetencao = false;
                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = false;
                }

                if ($scope.temCpfOuCnpj() && !$scope.isFisica()) {
                    if ($scope.notaFiscal.tomador.cadastroEconomico) {
                        if ($scope.notaFiscal.tomador.cadastroEconomico.enquadramentoFiscal.substitutoTributario) {
                            $scope.permiteRetencao = false;
                            $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = true;
                        }
                    } else {
                        PrestadorServicos.buscarPorCpfCnpj({cpfCnpj: $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '')}, function (result) {
                            if (result && result.length > 1) {
                                $scope.selecionarcadastroEconomico(result);
                            } else {
                                $scope.notaFiscal.tomador.cadastroEconomico = result[0];
                                if ($scope.notaFiscal.tomador.cadastroEconomico)
                                    $scope.notaFiscal.tomador.dadosPessoais.inscricaoMunicipal = $scope.notaFiscal.tomador.cadastroEconomico.inscricaoMunicipal;

                                if ($scope.notaFiscal.tomador.cadastroEconomico && $scope.notaFiscal.tomador.cadastroEconomico.pessoa) {
                                    $scope.notaFiscal.tomador.dadosPessoais.pessoaId = $scope.notaFiscal.tomador.cadastroEconomico.pessoa.id;
                                }
                                if ($scope.notaFiscal.tomador &&
                                    $scope.notaFiscal.tomador.cadastroEconomico &&
                                    $scope.notaFiscal.tomador.cadastroEconomico.enquadramentoFiscal &&
                                    $scope.notaFiscal.tomador.cadastroEconomico.enquadramentoFiscal.substitutoTributario) {
                                    $scope.permiteRetencao = false;
                                    $scope.notaFiscal.declaracaoPrestacaoServico.issRetido = true;
                                }
                            }
                        })
                    }
                }
            };

            $scope.selecionarcadastroEconomico = function (cadastrosEconomico) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/cadastro-economico-dlg/cadastro-economico-dlg.html',
                    controller: 'CadastroEconomicoDialogController',
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        cadastrosEconomico: function () {
                            return cadastrosEconomico;
                        }
                    }
                });
                modalInstance.result.then(function (cadastroEconomicoSelecionado) {
                    $scope.notaFiscal.tomador.cadastroEconomico = cadastroEconomicoSelecionado;
                    $scope.notaFiscal.tomador.dadosPessoais.inscricaoMunicipal = cadastroEconomicoSelecionado.inscricaoMunicipal;
                    $scope.verificarRetencaoIss();
                });
            };

            $scope.searchTomadorServico = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/tomador/tomador-search.html',
                    controller: 'TomadorSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (tomador) {

                    Tomador.get({id: tomador.id}, function (data) {
                        if (data && data.dadosPessoais) {
                            $scope.notaFiscal.tomador = {};
                            $scope.notaFiscal.tomador.dadosPessoais = data.dadosPessoais;
                            $scope.notaFiscal.tomador.dadosPessoais.id = null;
                            if ($scope.notaFiscal.tomador.dadosPessoais.endereco) {
                                $scope.notaFiscal.tomador.dadosPessoais.endereco.id = null;
                            }
                            $scope.notaFiscal.tomador.tomador = data;
                        }
                    });


                }, function () {
                });
            };

            $scope.searchIntermediarioServico = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/tomador/tomador-search.html',
                    controller: 'TomadorSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (tomador) {
                    if (tomador && tomador.dadosPessoais) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.intermediario = {};
                        $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.dadosPessoais = tomador.dadosPessoais;
                        $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.dadosPessoais.id = null;
                        if ($scope.notaFiscal.declaracaoPrestacaoServico.intermediario.dadosPessoais.endereco) {
                            $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.dadosPessoais.endereco.id = null;
                        }
                        $scope.notaFiscal.declaracaoPrestacaoServico.intermediario.tomador = tomador;
                    }
                }, function () {
                });
            };

            var onSaveFinished = function (result) {
                if (result.declaracaoPrestacaoServico.situacao == 'EMITIDA' && result && result.id) {
                    $state.go("notaFiscal");
                    ImpressaoPdf.imprimirPdfViaUrl('/publico/notaFiscal/imprime/' + result.id);
                }
            };

            $scope.emitir = function () {
                if ($scope.notaFiscal.declaracaoPrestacaoServico.itens) {
                    for (var i = 0; i < $scope.notaFiscal.declaracaoPrestacaoServico.itens.length; i++) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.itens[i].aliquotaServico = $scope.notaFiscal.aliquotaServico;
                    }
                }
                if ($scope.validarCamposObrigatorios()) {
                    $scope.notaFiscal.declaracaoPrestacaoServico.situacao = 'EMITIDA';
                    NotaFiscal.save($scope.notaFiscal, onSaveFinished, function (error) {
                        Notificacao.error("Erro", "Erro ao emitir a Nfs-e " + error.data.error + ". Por favor entre em contato com o suporte.");
                    });
                }
            };

            $scope.openCompetencia = function ($event) {
                $scope.status.openedCompetencia = true;
            };

            $scope.status = {openedCompetencia: false};

            $scope.clear = function () {
            };

            $scope.getSomaDescontosServico = function () {
                if ($scope.servico) {
                    return $scope.servico.descontosCondicionados + $scope.servico.descontosIncondicionados + $scope.servico.deducoes;
                }
            };

            $scope.calculaValoresServico = function (servico) {
                servico.total = servico.valorServico * servico.quantidade
                    - (servico.descontosIncondicionados && servico.descontosIncondicionados > 0 ?
                        servico.descontosIncondicionados : 0);
                servico.baseCalculo = servico.total;
                if (servico.deducoes > 0) {
                    servico.baseCalculo = servico.baseCalculo - servico.deducoes;
                }
                servico.iss = servico.baseCalculo * Util.roundToX($scope.notaFiscal.aliquotaServico / 100, 8);
                servico.total = Util.roundTo2(servico.total);
                servico.baseCalculo = Util.roundTo2(servico.baseCalculo);
                servico.iss = Util.roundTo2(servico.iss);
            };

            $scope.clearServico = function () {
                $scope.servico = {
                    baseCalculo: 0.0,
                    valorServico: 0.0,
                    quantidade: 1,
                    iss: 0.0,
                    descontosCondicionados: 0.0,
                    descontosIncondicionados: 0.0,
                    deducoes: 0.0,
                    cnae: $scope.notaFiscal.cnae,
                    servico: $scope.notaFiscal.servico,
                    aliquotaServico: $scope.notaFiscal.aliquotaServico,
                    prestadoNoPais: $scope.notaFiscal.prestadoNoPais,
                    municipio: $scope.notaFiscal.municipio,
                    pais: $scope.notaFiscal.pais,
                    nomeServico: $scope.notaFiscal.nomeServico,
                    deducao: false
                };
            };

            $scope.clearCnaeServicoSelecionado = function () {
                $scope.codigoServico = null;
                $scope.notaFiscal.servico = null;
                $scope.codigoCnae = null;
                $scope.notaFiscal.cnae = null;
                $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao = null;
            };


            $scope.validarAddServico = function () {
                $scope.mensagemCamposObrigatorios = [];
                if (!$scope.servico.nomeServico) {
                    $scope.mensagemCamposObrigatorios.push("O campo serviço deve ser informado.");
                }
                if (!$scope.servico.descricao) {
                    $scope.mensagemCamposObrigatorios.push("O campo descrição do serviço deve ser informado.");
                }
                if ($scope.getSomaDescontosServico() > $scope.servico.total) {
                    $scope.mensagemCamposObrigatorios.push("Os valores de desconto não podem ser maiores que o valor total dos serviços.");
                }
                if (!$scope.servico.valorServico || $scope.servico.valorServico === 0) {
                    $scope.mensagemCamposObrigatorios.push("O campo valor do serviço deve ser maior que zero.");
                }
                if ($scope.servico.deducao && $scope.configuracao.validarDadosDeducaoNfse) {
                    if (!$scope.servico.tipoDeducao) {
                        $scope.mensagemCamposObrigatorios.push("O campo tipo de dedução deve ser informado");
                    }
                    if (!$scope.servico.tipoOperacao) {
                        $scope.mensagemCamposObrigatorios.push("O campo tipo de operação deve ser informado");
                    }
                    if (!$scope.servico.numeroDocumentoFiscal) {
                        $scope.mensagemCamposObrigatorios.push("O campo número do documento fiscal deve ser informado");
                    }
                    if (!$scope.servico.cpfCnpjDeducao) {
                        $scope.mensagemCamposObrigatorios.push("O campo CPF/CNPJ deve ser informado");
                    }
                    if (!$scope.servico.cpfCnpjDeducao) {
                        $scope.mensagemCamposObrigatorios.push("O campo CPF/CNPJ deve ser informado");
                    }
                }
                if ($scope.mensagemCamposObrigatorios.length > 0) {
                    Notificacao.camposObrigatorios($scope.mensagemCamposObrigatorios, "warning");
                    return false;
                }
                return true;
            };


            $scope.continuarServico = function () {
                if ($scope.mostraFormServicos) {
                    $scope.addServico(true);
                } else {
                    $scope.proximoPasso();
                }
            };

            $scope.novoServico = function () {
                if ($scope.mostraFormServicos) {
                    $scope.addServico(false);
                } else {
                    $scope.mostraFormServicos = true
                }
            };

            $scope.addServico = function (continua) {
                if ($scope.validarAddServico()) {
                    if ($scope.servico.deducao) {
                        $scope.servico.deducoes = $scope.servico.total;
                    }
                    if (!$scope.notaFiscal.declaracaoPrestacaoServico.itens) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.itens = [];
                    }
                    $scope.notaFiscal.declaracaoPrestacaoServico.itens.push($scope.servico);
                    $scope.clearServico();
                    if (continua) {
                        $scope.mostraFormServicos = !$scope.mostraFormServicos;
                        $scope.proximoPasso();
                    }
                }

            };

            function isFixoOuEstimado() {
                return ($scope.prestador.enquadramentoFiscal.regimeTributario === "ISS_FIXO_PESSOA_JURIDICA"
                    || $scope.prestador.enquadramentoFiscal.regimeTributario === "ISS_FIXO_PESSOA_FISICA"
                    || $scope.prestador.enquadramentoFiscal.regimeTributario === "ESTIMATIVA");
            }

            $scope.validarAliquotaNota = function () {
                if (!$scope.notaFiscal.servico) {
                    $scope.bloqueiaAliquota = false;
                    return
                }

                $scope.bloqueiaAliquota = false;

                if (($scope.exigibilidadeNaoIncide() &&
                    !($scope.prestador.enquadramentoFiscal.regimeTributario === "LUCRO_PRESUMIDO" ||
                        $scope.prestador.enquadramentoFiscal.regimeTributario === "LUCRO_REAL" ||
                        $scope.prestador.enquadramentoFiscal.regimeTributario === "SEM_REGIME_FISCAL"))
                    || $scope.prestador.enquadramentoFiscal.regimeTributario === "MICROEMPRESARIO_INDIVIDUAL"
                    || (($scope.temExigibilidade() || $scope.exigibilidadeSuspensa())
                        && isFixoOuEstimado())) {
                    $scope.bloqueiaAliquota = true;
                    $scope.notaFiscal.aliquotaServico = 0;
                    return;
                }

                if (!$scope.isPrestadorSimplesNacional()
                    && !$scope.notaFiscal.servico.vetadoLC1162003
                    && $scope.notaFiscal.servico.aliquota &&
                    $scope.notaFiscal.municipio.id === $scope.municipio.id) {
                    $scope.bloqueiaAliquota = true;
                    $scope.notaFiscal.aliquotaServico = $scope.notaFiscal.servico.aliquota;
                }

                if ($scope.isPrestadorSimplesNacional() && !$scope.isMei() && (!$scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa
                    || !$scope.notaFiscal.prestador.rbt12)) {
                    $scope.bloqueiaAliquota = true;
                    $scope.notaFiscal.aliquotaServico = 0;
                    return;
                }

                if ($scope.isPrestadorSimplesNacional() && !$scope.isMei() && $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa) {
                    $scope.bloqueiaAliquota = true;
                    $scope.calcularAliquotaServicoSNA();
                    return;
                }

                if ((!$scope.notaFiscal.aliquotaServico || $scope.notaFiscal.aliquotaServico <= 0) &&
                    !($scope.prestador.enquadramentoFiscal.regimeTributario === "LUCRO_PRESUMIDO" ||
                        $scope.prestador.enquadramentoFiscal.regimeTributario === "LUCRO_REAL" ||
                        $scope.prestador.enquadramentoFiscal.regimeTributario === "SEM_REGIME_FISCAL")) {
                    $scope.bloqueiaAliquota = false;
                    SweetAlert.error("Atenção", "Não foi possível encontrar alíquota.");
                }
            };


            $scope.continuarParaResumo = function () {
                if ($scope.isPrestadorSimplesNacional() && !$scope.isMei() && (!$scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa
                    || !$scope.notaFiscal.prestador.rbt12)) {
                    $scope.bloqueiaAliquota = true;
                    $scope.notaFiscal.aliquotaServico = 0;

                    SweetAlert.swal({
                            title: "Atenção",
                            text: "Para continuar é necessário informar o  ANEXO III DA LEI COMPLEMENTAR Nº 123 e o RBT12",
                            type: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Continuar",
                            closeOnConfirm: false,
                            closeOnCancel: false
                        },
                        function () {
                            SweetAlert.close();
                            $scope.alterarAliquotaSimples();
                        });
                    return;
                }
                if ($scope.notaFiscal.retencoesFederais > $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos) {
                    SweetAlert.error("Atenção", "O valor total das retenções (" + $scope.notaFiscal.retencoesFederais + ") não pode ser superior ao valo total dos serviços (" + $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos + ")");
                    return;
                }
                if (!$scope.isMei() && !isFixoOuEstimado() &&
                    !$scope.notaFiscal.servico.vetadoLC1162003 &&
                    !$scope.notaFiscal.aliquotaServico && !$scope.exigibilidadeNaoIncide()) {
                    SweetAlert.error("Atenção", "A Alíquota do ISS deve ser informada");
                    return;
                }
                if ($scope.notaFiscal.aliquotaServico && $scope.notaFiscal.aliquotaServico != 0 &&
                    ($scope.notaFiscal.aliquotaServico < 2 || $scope.notaFiscal.aliquotaServico > 5)) {
                    SweetAlert.error("Atenção", "Alíquota do ISS deve ser entre 2% e 5%");
                    return;
                }
                $scope.calculaValoresDaNota(false);
                $scope.proximoPasso();
            };

            $scope.calculaValoresDaNota = function (calcularRetencoes) {
                $scope.validarAliquotaNota();

                $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.issCalculado = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.deducoesLegais = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.descontosIncondicionais = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.descontosCondicionais = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.issPagar = 0.0;
                $scope.notaFiscal.declaracaoPrestacaoServico.valorLiquido = 0.0;

                if ($scope.notaFiscal.declaracaoPrestacaoServico.itens) {
                    angular.forEach($scope.notaFiscal.declaracaoPrestacaoServico.itens, function (servico) {
                        $scope.calculaValoresServico(servico);
                        $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos = Number($scope.notaFiscal.declaracaoPrestacaoServico.totalServicos) + Number(servico.total);
                        $scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo = Number($scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo) + Number(servico.baseCalculo);
                        $scope.notaFiscal.declaracaoPrestacaoServico.issCalculado = Number($scope.notaFiscal.declaracaoPrestacaoServico.issCalculado) + Number(servico.iss);
                        $scope.notaFiscal.declaracaoPrestacaoServico.deducoesLegais = Number($scope.notaFiscal.declaracaoPrestacaoServico.deducoesLegais) + Number(servico.deducoes);
                        $scope.notaFiscal.declaracaoPrestacaoServico.descontosIncondicionais = Number($scope.notaFiscal.declaracaoPrestacaoServico.descontosIncondicionais) + Number(servico.descontosIncondicionados);
                        $scope.notaFiscal.declaracaoPrestacaoServico.descontosCondicionais = Number($scope.notaFiscal.declaracaoPrestacaoServico.descontosCondicionais) + Number(servico.descontosCondicionados);
                    });


                    if (calcularRetencoes) {
                        if ($scope.isPrestadorSimplesNacional()) {
                            $scope.calcularRetencoesFederais($scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo);
                        } else {
                            $scope.criarRetencoesFederais($scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo);
                        }
                    }

                    $scope.notaFiscal.declaracaoPrestacaoServico.retencoesFederais = getTotalRetencoesFederais();
                    $scope.notaFiscal.aliquotaRetencoesFederais = getTotalAliquotaRetencoesFederais();

                    var valorRetido = getTotalRetencoesFederaisRetidas();
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.issRetido) {
                        valorRetido = valorRetido + $scope.notaFiscal.declaracaoPrestacaoServico.issCalculado;
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === 'TRIBUTACAO_MUNICIPAL' &&
                        !$scope.notaFiscal.declaracaoPrestacaoServico.issRetido &&
                        !$scope.isPrestadorSimplesNacional()) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.issPagar = $scope.notaFiscal.declaracaoPrestacaoServico.issCalculado;
                    }
                    $scope.notaFiscal.declaracaoPrestacaoServico.valorLiquido = $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos - valorRetido;
                }

                $scope.notaFiscal.totalServicos = Util.roundTo2($scope.notaFiscal.totalServicos);
                $scope.notaFiscal.baseCalculo = Util.roundTo2($scope.notaFiscal.baseCalculo);
                $scope.notaFiscal.issCalculado = Util.roundTo2($scope.notaFiscal.issCalculado);
                $scope.notaFiscal.deducoesLegais = Util.roundTo2($scope.notaFiscal.deducoesLegais);
                $scope.notaFiscal.descontosIncondicionais = Util.roundTo2($scope.notaFiscal.descontosIncondicionais);
                $scope.notaFiscal.descontosCondicionais = Util.roundTo2($scope.notaFiscal.descontosCondicionais);
                $scope.notaFiscal.issPagar = Util.roundTo2($scope.notaFiscal.issPagar);
                $scope.notaFiscal.valorLiquido = Util.roundTo2($scope.notaFiscal.valorLiquido);
            };

            $scope.criarRetencoesFederais = function (valor) {
                if (getTotalRetencoesFederais()) {
                    return;
                }
                var tributosFederais = {};
                PrestadorServicos.getTributosFederais({prestadorId: $scope.prestador.id},
                    function (result) {
                        tributosFederais = result;
                        tributosFederais.percentualPis = tributosFederais.pis ? tributosFederais.pis : 0;
                        tributosFederais.pis = tributosFederais.percentualPis ? ((tributosFederais.percentualPis / 100) * valor) : 0;
                        tributosFederais.percentualCofins = tributosFederais.cofins ? tributosFederais.cofins : 0;
                        tributosFederais.cofins = tributosFederais.percentualCofins ? ((tributosFederais.percentualCofins / 100) * valor) : 0;
                        tributosFederais.percentualInss = tributosFederais.inss ? tributosFederais.inss : 0;
                        tributosFederais.inss = tributosFederais.percentualInss ? ((tributosFederais.percentualInss / 100) * valor) : 0;
                        tributosFederais.percentualIrrf = tributosFederais.irrf ? tributosFederais.irrf : 0;
                        tributosFederais.irrf = tributosFederais.percentualIrrf ? ((tributosFederais.percentualIrrf / 100) * valor) : 0;
                        tributosFederais.percentualCsll = tributosFederais.csll ? tributosFederais.csll : 0;
                        tributosFederais.csll = tributosFederais.percentualCsll ? ((tributosFederais.percentualCsll / 100) * valor) : 0;
                        tributosFederais.percentualCpp = tributosFederais.cpp ? tributosFederais.cpp : 0;
                        tributosFederais.cpp = tributosFederais.percentualCpp ? ((tributosFederais.percentualCpp / 100) * valor) : 0;
                        tributosFederais.percentualInss = tributosFederais.inss ? tributosFederais.inss : 0;
                        tributosFederais.inss = tributosFederais.percentualInss ? ((tributosFederais.percentualInss / 100) * valor) : 0;
                        tributosFederais.percentualOutrasRetencoes = tributosFederais.outrasRetencoes ? tributosFederais.outrasRetencoes : 0;
                        tributosFederais.outrasRetencoes = tributosFederais.percentualOutrasRetencoes ? ((tributosFederais.percentualOutrasRetencoes / 100) * valor) : 0;
                        $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais = tributosFederais;
                    });
            };

            $scope.calcularValoresPorAliquota = function (aliquota, valor) {
                $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais[valor]
                    = ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais[aliquota] / 100)
                    * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                atualizarRentencaoFederal();
            };

            $scope.calcularAliquotaPorValor = function (valor, aliquota) {
                $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais[aliquota]
                    = ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais[valor] * 100)
                    / $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                atualizarRentencaoFederal();
            };

            function atualizarRentencaoFederal() {
                $scope.notaFiscal.retencoesFederais = getTotalRetencoesFederais();
            }

            $scope.calcularRetencoesFederais = function (valor) {
                if (!$scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais) {
                    $scope.criarRetencoesFederais(valor);
                }

                tributosFederais = $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais;

                if ($scope.notaFiscal.declaracaoPrestacaoServico.totalServicos && $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos > 0 && $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa) {

                    var tributosFederais = $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais;

                    tributosFederais.percentualPis = 0;
                    tributosFederais.pis = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.pisPasep) {
                        tributosFederais.percentualPis = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.pisPasep / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualPis = tributosFederais.percentualPis + (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.pisPasep / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualPis = tributosFederais.percentualPis * 100;
                        tributosFederais.percentualPis = parseFloat(tributosFederais.percentualPis).toFixed(2);
                        tributosFederais.pis = (tributosFederais.percentualPis / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    tributosFederais.percentualCofins = 0;
                    tributosFederais.cofins = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.cofins) {
                        tributosFederais.percentualCofins = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.cofins / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualCofins = tributosFederais.percentualCofins + (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.cofins / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualCofins = tributosFederais.percentualCofins * 100;
                        tributosFederais.percentualCofins = parseFloat(tributosFederais.percentualCofins).toFixed(2);
                        tributosFederais.cofins = (tributosFederais.percentualCofins / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    tributosFederais.percentualIrpj = 0;
                    tributosFederais.irpj = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.irpj) {
                        tributosFederais.percentualIrrf = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.irpj / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualIrrf = tributosFederais.percentualIrrf +
                                (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.irpjExcedente / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualIrrf = tributosFederais.percentualIrrf * 100;
                        tributosFederais.percentualIrrf = parseFloat(tributosFederais.percentualIrrf).toFixed(2);
                        tributosFederais.irrf = (tributosFederais.percentualIrrf / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    tributosFederais.percentualCsll = 0;
                    tributosFederais.csll = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.csll) {
                        tributosFederais.percentualCsll = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.csll / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualCsll = tributosFederais.percentualCsll +
                                (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.csllExcedente / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualCsll = tributosFederais.percentualCsll * 100;
                        tributosFederais.percentualCsll = parseFloat(tributosFederais.percentualCsll).toFixed(2);
                        tributosFederais.csll = (tributosFederais.percentualCsll / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    tributosFederais.percentualCpp = 0;
                    tributosFederais.cpp = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.cpp) {
                        tributosFederais.percentualCpp = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.cpp / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualCpp = tributosFederais.percentualCpp +
                                (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.cppExcedente / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualCpp = tributosFederais.percentualCpp * 100;
                        tributosFederais.percentualCpp = parseFloat(tributosFederais.percentualCpp).toFixed(2);
                        tributosFederais.cpp = (tributosFederais.percentualCpp / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    tributosFederais.percentualInss = 0;
                    tributosFederais.inss = 0;
                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.inss) {
                        tributosFederais.percentualInss = ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.inss / 100) * $scope.aliquotaEfetiva;
                        if ($scope.aliquotaServicoExcedente > 0) {
                            tributosFederais.percentualInss = tributosFederais.percentualInss +
                                (($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006.inssExcedente / 100) * $scope.aliquotaServicoExcedente);
                        }
                        tributosFederais.percentualInss = tributosFederais.percentualInss * 100;
                        tributosFederais.percentualInss = parseFloat(tributosFederais.percentualInss).toFixed(2);
                        tributosFederais.inss = (tributosFederais.percentualInss / 100) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                    }

                    var percentualOutrasRetencoes = 0;

                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.icms &&
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.icms > 0) {
                        percentualOutrasRetencoes = percentualOutrasRetencoes + $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.icms;
                    }

                    if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.ipi &&
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.ipi > 0) {
                        percentualOutrasRetencoes = percentualOutrasRetencoes + $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa.ipi;
                    }

                    if (percentualOutrasRetencoes > 0) {
                        tributosFederais.percentualOutrasRetencoes = (percentualOutrasRetencoes / 100) * $scope.aliquotaEfetiva;
                        tributosFederais.outrasRetencoes =
                            (tributosFederais.percentualOutrasRetencoes) * $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos;
                        tributosFederais.percentualOutrasRetencoes = tributosFederais.percentualOutrasRetencoes * 100;
                    }

                }
            };

            function getTotalRetencoesFederais() {
                var total = 0;
                if ($scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais) {

                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.pis) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.pis);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cofins) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cofins);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.inss) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.inss);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.irrf) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.irrf);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cpp) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cpp);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.csll) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.csll);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.outrasRetencoes) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.outrasRetencoes);
                    }
                }
                return total;
            }

            function getTotalRetencoesFederaisRetidas() {
                var total = 0;
                if ($scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais) {

                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.pis && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoPis) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.pis);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cofins && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoCofins) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cofins);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.inss && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoInss) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.inss);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.irrf && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoIrrf) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.irrf);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cpp && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoCpp) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.cpp);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.csll && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoCsll) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.csll);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.outrasRetencoes && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.retencaoOutrasRetencoes) {
                        total = total + ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.outrasRetencoes);
                    }
                }
                return total;
            }

            function getTotalAliquotaRetencoesFederais() {
                var total = 0;
                if ($scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais) {

                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualPis) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualPis);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCofins) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCofins);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualInss) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualInss);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualIrrf) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualIrrf);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCpp) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCpp);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCsll) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualCsll);
                    }
                    if ($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualOutrasRetencoes) {
                        total = total + Number($scope.notaFiscal.declaracaoPrestacaoServico.tributosFederais.percentualOutrasRetencoes);
                    }
                }
                return total;
            }

            $scope.removeServico = function (index) {
                $scope.notaFiscal.declaracaoPrestacaoServico.itens.splice(index, 1);
            };

            $scope.editaServico = function (index) {
                $scope.servico = $scope.notaFiscal.declaracaoPrestacaoServico.itens[index];
                $scope.removeServico(index);
                $scope.mostraFormServicos = true;
            };

            $scope.selecionouServico = function () {
                $scope.bloqueiaAliquota = true;
                $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil = null;
                $scope.art = null;
                if ($scope.notaFiscal.servico) {
                    $scope.notaFiscal.prestadoNoPais = true;
                    $scope.notaFiscal.municipio = $scope.municipio;
                    $scope.codigoServico = $scope.notaFiscal.servico.codigo;
                    $scope.notaFiscal.nomeServico = $scope.notaFiscal.servico.descricao;
                    $scope.notaFiscal.aliquotaServico = 0;
                    $scope.verificarExigibilidadeIss();
                    $scope.verificarRetencaoIss();
                }
            };

            $scope.loadServico = function () {
                if ($scope.notaFiscal.cnae) {
                    $scope.codigoCnae = $scope.notaFiscal.cnae.codigo;
                    $scope.servicos = Servico.getPorCnaePrestador({
                        cnaeId: $scope.notaFiscal.cnae.id,
                        prestadorId: $scope.prestador.id
                    });
                    angular.element('[id="field_codigoServico"]').focus();
                } else {
                    $scope.servicos = [];
                }
            };


            $scope.searchServico = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/prestadorServicos/servicoPrestador-search.html',
                    controller: 'ServicoPrestadorSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (result) {
                    $scope.servico.servico = result;
                    $scope.selecionouServico();
                }, function () {
                });
            };

            $scope.validarCamposObrigatorios = function () {
                $scope.mensagemCamposObrigatorios = [];
                if ($scope.notaFiscal.declaracaoPrestacaoServico.issRetido === true && $scope.notaFiscal.declaracaoPrestacaoServico.responsavelRetencao === undefined) {
                    $scope.mensagemCamposObrigatorios.push("O campo Responsável pela Retenção é obrigatório.");
                }

                if ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade == 'IDENTIFICADO') {
                    if ($scope.notaFiscal.tomador.dadosPessoais.cpfCnpj === null) {
                        $scope.mensagemCamposObrigatorios.push("O campo CPF ou CNPJ do Tomador de Serviço é obrigatório.");
                    }
                    if ($scope.notaFiscal.tomador.dadosPessoais.nomeRazaoSocial === null) {
                        $scope.mensagemCamposObrigatorios.push("O campo Nome ou Razão Social é obrigatório.");
                    }
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.itens.length === 0) {
                    $scope.mensagemCamposObrigatorios.push("É necessário adicionar ao menos um serviço para a Nota Fiscal.");
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.totalServicos <= 0) {
                    $scope.mensagemCamposObrigatorios.push("Os valores totais dos serviços devem ser maiores que ZERO.");
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.deducoesLegais != $scope.notaFiscal.declaracaoPrestacaoServico.totalServicos && $scope.notaFiscal.declaracaoPrestacaoServico.baseCalculo <= 0) {
                    $scope.mensagemCamposObrigatorios.push("Os valores totais da base de cálculo deve ser maiore que ZERO.");
                }
                if ($scope.notaFiscal.declaracaoPrestacaoServico.valorLiquido <= 0) {
                    $scope.mensagemCamposObrigatorios.push("O valor liquido deve ser maior que ZERO.");
                }
                if ($scope.notaFiscal.cancelamentoSubstituicao &&
                    !$scope.notaFiscal.cancelamentoSubstituicao.motivoCancelamento) {
                    $scope.mensagemCamposObrigatorios.push("O Motivo de Cancelamento é obrigatório.");
                }
                if ($scope.mensagemCamposObrigatorios.length > 0) {
                    Notificacao.camposObrigatorios($scope.mensagemCamposObrigatorios, "warning");
                    return false;
                } else {
                    return true;
                }
            };


            $scope.loadEnderecoByCEP = function (cep) {
                if (cep) {
                    CEP.getByCep({cep: cep}, function (endereco) {
                        if (endereco.municipio) {
                            $scope.notaFiscal.tomador.dadosPessoais.cep = endereco.cep;
                            $scope.notaFiscal.tomador.dadosPessoais.municipio = endereco.municipio;
                            $scope.notaFiscal.tomador.dadosPessoais.logradouro = endereco.logradouro;
                            $scope.notaFiscal.tomador.dadosPessoais.bairro = endereco.bairro;
                        }
                    });
                }
            };

            $scope.selecionarCnaePorCodigo = function () {
                for (var i = 0; i < $scope.cnaes.length; i++) {
                    if ($scope.cnaes[i].codigo.replace(/^0+/, '') === $scope.codigoCnae.replace(/^0+/, '')) {
                        $scope.notaFiscal.cnae = $scope.cnaes[i];
                        $scope.loadServico();
                        return;
                    }
                }
                $scope.notaFiscal.cnae = {}
            };

            $scope.selecionarServicoPorCodigo = function () {
                for (var i = 0; i < $scope.servicos.length; i++) {
                    if ($scope.servicos[i].codigo.replace(/^0+/, '') === $scope.codigoServico.replace(/^0+/, '')) {
                        $scope.notaFiscal.servico = $scope.servicos[i];
                        $scope.selecionouServico();
                        return;
                    }
                }
                $scope.notaFiscal.servico = {}
            };


            $scope.loadPaisByCodigo = function (codigo, donoDoServico) {
                if (codigo) {
                    Pais.getByCodigo({codigo: codigo}, function (pais) {
                        if (pais.id) {
                            donoDoServico.pais = pais;
                            donoDoServico.municipio = {};
                        } else {
                            Notificacao.warn("Atenção", "Não foi encontrado nenhum País com o código " + codigo);
                            donoDoServico.pais = {};
                        }
                    });
                }
            };

            $scope.searchMunicipio = function (caraQueTemMunicipio) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/municipio/municipio-search.html',
                    controller: 'MunicipioSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (municipio) {
                    caraQueTemMunicipio.municipio = municipio;
                    caraQueTemMunicipio.pais = {};
                    $scope.verificarExigibilidadeIss();
                    $scope.verificarRetencaoIss();
                }, function () {
                });
            };

            $scope.searchPais = function (donoDoServico) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/pais/pais-search.html',
                    controller: 'PaisSearchController',
                    size: 'lg'
                });
                modalInstance.result.then(function (pais) {
                    donoDoServico.pais = pais;
                    donoDoServico.municipio = {};
                }, function () {
                });
            };

            $scope.isFisica = function () {
                return $scope.notaFiscal
                    &&
                    (
                        ($scope.notaFiscal.declaracaoPrestacaoServico.modalidade == 'SEM_CPF') ||
                        ($scope.notaFiscal.declaracaoPrestacaoServico
                            && $scope.notaFiscal.tomador
                            && $scope.notaFiscal.tomador.dadosPessoais
                            && $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj
                            && $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '').length == 11)
                    )
            };

            $scope.mostrarDadosServico = function () {
                return $scope.notaFiscal &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.modalidade &&
                    (($scope.notaFiscal.declaracaoPrestacaoServico.modalidade === 'IDENTIFICADO' && $scope.temCpfOuCnpj() && !$scope.cpfDigitadoIgualPrestador())
                        || $scope.notaFiscal.declaracaoPrestacaoServico.modalidade !== 'IDENTIFICADO')
            }

            $scope.mostrarDadosGerais = function () {
                return $scope.notaFiscal &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.itens &&
                    $scope.notaFiscal.declaracaoPrestacaoServico.itens.length > 0;
            }

            $scope.temCpfOuCnpj = function () {
                return $scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.tomador
                    && $scope.notaFiscal.tomador.dadosPessoais
                    && $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj;
            }

            $scope.cpfDigitadoIgualPrestador = function () {
                return $scope.temCpfOuCnpj()
                    && $scope.cpfCnpjDoPrestador
                    && $scope.notaFiscal.tomador.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '')
                    === $scope.cpfCnpjDoPrestador.replace(/[^\w\s]/gi, '');
            }

            $scope.temExigibilidade = function () {
                return $scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao
                    && $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "TRIBUTACAO_MUNICIPAL";
            }

            $scope.exigibilidadeSuspensa = function () {
                return $scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao
                    && ($scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "EXIGIBILIDADE_SUSPENSA_DECISAO_JUDICIAL"
                        || $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "EXIGIBILIDADE_SUSPENSA_PROCESSO_ADMINISTRATIVO");
            };

            $scope.exigibilidadeNaoIncide = function () {
                return $scope.notaFiscal
                    && $scope.notaFiscal.declaracaoPrestacaoServico
                    && $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao
                    && ($scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "NAO_INCIDENCIA"
                        || $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "ISENCAO"
                        || $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "IMUNIDADE"
                        || $scope.notaFiscal.declaracaoPrestacaoServico.naturezaOperacao === "EXPORTACAO"
                        || $scope.isMei());
            };

            $scope.copiarNotaPorRps = function () {
                if ($scope.notaFiscal.rps && $scope.notaFiscal.rps.numero) {
                    NotaFiscal.copyPorRps({numeroRps: $scope.notaFiscal.rps.numero}, function (data) {
                        if (data && data.rps && data.rps.numero) {
                            $scope.notaFiscal = data;
                            if ($scope.notaFiscal.rps.dataEmissao) {
                                $scope.notaFiscal.rps.dataEmissao = new Date($scope.notaFiscal.rps.dataEmissao)
                            }
                            $scope.tratarNotaFiscalCopiada();
                        }
                    });
                }
            }

            $scope.alterarAliquotaSimples = function () {
                var modalDescriminacaoServico = $modal.open({
                    templateUrl: 'app/entities/notaFiscal/aliquota-simples-anexo-lei-1232006.html',
                    controller: 'AliquotaSimplesAnesoLei1232006Controller',
                    size: 'lg',
                    resolve: {
                        prestador: function () {
                            return $scope.notaFiscal.prestador;
                        }
                    }
                });
                modalDescriminacaoServico.result.then(function (data) {
                    if (data && data.faixaSelecionada && data.rbt12) {
                        $scope.notaFiscal.prestador.rbt12 = data.rbt12;
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006 = data;
                        $scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa = data.faixaSelecionada;

                        if ($scope.notaFiscal.prestador.enquadramentoFiscal.anexoLei1232006Faixa) {
                            $scope.calcularAliquotaServicoSNA();
                            $scope.calculaValoresDaNota(true);
                            if ($scope.notaFiscal.prestador.id) {
                                PrestadorServicos.salvarFaixaAndRbt12($scope.notaFiscal.prestador);
                            }
                        }
                    }
                });
            };

            $scope.validarDataEmissao = function () {
                if (!$scope.notaFiscal.emissao) {
                    $scope.notaFiscal.emissao = new Date();
                } else {

                    var emissaoSemHora = new Date($scope.notaFiscal.emissao.setHours(0, 0, 0, 0));
                    var ultimaEmissaoSemHora = new Date($scope.notaFiscal.ultimaEmissao.setHours(0, 0, 0, 0));

                    if (!$scope.emissaoRetroativaUltimaEmissao && emissaoSemHora < ultimaEmissaoSemHora) {
                        $scope.notaFiscal.emissao = new Date();
                        Notificacao.error('Erro', 'A Data de Emissão não pode ser inferior a última data de emissão ' + $filter('date')(ultimaEmissaoSemHora, 'dd/MM/yyyy'));
                    }
                }
                $scope.notaFiscal.declaracaoPrestacaoServico.competencia = $scope.notaFiscal.emissao;
            }

            $scope.buscarObra = function () {
                if ($scope.art) {
                    ConstrucaoCivil.buscarConstrucaoCivilByArt({art: $scope.art}, function (data) {
                        $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil = null;
                        if (data.id) {
                            $scope.notaFiscal.declaracaoPrestacaoServico.construcaoCivil = data;
                            $scope.selecionouObra();
                        } else {
                            Notificacao.error("Erro", "Número de ART não registrada no sistema.");
                            $scope.art = "";
                            angular.element('[id="field_art"]').focus();
                        }
                    });
                }
            }

        }])
;
