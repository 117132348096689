(function () {
    'use strict';

    angular.module('nfseApp').controller('ExportacaoXmlController',
        function ($scope, PrestadorServicos, Notificacao, localStorageService, DataUtil, NotaFiscal, $interval,
                  Util) {
            $scope.prestador = null;
            $scope.tipoDocumento = 'EMITIDA';

            $scope.tipo = "NORMAL";
            $scope.tipoData = "COMPETENCIA";
            $scope.dataInicial;
            $scope.dataFinal;
            $scope.competenciaAno;
            $scope.competenciaMes;
            $scope.numeroNotaFiscalInicial;
            $scope.numeroNotaFiscalFinal;
            $scope.meses;
            $scope.gerarArquivoXml;
            $scope.xml;


            PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (data) {
                $scope.prestador = data;
            });

            $scope.meses = Util.getMeses({});

            $scope.montarConsultaNotaFiscal = function (orderBy) {
                var campos = [];

                if ($scope.tipoDocumento === 'EMITIDA') {
                    var parametroQueryCampo = {
                        campo: "nf.prestador_id",
                        operador: "IGUAL",
                        valorLong: $scope.prestador.id
                    };
                    campos.push(parametroQueryCampo);
                } else {
                    if ($scope.prestador) {
                        var parametroQueryCampo = {
                            campo: "dptnf.cpfcnpj",
                            operador: "IGUAL",
                            valorString: Util.apenasNumeros($scope.prestador.pessoa.dadosPessoais.cpfCnpj)
                        };
                        campos.push(parametroQueryCampo);
                    } else {
                        var parametroQueryCampo = {
                            campo: "dptnf.cpfcnpj",
                            operador: "IGUAL",
                            valorString: Util.apenasNumeros($scope.account.login)
                        };
                        campos.push(parametroQueryCampo);
                    }
                }


                var campoData = $scope.tipoData === "COMPETENCIA" ? "dps.competencia" : "nf.emissao";
                if ($scope.tipoData === "EMISSAO") {
                    if ($scope.dataInicial) {
                        var dataInicial = DataUtil.dateWithOutHoursMinutesSeconds($scope.dataInicial);
                        parametroQueryCampo = {
                            campo: "nf.emissao",
                            operador: "MAIOR_IGUAL",
                            valorDate: dataInicial
                        };
                        campos.push(parametroQueryCampo);
                    }
                    if ($scope.dataFinal) {
                        var dataFinal = DataUtil.dateWithOutHoursMinutesSeconds($scope.dataFinal);
                        parametroQueryCampo = {
                            campo: "nf.emissao",
                            operador: "MENOR_IGUAL",
                            valorDate: dataFinal
                        };
                        campos.push(parametroQueryCampo);
                    }
                } else if ($scope.tipoData === "COMPETENCIA") {
                    parametroQueryCampo = {
                        campo: "extract(year from dps.competencia)",
                        operador: "IGUAL",
                        valorInteger: $scope.competenciaAno
                    };
                    campos.push(parametroQueryCampo);
                    parametroQueryCampo = {
                        campo: "extract(month from dps.competencia)",
                        operador: "IGUAL",
                        valorInteger: $scope.competenciaMes.numeroMes
                    };
                    campos.push(parametroQueryCampo);
                }

                if ($scope.numeroNotaFiscalInicial) {
                    parametroQueryCampo = {
                        campo: " nf.numero ",
                        operador: "MAIOR_IGUAL",
                        valorLong: $scope.numeroNotaFiscalInicial
                    };
                    campos.push(parametroQueryCampo);
                }

                if ($scope.numeroNotaFiscalFinal) {
                    parametroQueryCampo = {
                        campo: " nf.numero ",
                        operador: "MENOR_IGUAL",
                        valorLong: $scope.numeroNotaFiscalFinal
                    };
                    campos.push(parametroQueryCampo);
                }

                return {
                    offset: $scope.page,
                    limit: $scope.per_page,
                    parametrosQuery: [{
                        juncao: " and ",
                        parametroQueryCampos: campos
                    }],
                    orderBy: orderBy
                };
            };

            function filtrosValidos() {
                if ($scope.tipoData === "EMISSAO" && !$scope.dataInicial) {
                    Notificacao.warn("Atenção", "A Emissão Inicial deve ser informada.");
                    return false;
                }
                if ($scope.tipoData === "EMISSAO" && !$scope.dataFinal) {
                    Notificacao.warn("Atenção", "A Emissão Final deve ser informada.");
                    return false;
                }
                if ($scope.tipoData === "COMPETENCIA" && !$scope.competenciaMes) {
                    Notificacao.warn("Atenção", "A Competência deve ser informada.");
                    return false;
                }
                if ($scope.tipoData === "COMPETENCIA" && !$scope.competenciaAno) {
                    Notificacao.warn("Atenção", "O Ano deve ser informado.");
                    return false;
                }
                return true;
            }

            $scope.consultarXml = function () {
                NotaFiscal.consultarXml($scope.baixarXml, function (result) {
                    $scope.xml = result;
                    console.log("CONSULTA PERCENTUAL - {}", $scope.xml.percentual);
                    if (!$scope.xml || $scope.xml.percentual >= 100) {
                        if ($scope.interval) {
                            $interval.cancel($scope.interval);
                        }
                        console.log("FINALIZOU CONSULTA");
                        if ($scope.xml.total === 0) {
                            Notificacao.warn("Atenção", "Nenhum nota fiscal encontrado para os filtros selecionados.")
                            $scope.xml = null;
                        }
                    }
                });
            };

            $scope.exportarXml = function () {
                if (!filtrosValidos())
                    return;
                $scope.baixarXml = {
                    tipo: $scope.tipo,
                    consultaGenericaNfseDTO: $scope.montarConsultaNotaFiscal(" order by nf.numero desc "),
                    inscricao: $scope.prestador.inscricaoMunicipal,
                    percentual: 0
                };
                NotaFiscal.baixarXml($scope.baixarXml, function () {
                    verificarSituacaoXml()
                });
            };

            $scope.gerarArquivoXml = function () {
                if ($scope.xml.percentual >= 100) {
                    $scope.baixandoArquivo = true;
                    var a = document.createElement('a');
                    if ($scope.baixarXml.tipo === 'NORMAL') {
                        a.href = 'data:application/xml;base64,' + $scope.xml.conteudo;
                        a.download = 'lote-notas-fiscais.xml';
                    } else {
                        a.href = 'data:application/x-bzip;base64,' + $scope.xml.conteudo;
                        a.download = 'lote-notas-fiscais.zip';
                    }
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                    $scope.baixandoArquivo = false;
                    $scope.xml = null;
                }
            }

            function verificarSituacaoXml() {
                $scope.consultarXml();
                $scope.interval = $interval(function () {
                    $scope.consultarXml();
                }, 3000);
            }

            $scope.$on("$destroy", function (event) {
                console.log("TIMOUT ooff");
                $interval.cancel($scope.interval);
            });

            $scope.handleTipoData = function () {
                $scope.dataInicial = null;
                $scope.dataFinal = null;
                $scope.competenciaAno = null;
                $scope.competenciaMes = null;

                if ($scope.tipoData === "EMISSAO") {
                    var hoje = new Date();
                    var month = hoje.getMonth();
                    var fullYear = hoje.getFullYear();
                    $scope.dataInicial = new Date(fullYear, month, 1);
                    $scope.dataFinal = hoje;
                } else {
                    var dataAtual = new Date();
                    $scope.competenciaAno = dataAtual.getFullYear();
                    angular.forEach($scope.meses, function (mes) {
                        if (mes.numeroMes === dataAtual.getMonth() + 1) {
                            $scope.competenciaMes = mes;
                        }
                    });
                }
            };

            $scope.handleTipoData();
        });
})();
