(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('CancelamentoNotaFiscalController', function ($scope, NotaFiscal,
                                                                  entity, SweetAlert, $modalInstance, Account) {
                $scope.notaFiscal = entity;
                $scope.podeSolicitar = false;
                $scope.cancelando = false;
                $scope.substituida = false;
                $scope.numeroSubstituta;

               if (!$scope.notaFiscal.declaracaoPrestacaoServico.cancelamento ||
                !$scope.notaFiscal.declaracaoPrestacaoServico.cancelamento.situacaoFiscal ||
                $scope.notaFiscal.declaracaoPrestacaoServico.cancelamento.situacaoFiscal == 'NAO_DEFERIDO') {
                    $scope.podeSolicitar = true;
                    $scope.cancelamento = {notaFiscal: entity};
                    Account.get().$promise
                        .then(function (account) {
                            $scope.cancelamento.usuarioTomador = account.data;
                        });
                }

                $scope.cancelarNota = function () {
                    SweetAlert.swal({
                            title: "Confirme o cancelamento",
                            text: "Você tem certeza que quer cancelar a Nota Fiscal?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                            cancelButtonText: "Não",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm && !$scope.cancelando) {
                                $scope.cancelando = true;
                                NotaFiscal.cancelar($scope.cancelamento, function (data) {
                                    $modalInstance.close(data);
                                });
                            }
                        });
                };

                $scope.findNotaSubstituta = function () {
                    $scope.cancelamento.notaFiscalSubstituta = null;
                    NotaFiscal.porNumero({numero: $scope.numeroSubstituta}, function (nota) {
                        if (nota && nota.id == $scope.cancelamento.notaFiscal.id) {
                            Notificacao.warn("Atenção", "Nota Fisca de Substituição deve ser diferente da " +
                                "Nota Fiscal Cancelada.");
                        }
                        $scope.cancelamento.notaFiscalSubstituta = nota;
                    });
                };

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                };
            });
})();
