'use strict';

angular.module('nfseApp')
    .controller('TomadorController', function ($scope, $filter, Tomador, TomadorSearch, ParseLinks, Notificacao, ExportarXls) {
        $scope.tomadors = [];
        $scope.page = 1;
        $scope.per_page = 10;
        $scope.loadAll = function () {
            Tomador.query({
                page: $scope.page,
                per_page: $scope.per_page
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.tomadors = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            Tomador.get({id: id}, function (result) {
                $scope.tomador = result;

                Notificacao.confirmDelete(
                    function () {
                        $scope.confirmDelete(id);
                    });

            });
        };

        $scope.confirmDelete = function (id) {
            Tomador.delete({id: id},
                function () {
                    $scope.loadAll();
                    Notificacao.info("Removido!", "O Tomador foi removido com sucesso.");
                }, function (error) {
                    Notificacao.warn("Atenção!", "O Tomador possúi vinculo com nota fiscal. Por isso não pode ser excluído.");
                });
        };

        $scope.search = function () {
            Tomador.query({query: $scope.searchQuery}, function (result) {
                $scope.tomadors = result;
            }, function (response) {
                if (response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.tomador = {id: null};
        };


        var colunas = [
            {descricao: 'Apelido', valor: 'apelido'},
            {descricao: 'Cpf/Cnpj', valor: 'cpfCnpj'},
            {descricao: 'Nome/Razão Social', valor: 'nomeRazaoSocial'},
            {descricao: 'Email', valor: 'email'},
        ];

        function getLinhas() {
            var linhas = [];
            for (var i = 0; i < $scope.tomadors.length; i++) {
                var tomador = $scope.tomadors[i];
                tomador.apelido =
                    linhas.push({
                        apelido: tomador.dadosPessoais.apelido,
                        cpfCnpj: tomador.dadosPessoais.cpfCnpj,
                        nomeRazaoSocial: tomador.dadosPessoais.nomeRazaoSocial,
                        email: tomador.dadosPessoais.email
                    });
            }
            return linhas;
        }

        $scope.baixarXls = function () {
            var xls = {
                colunas: colunas,
                linhas: getLinhas()
            };
            ExportarXls.getXls(xls);
        };

        $scope.baixarPDF = function () {
            var xls = {
                colunas: colunas,
                linhas: getLinhas()
            };
            ExportarXls.getPdf(xls);
        };
    });
