'use strict';

angular.module('nfseApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('registrarEmpresa', {
                parent: 'entity',
                url: '/registrar-empresa',
                data: {
                    roles: ['ROLE_CONTRIBUINTE'],
                    pageTitle: 'Informe seus dados para o cadastro'
                },
                ncyBreadcrumb: {
                    label: 'Registro de empresa'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/registrar-empresa/registrar-empresa.html',
                        controller: 'RegistrarEmpresaController'
                    }
                },
                params: {
                    cpfCnpj: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            cpfCnpj: $stateParams.cpfCnpj
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('servico');
                        $translatePartialLoader.addPart('cnae');
                        $translatePartialLoader.addPart('mes');
                        return $translate.refresh();
                    }],
                    convite: function () {
                        return {};
                    }
                }
            });
    });
