'use strict';

angular.module('nfseApp')
    .factory('NotaFiscal', function ($resource, $state, $modal, DateUtils, SweetAlert, BloqueioEmissaoNfse) {

        function transformResponseList(data) {
            data = angular.fromJson(data);
            for (var i = 0; i < data.length; i++) {
                var nota = data[i];
                convertDateFromServer(nota);
            }
            return data;
        }

        function convertDateFromServer(data) {
            if (data.emissao) {
                data.emissao = DateUtils.convertLocaleDateFromServer(data.emissao);
            }
            if (data.ultimaEmissao) {
                data.ultimaEmissao = DateUtils.convertLocaleDateFromServer(data.ultimaEmissao);
            }
            if (data.declaracaoPrestacaoServico && data.declaracaoPrestacaoServico.competencia) {
                data.declaracaoPrestacaoServico.competencia =
                    DateUtils.convertLocaleDateFromServer(data.declaracaoPrestacaoServico.competencia);
            }
            if (data.rps && data.rps.dataEmissao) {
                data.rps.dataEmissao =
                    DateUtils.convertLocaleDateFromServer(data.rps.dataEmissao);
            }
        }

        function convertDateToServer(data) {
            if (data.emissao) {
                data.emissao = DateUtils.convertLocaleDateToServer(data.emissao);
            }
            if (data.ultimaEmissao) {
                data.ultimaEmissao = DateUtils.convertLocaleDateToServer(data.ultimaEmissao);
            }
            if (data.declaracaoPrestacaoServico && data.declaracaoPrestacaoServico.competencia) {
                data.declaracaoPrestacaoServico.competencia =
                    DateUtils.convertLocaleDateToServer(data.declaracaoPrestacaoServico.competencia);
            }
        }

        var resource = $resource('api/notaFiscals/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    convertDateFromServer(data);
                    return data;
                }
            },
            'getPublica': {
                url: '/api/publica/nota-fiscal/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        convertDateFromServer(data);
                    }
                    return data;
                }
            },
            'getPublicaCartaCorrecao': {
                url: '/api/publica/carta-correcao/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        convertDateFromServer(data);
                    }
                    return data;
                }
            },
            'buscarNotasEmCancelamento': {
                url: '/api/notaFiscals/em-cancelamento/:cpf/:cnpj',
                method: 'GET',
                isArray: true
            },
            'buscarNotasComoTomador': {
                method: 'GET',
                url: 'api/notaFiscals/notas-tomadas',
                isArray: true
            },
            'buscarNotasComoTomadorAndLote': {
                method: 'GET',
                url: 'api/notaFiscals/notas-tomadas-por-lote',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for (var i = 0; i < data.length; i++) {
                        var nota = data[i];
                        convertDateFromServer(nota);
                    }
                    return data;
                }
            },
            'buscarNotasFiscais': {
                method: 'POST',
                url: 'api/buscar-notas-fiscais',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data)
                        for (var i = 0; i < data.length; i++) {
                            var nota = data[i];
                            convertDateFromServer(nota);
                        }
                    return data;
                }
            },
            'buscarNotasSemDeclaracaoPorCompetencia': {
                method: 'GET',
                url: 'api/notas-sem-declaracao-por-competencia',
                isArray: true
            },
            'new': {
                method: 'GET',
                url: 'api/notaFiscals/new',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        convertDateFromServer(data);
                    }
                    return data;
                }
            },
            'newSubstituicao': {
                method: 'GET',
                url: 'api/notaFiscals/new-substituicao',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        convertDateFromServer(data);
                    }
                    return data;
                }
            },
            'cancelar': {
                method: 'POST',
                url: 'api/notaFiscals/cancelar',
                transformRequest: function (data) {
                    if (data.notaFiscal)
                        convertDateToServer(data.notaFiscal);
                    if (data.notaFiscalSubstituta)
                        convertDateToServer(data.notaFiscalSubstituta);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'cartaCorrecao': {
                method: 'POST',
                url: 'api/notaFiscals/carta-correcao'
            },
            'submeterCancelamento': {
                method: 'POST',
                url: 'api/notaFiscals/submeter-cancelamento'
            },
            'imprimirCartaCorrecao': {
                method: 'GET',
                url: 'api/notaFiscals/carta-correcao-impressao',
                headers: {
                    accept: 'application/pdf'
                }
            },
            'buscarCartaCorrecao': {
                method: 'GET',
                isArray: true,
                url: 'api/notaFiscals/carta-correcao-por-nota/:idNotaFiscal'
            },
            'getCancelamentos': {
                method: 'GET',
                url: 'api/notaFiscals/cancelamentos/:id'
            },
            'autenticar': {
                method: 'POST',
                url: 'api/notaFiscals/autenticar',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'copy': {
                method: 'GET',
                url: 'api/notaFiscals/copy/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.copiada = true;
                        convertDateFromServer(data);
                    }
                    return data;
                }
            },
            'copyPorRps': {
                method: 'GET',
                url: 'api/notaFiscals/copy-rps/:numeroRps',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getItemDeclaracaoServicoEmitidosPorPrestador': {
                method: 'GET',
                isArray: true,
                url: "api/notaFiscals/item_declaracao_emitidos"
            },
            'getTomadorPorCpfCnpj': {
                method: 'GET',
                url: "api/tomador_da_nota_por_cpfCnpj/:cpfCnpj",
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'buscarNotasNaDeclaracao': {
                method: 'GET',
                url: "api/notas-na-declaracao",
                isArray: true,
                transformResponse: transformResponseList

            },
            'getNotasNaoDeclaradas': {
                method: 'GET',
                url: "api/notas_nao_declaradas",
                isArray: true
            },
            'getNotasRetencaoNaoDeclaradas': {
                method: 'GET',
                url: "api/notas_retencao_nao_declaradas",
                isArray: true
            },
            'getEmitidasPorPrestadorMes': {
                url: "/api/nota_emita_por_prestador_mes",
                method: 'GET',
                isArray: true
            },
            'getEmitidasPorMes': {
                url: "/api/nota_emita_por_mes",
                method: 'GET',
                isArray: true
            },
            'imprime': {
                method: 'GET',
                url: "/publico/notaFiscal/imprime/:id",
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true,
            },
            'imprimeRelatorio': {
                method: 'POST',
                url: "/api/imprimir-relatorio-notas-fiscais",
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true,
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    convertDateToServer(data);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    convertDateToServer(data)
                    return angular.toJson(data);
                }
            },
            'enviarPorEmail': {
                url: "/api/enviar-nota-fiscal",
                method: 'PUT'
            },
            'baixarXml': {
                method: 'POST',
                url: '/api/notaFiscals/baixar-xml'
            },
            'consultarXml': {
                method: 'POST',
                url: '/api/notaFiscals/consultar-xml'
            },
            'baixarXmlNotaFiscal': {
                method: 'GET',
                url: '/api/notaFiscals/baixar-xml-nota-fiscal'
            },
            'baixarXls': {
                method: 'GET',
                url: '/api/notaFiscals/baixar-xls'
            },
            'getParcelasDaNota': {
                method: 'GET',
                url: 'api/notaFiscals/parcelas/:id',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getServicosDeclaradosIssRetido': {
                method: 'GET',
                url: 'api/notaFiscals/servicos-declarados-iss-retido',
                isArray: true,
                transformResponse: transformResponseList
            },
            'porNumero': {
                method: 'GET',
                url: 'api/notaFiscal-por-numero'
            }
        });

        resource.downloadXmlNotaFiscal = function (idNotaFiscal) {
            resource.baixarXmlNotaFiscal({
                idNotaFiscal: idNotaFiscal
            }, function (result) {
                var a = document.createElement('a');
                a.href = 'data:application/xml;base64,' + result.conteudo;
                a.target = '_blank';
                a.download = 'notaFiscal' + idNotaFiscal + '.xml';
                document.body.appendChild(a);
                a.click();
            });
        };

        resource.cancelarNota = function (nota, onSucess) {
            var modalInstance = $modal.open({
                templateUrl: 'app/entities/notaFiscal/cancelamentoNotaFiscal.html',
                controller: 'CancelamentoNotaFiscalController',
                size: 'lg',
                resolve: {
                    entity: function (NotaFiscal) {
                        return NotaFiscal.get({id: nota.id}).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('motivoCancelamento');
                        $translatePartialLoader.addPart('situacaoDeferimento');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function (notaFiscal) {
                if (notaFiscal.declaracaoPrestacaoServico.situacao === 'CANCELADA') {
                    SweetAlert.success("Operação realizada", "Nota Fiscal Cancelada!");
                } else {
                    SweetAlert.warning("Solicitação em análise", "Assim que aprovada sua solicitação o documento será cancelado!");
                }
                onSucess();
            }, function () {
            });
        };

        resource.emitirNotaFiscal = function () {
            BloqueioEmissaoNfse.buscarBloqueioPrestador({}, function (result) {
                if (result && result.id) {
                    $modal.open({
                        templateUrl: 'app/entities/bloqueio-emissao-nfse/bloqueio-emissao-nfse.html',
                        controller: 'BloqueioEmissaoNfseController',
                        keyboard: false,
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            bloqueio: function () {
                                return result;
                            }
                        }
                    });
                } else {
                    $state.go('notaFiscal.new');
                }
            });
        }

        return resource;
    })
;
