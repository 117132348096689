(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('AvisosSistemaController',
            function ($scope, $sanitize, $modalInstance, avisos) {
                $scope.avisos = avisos;
                $scope.aviso = $scope.avisos.splice(0, 1)[0];

                $scope.continuar = function () {
                    if ($scope.avisos.length == 0) {
                        $modalInstance.dismiss('cancel');
                    } else {
                        $scope.aviso = $scope.avisos.splice(0, 1)[0];
                    }
                }

                $scope.trustAsHtml = function (value) {
                    return $sanitize(value);
                };
            });
})();
