'use strict';

angular.module('nfseApp')
    .factory('DeclaracaoMensalServico', function ($resource, DateUtils) {
        function convertDateFromServer(data) {
            if (data.abertura) {
                data.abertura = DateUtils.convertLocaleDateFromServer(data.abertura);
            }
            if (data.encerramento) {
                data.encerramento = DateUtils.convertLocaleDateFromServer(data.encerramento);
            }
            if (data.vencimento) {
                data.vencimento = DateUtils.convertLocaleDateFromServer(data.vencimento);
            }
        }

        function convertDateToServer(data) {
            if (data.abertura) {
                data.abertura = DateUtils.convertLocaleDateToServer(data.abertura);
            }
            if (data.encerramento) {
                data.encerramento = DateUtils.convertLocaleDateToServer(data.encerramento);
            }
        }

        var resource = $resource('api/declaracaoMensalServicos/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    convertDateToServer(data);
                    return angular.toJson(data);
                }
            },
            'getMeses': {
                url: 'api/declaracaoMensalServicos/meses',
                method: 'GET',
                isArray: true
            },
            'getMesesNaoDeclarados': {
                url: 'api/declaracaoMensalServicos/meses_nao_declarados',
                method: 'GET',
                isArray: true
            },
            'getNotasDeclaradasPorCompetenciaEPrestador': {
                url: '/api/nota_declarada_por_competencia_prestador/:idPrestador/:mes/:ano/:tipoMovimento',
                method: 'GET',
                isArray: true
            },
            'novaDms': {
                url: '/api/nova-dms',
                method: 'GET'
            },
            'getNotasDeclaradasPorDMS': {
                url: '/api/nota_declarada_por_dms/:id',
                method: 'GET',
                isArray: true
            },
            'update': {method: 'PUT'},
            'imprimirDam': {
                method: 'GET',
                url: "/api/imprimir-dam/:id",
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true,
            },
            'getEstatisticasDeclaradas': {
                url: '/api/estatistica-mensal-declarada',
                method: 'GET',
                isArray: true
            },
            'cancelar': {
                url: '/api/cancelar-declaracao-mensal-servico/:id',
                method: 'GET'
            },
            'importarDesif': {
                url: '/api/importar-desif',
                method: 'POST'
            },
            'emitirProtocoloRecebimentoDms': {
                method: 'GET',
                url: "/api/emitir-protocolo-declaracao/:id",
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
                cache: true
            },
            'buscarItensDeclaracaoServico': {
                method: 'GET',
                url: '/api/itens-declaracao-servico',
                isArray: true
            },
            'consultar': {
                method: 'POST',
                url: '/api/declaracaoMensalServicos/consultar',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data)
                        for (var i = 0; i < data.length; i++) {
                            var dms = data[i];
                            convertDateFromServer(dms);
                        }
                    return data;
                }
            }
        });

        return resource;
    });
