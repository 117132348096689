'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoRetencaoDetailController',
    function ($scope, entity, NotaFiscal, ParseLinks) {
        $scope.declaracaoMensalServico = entity;
        $scope.page;
        $scope.per_page = 20;
        $scope.links;
        $scope.notas;

        $scope.buscarNotasNaDeclaracao = function () {
            NotaFiscal.buscarNotasNaDeclaracao({
                    page: $scope.page - 1,
                    per_page: $scope.per_page,
                    idDeclaracaoMensalServico: $scope.declaracaoMensalServico.id
                },
                function (data, headers) {
                    if (headers)
                        $scope.links = ParseLinks.parse(headers('link'));
                    $scope.notas = data;
                }
            );
        };

        $scope.buscarNotasNaDeclaracao();

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.buscarNotasNaDeclaracao();
        };
});

