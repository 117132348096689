'use strict';

angular.module('nfseApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('credenciamento', {
                parent: 'entity',
                url: '/credenciamento',
                data: {
                    roles: ['ROLE_CONTRIBUINTE'],
                    pageTitle: 'Cadastro Inicial do Prestador de Serviços'
                },
                ncyBreadcrumb: {
                    label: 'Credenciamento'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credenciamento/credenciamento.html',
                        controller: 'CredenciamentoController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prestadorServicos');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('documentacaoCredenciamento', {
                parent: 'entity',
                url: '/documentos-apresentacao/{id}',
                data: {
                    roles: ['ROLE_CONTRIBUINTE'],
                    pageTitle: 'Documentos para o Credencimento'
                },
                ncyBreadcrumb: {
                    label: 'Documentação'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credenciamento/documentos.html',
                        controller: 'DocumentosController'
                    },
                    'menu@': {
                        templateUrl: 'app/components/menu/navigation.html',
                        controller: 'NavbarController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('prestadorServicos');
                        $translatePartialLoader.addPart('dadosPessoais');
                        $translatePartialLoader.addPart('endereco');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PrestadorServicos', function ($stateParams, PrestadorServicos) {
                        return PrestadorServicos.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('imprimeTermoCredenciamento', {
                url: '/impressao-termo-credenciamento/{id}',
                parent: 'documentacaoCredenciamento',
                data: {
                    roles: ['ROLE_CONTRIBUINTE'],
                    pageTitle: 'Documentos para o Credencimento'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes do Termo de Credenciamento'
                },
                onEnter: ['$stateParams', '$state', '$modal', function ($stateParams, $state, $modal) {
                    $modal.open({
                        templateUrl: 'app/entities/credenciamento/termo-credenciamento-impressao.html',
                        controller: 'TermoCredenciamentoImpressaoController',
                        size: 'lg',
                        resolve: {
                            id: function () {
                                return $stateParams.id;
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
    });
