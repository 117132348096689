'use strict';

angular.module('nfseApp')
    .controller('LoginController', function ($rootScope, $scope, $state, $timeout, Auth, Account,
                                             PrestadorServicos, Principal, Idle, SweetAlert, User,
                                             Notificacao, MensagemContribuinteUsuarioService,
                                             localStorageService, AvisoSistema, $modal) {
        $scope.user = {};
        $scope.errors = {};
        $scope.recaptcha = null;

        $scope.rememberMe = true;
        $timeout(function () {
            angular.element('[ng-model="username"]').focus();
        });

        //manda p o home caso tente acessar o login e já está logado
        Principal.identity().then(function (account) {
            if (account) {
                //manda p o home caso tente acessar o login e já está logado
                $state.go('home', {reload: true});
            } else {
                $scope.verificarAvisos();
            }
        });

        $scope.verificarAvisos = function () {
            AvisoSistema.buscarAvisosVigentes({}, function (result) {
                $modal.open({
                    templateUrl: 'app/entities/aviso-sistema/avisos-sistema.html',
                    controller: 'AvisosSistemaController',
                    keyboard: false,
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        avisos: function () {
                            return result;
                        }
                    }
                });
            });
        }

        $scope.login = function (event) {
            if (!$scope.recaptcha) {
                Notificacao.error('Erro', 'Captcha Inválido.');
                return;
            }
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                $rootScope.exibiuMensagem = false;

                $scope.posLogin();

                $scope.$emit('nfseApp:navbarUpdate', event);
                // start watching when the app runs. also starts the Keepalive service by default.
                Idle.watch();
                $rootScope.$on('IdleStart', function () {
                    SweetAlert.swal({
                            title: "Cadê você?",
                            text: "Já faz algum tempo que você não trabalha na aplicação, logo sua sessão irá expirar e será redirecionado para a tela de login",
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Voltar!",
                            closeOnConfirm: true
                        },
                        function () {
                            Account.get();
                        });
                    // the user appears to have gone idle
                });
                $rootScope.$on('IdleTimeout', function () {
                    SweetAlert.warning("OPS!", "Sua sessão expirou, terá que fazer login novamente");
                    Auth.logout();
                    $state.go('login');
                    // the user has timed out (meaning idleDuration + timeout has passed without any activity)
                    // this is where you'd log them
                });

            }).catch(function (error) {
                console.log(error);
                var msg = [];
                if (!$scope.username) {
                    msg.push("Informe o CPF ou CNPJ");
                }
                if (!$scope.password) {
                    msg.push("Informe a senha");
                }
                if (error.data && error.data.error_description && error.data.error_description === 'UserNotActivated') {
                    msg.push(" Seu cadastro ainda não foi ativado. Por favor, verifique se recebeu um e-mail contendo instruções de ativação do cadastro. Caso contrário utilize a opção 'Esqueceu a senha?' para reenviar o e-mail de ativação do cadastro. Se mesmo assim não receber o e-mail verifique na sua caixa de Spam");
                }
                if (msg.length == 0) {
                    User.acrescentarTentativaAcesso({login: $scope.username});
                    msg.push("Por favor verifique suas credenciais e tente novamente");
                }
                SweetAlert.swal({
                    title: "Problemas na autenticação",
                    text: msg.join(", "),
                    type: "error",
                    showCancelButton: false,
                    confirmButtonText: "Voltar!",
                    closeOnConfirm: true
                });
            });
        };

        $scope.posLogin = function () {
            var prestador = localStorageService.get("prestadorPrincipal");
            if (!Principal.isInRole('ROLE_ADMIN') && prestador && prestador.prestador && prestador.id) {
                MensagemContribuinteUsuarioService.proximaMensagemNaoLida({}, function (data) {
                    if (data && data.id) {
                        $state.go("mensagemContribuinteUsuarioNew", {id: data.id});
                    } else {
                        $scope.redirecionar();
                    }
                });
            } else {
                $scope.redirecionar();
            }
        }

        $scope.redirecionar = function () {
            if ($rootScope.previousStateName === 'register') {
                $state.go('home', {reload: true});
            } else if ($rootScope.previousStateName === 'requestReset') {
                $state.go('home', {reload: true});
            } else {
                User.zerarTentativaAcesso({login: $scope.username});
                $rootScope.back();
            }
        }

        $scope.handleCpf = function () {
            $scope.username = soAlfaNumerico($scope.username);
        }
    });
