(function () {
    'use strict';

angular.module('nfseApp')
    .factory('MensagemContribuinteUsuarioService', function ($resource, DateUtils) {
        function transformResponseList(data) {
            data = angular.fromJson(data);
            for (var i = 0; i < data.length; i++) {
                var nota = data[i];
                transformResponse(nota);
            }
            return data;
        }

        function transformResponse(data) {
            if (!data) {
                return;
            }
            data = angular.fromJson(data);
            if (data.dataLeitura) {
                data.dataLeitura = DateUtils.convertLocaleDateFromServer(data.dataLeitura);
            }
            if (data.mensagem) {
                if (data.mensagem.emitidaEm) {
                    data.mensagem.emitidaEm = DateUtils.convertLocaleDateFromServer(data.mensagem.emitidaEm);
                }
            }
            return data;
        }

        var resource = $resource('api/mensagem-contribuinte-usuario/:id', {}, {
            'consultar': {
                url: 'api/mensagem-contribuinte-usuario/consultar',
                method: 'POST',
                isArray: true,
                transformResponse: transformResponseList
            },
            'proximaMensagemNaoLida': {
                url: 'api/mensagem-contribuinte-usuario/proxima-nao-lida',
                method: 'GET',
                transformResponse: transformResponse
            },
            'marcarComoLida': {
                url: 'api/mensagem-contribuinte-usuario/marcar-como-lida',
                method: 'POST'
            }
        });

        return resource;
    });
})();
