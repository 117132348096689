'use strict';

angular.module('nfseApp')
    .controller('RegisterController', function ($scope, $translate, $timeout, Auth,
                                                Notificacao, $state, convite, Register,
                                                CEP, $modal, Municipio, Pessoa, PrestadorServicos, Configuracao) {

        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.errorUserExists = null;
        $scope.registerAccount = {};
        $scope.convite = convite;
        $scope.registerAccount.dadosPessoais = {};

        Configuracao.get({}, function (data) {
            $scope.configuracao = data;
        });

        $timeout(function () {
            angular.element('[ng-model="registerAccount.login"]').focus();
        });

        $scope.register = function () {
            $scope.registerAccount.login = $scope.registerAccount.dadosPessoais.cpfCnpj;
            if ($scope.registerAccount.password !== $scope.confirmPassword) {
                Notificacao.error('A senha e sua confirmação devem ser iguais! ');
                $scope.doNotMatch = 'ERROR';
                return
            }

            if ($scope.isCnpj()) {
                if (!$scope.registerAccount.dadosPessoais.municipio || $scope.registerAccount.dadosPessoais.municipio.id === $scope.configuracao.municipio.id) {
                    Notificacao.error("Atenção.",
                        " <p>Este cadastro não contempla as Empresas de Dentro do Município.</p>\n" +
                        "                        <p>Para obter acesso ao sistema com Empresas de Dentro do Município é necessário realizar o\n" +
                        "                            credenciamento junto à Prefeitura Municipal.</p>\n" +
                        "                        <p>Entre em contato com a Prefeitura para maiores informações.</p>");

                    return;
                }
                PrestadorServicos.buscarPorCpfCnpj({cpfCnpj: $scope.registerAccount.dadosPessoais.cpfCnpj.replace(/[^\w\s]/gi, '')},
                    function (result) {
                        if (result && result.length > 0) {
                            Notificacao.error("Atenção.",
                                " <p>Este cadastro não contempla as Empresas de Dentro do Município.</p>\n" +
                                "                        <p>Para obter acesso ao sistema com Empresas de Dentro do Município é necessário realizar o\n" +
                                "                            credenciamento junto à Prefeitura Municipal.</p>\n" +
                                "                        <p>Entre em contato com a Prefeitura para maiores informações.</p>");

                            $scope.prestador = result[0];
                        } else {
                            criarConta();
                        }
                    });
            } else {
                criarConta();
            }
        };

        function criarConta() {
            $scope.registerAccount.langKey = $translate.use();
            $scope.doNotMatch = null;
            $scope.error = null;
            $scope.errorUserExists = null;
            $scope.errorEmailExists = null;

            Auth.createAccount($scope.registerAccount).then(function () {
                if ($scope.convite && $scope.conviteValido()) {
                    Register.autorizarUsuario({
                        conviteUsuario: $scope.convite,
                        login: $scope.registerAccount.login
                    });
                }
                $scope.success = 'OK';
                Notificacao.priority('Cadastro salvo com sucesso! Favor verificar seu e-mail para confirmar a conta.');
                $state.go('login');
            }, function (response) {
                if (response.status == 400 && !$scope.registerAccount.dadosPessoais.endereco.municipio.estado) {
                    Notificacao.error("Atenção", "O campo estado é obrigatório!");
                }
                if (response.status == 406) {
                    Notificacao.warn("Atenção!", response.data.message);
                    $state.go('login');
                }
            });
        }


        $scope.conviteValido = function () {

            if ($scope.convite && $scope.convite.ativo) {
                var nowDate = new Date();
                var dataConvite = new Date($scope.convite.dataConvite);
                dataConvite.setHours(dataConvite.getHours() + 48);
                return dataConvite > nowDate;
            }
            return false;
        };

        function carregarDadosConvite() {
            if ($scope.convite) {
                if ($scope.convite && $scope.conviteValido()) {
                    $scope.registerAccount.nomeRazao = $scope.convite.nome;
                    $scope.registerAccount.login = $scope.convite.cpf;
                    $scope.registerAccount.email = $scope.convite.email;
                }
            }
        }

        $scope.loadEnderecoByCEP = function (cep) {
            if (cep) {
                CEP.getByCep({cep: cep}, function (endereco) {
                    $scope.registerAccount.dadosPessoais.cep = endereco.cep;
                    $scope.registerAccount.dadosPessoais.municipio = endereco.municipio;
                    $scope.registerAccount.dadosPessoais.logradouro = endereco.logradouro;
                    $scope.registerAccount.dadosPessoais.bairro = endereco.bairro;
                });
            }
        };

        carregarDadosConvite();

        $scope.loadMunicipioByCodigo = function (nome) {
            if (nome) {
                Municipio.getByNome({nome: $scope.registerAccount.dadosPessoais.municipio.nome}, function (result) {
                        $scope.registerAccount.dadosPessoais.municipio = result;
                    },
                    function (error) {
                        if (error.status === 404) {
                            Notificacao.warn("Atenção!", "Não foi encontrado nenhum município com essa descrição, por favor utilize a pesquisa!")
                        }
                        $scope.registerAccount.dadosPessoais.municipio = {};
                    })
            }
        };

        $scope.searchMunicipio = function () {
            var modalInstance = $modal.open({
                templateUrl: 'app/entities/municipio/municipio-search.html',
                controller: 'MunicipioSearchController',
                size: 'lg'
            });
            modalInstance.result.then(function (municipio) {
                if (!$scope.registerAccount.dadosPessoais) {
                    $scope.registerAccount.dadosPessoais = {};
                }
                $scope.registerAccount.dadosPessoais.municipio = municipio;
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
        };

        $scope.loadByCpfCnpj = function () {
            Pessoa.getPorCpfCnpj(
                {cpfCnpj: $scope.registerAccount.dadosPessoais.cpfCnpj}, function (result) {
                    if (result.dadosPessoais) {
                        $scope.registerAccount.dadosPessoais = result.dadosPessoais;
                        Notificacao.warn("Atenção", "Já existe uma Pessoa com o CPF ou CNPJ informado");
                    }
                });
        };

        $scope.isCnpj = function () {
            return $scope.registerAccount && $scope.registerAccount.dadosPessoais.cpfCnpj &&
                $scope.registerAccount.dadosPessoais.cpfCnpj.length > 11
        };

        $scope.isCpf = function () {
            return $scope.registerAccount && $scope.registerAccount.dadosPessoais.cpfCnpj &&
                $scope.registerAccount.dadosPessoais.cpfCnpj.length <= 11
        };

    })
;
