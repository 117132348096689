'use strict';

angular.module('nfseApp')
    .controller('TomadorSearchController', function ($scope, $timeout, Tomador, TomadorSearch, ParseLinks, SweetAlert, $modalInstance) {
        $scope.tomadors = [];
        $scope.page = 1;
        $scope.per_page = 5;

        $timeout(function () {
            angular.element('[name="searchQuery"]').focus();
        });


        $scope.loadAll = function () {
            Tomador.query({
                page: $scope.page,
                per_page: $scope.per_page
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.tomadors = result;
            });
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadAll();

        $scope.search = function () {
            if(!$scope.searchQuery){
                $scope.searchQuery = '';
            }
            Tomador.query({query: $scope.searchQuery},
                function (result) {
                    $scope.tomadors = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.loadAll();
                    }
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.tomador = {codigo: null, descricao: null, ativo: null, id: null};
        };

        $scope.ok = function (tomador) {
            $modalInstance.close(tomador);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
