(function () {
    'use strict';

angular.module('nfseApp')
    .controller('MongoController', function ($scope, Mongo, Notificacao) {
        $scope.collectionName;

        $scope.dropCollection = function () {
            Mongo.dropCollection({collectionName: $scope.collectionName}, function () {
                Notificacao.info("Informação!", "Drop collection efetuado com sucesso!");
            });
        };

        $scope.dropDb = function () {
            Mongo.dropDb({dbName: $scope.dbName}, function () {
                Notificacao.info("Informação!", "Drop db efetuado com sucesso!");
            });
        };
    });
})();
