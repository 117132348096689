'use strict';

angular.module('nfseApp').controller('DeclaracaoMensalServicoInstituicaoFinanceiraDetailController',
    function ($scope, entity, DeclaracaoMensalServico, PrestadorServicos, localStorageService) {
        $scope.declaracaoMensalServicoInstituicaoFinanceira = entity;
        $scope.itens = [];
        $scope.totalSaldoAnterior = 0;
        $scope.totalCredito = 0;
        $scope.totalDebito = 0;
        $scope.totalDeclarado = 0;
        $scope.totalIss = 0;
        $scope.prestador = null;

        $scope.buscarItensDeclaracaoServico = function () {
            DeclaracaoMensalServico.buscarItensDeclaracaoServico({
                    idDeclaracaoMensalServico: $scope.declaracaoMensalServicoInstituicaoFinanceira.id
                },
                function (data) {
                    $scope.itens = data;
                    $scope.itens.forEach(function (item) {
                        $scope.totalSaldoAnterior = $scope.totalSaldoAnterior + item.saldoAnterior;
                        $scope.totalCredito = $scope.totalCredito + item.credito;
                        $scope.totalDebito = $scope.totalDebito + item.debito;
                        $scope.totalDeclarado = $scope.totalDeclarado + item.valorServico;
                        $scope.totalIss = $scope.totalIss + item.iss;
                    });
                }
            );
        };

        $scope.init = function () {
            PrestadorServicos.get({id: localStorageService.get("prestadorPrincipal").prestador.id}, function (data) {
                $scope.prestador = data;
                $scope.buscarItensDeclaracaoServico();
            })
        }

        $scope.init();
    });

