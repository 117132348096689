'use strict';

angular.module('nfseApp')
    .controller('AliquotaSimplesAnesoLei1232006Controller',
        function ($scope, PrestadorServicos, ParseLinks, SweetAlert, $modalInstance, prestador) {

            $scope.anexo = {};


            PrestadorServicos.getAnexosLei1232006({}, function (data) {
                $scope.anexos = data;
                for (var i = 0; i < $scope.anexos.length; i++) {
                    var anexo = $scope.anexos[i];

                    if (prestador.enquadramentoFiscal.anexoLei1232006
                        && anexo.id === prestador.enquadramentoFiscal.anexoLei1232006.id) {
                        $scope.anexo = anexo;
                    }

                    if (prestador.rbt12) {
                        $scope.rbt12 = prestador.rbt12;
                    }
                }
            });


            $scope.selecionarFaixa = function (faixa) {
                if (!$scope.rbt12) {
                    SweetAlert.error("Atenção", "Informe o RBT12.");
                    return;
                }
                if($scope.rbt12 > faixa.valorMaximo){
                    SweetAlert.error("Atenção", "RBT12 imcompatível com a faixa selecionada.");
                    return;
                }
                $scope.anexo.faixaSelecionada = faixa;
            };


            $scope.ok = function () {
                $scope.anexo.rbt12 = $scope.rbt12;
                $modalInstance.close($scope.anexo);
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }
    )
;
