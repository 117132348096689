'use strict';

angular.module('nfseApp')
    .controller('NotaFiscalDetailController',
        function ($scope, $rootScope, $stateParams, entity, NotaFiscal) {
            $scope.notaFiscal = entity;

            NotaFiscal.getParcelasDaNota({id: $stateParams.id}, function (res) {
                $scope.parcelas = res;
            });


            $scope.load = function (id) {
                NotaFiscal.get({id: id}, function (result) {
                    $scope.notaFiscal = result;
                });
            };
            $rootScope.$on('nfseApp:notaFiscalUpdate', function (event, result) {
                $scope.notaFiscal = result;
            });
        });
