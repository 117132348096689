(function () {
    'use strict';

    angular.module('nfseApp')
        .controller('EmissaoGuiaController', function ($scope, $modalInstance, entity, Notificacao, DateUtils, ImpressaoPdf) {
            $scope.emissaoDam = entity;

            $scope.imprimirDam = function () {
                if ($scope.emissaoDam.vencimentoDam == null) {
                    Notificacao.warn("Atenção", "O campo Vencimento da Guia deve ser informado.")
                } else if (DateUtils.zerarTempo($scope.emissaoDam.vencimentoDam).getTime() < DateUtils.zerarTempo(new Date()).getTime()) {
                    Notificacao.warn("Atenção", "O Vencimento da Guia não pode ser inferior a hoje.")
                } else {
                    ImpressaoPdf.imprimirPdfViaPost('/api/imprimir-dam-declaracao', $scope.emissaoDam);
                    $scope.cancel();
                }
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        })
})();
