'use strict';

angular.module('nfseApp')
    .controller('NotaFiscalController',
        function ($scope, $window, NotaFiscal, NotaFiscalSearch, ParseLinks,
                  localStorageService, SweetAlert, $state, ImpressaoPdf, $modal, ExportarXls, moment,
                  $http, EnumCacheService, Notificacao, BloqueioEmissaoNfse) {
            $scope.notaFiscals = [];
            $scope.per_page = 20;
            $scope.orderBy;
            $scope.prestadorPrincipal = localStorageService.get("prestadorPrincipal");
            $scope.situacoes = EnumCacheService.carregarValuesEnum("SituacaoDeclaracaoNfseDTO");


            $scope.buscarOperacoes = function (tipo) {
                var operacoes = [];
                operacoes.push({nome: 'Igual', value: 'IGUAL'});
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Maior ou igual', value: 'MAIOR_IGUAL'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Menor ou igual', value: 'MENOR_IGUAL'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Menor', value: 'MENOR'});
                }
                if (tipo === 'inteiro' || tipo === 'decimal' || tipo === 'data') {
                    operacoes.push({nome: 'Maior', value: 'MAIOR'});
                }
                if (tipo === 'texto' || tipo === 'cpfcnpj') {
                    operacoes.push({nome: 'Contém', value: 'LIKE'});
                }
                return operacoes;
            };

            $scope.filtrosDisponiveis = [
                {
                    nomeAtributo: 'lower(dptnf.nomerazaosocial)',
                    tipo: 'texto',
                    descricao: 'Tomador - Nome/Razão Social',
                    operacoes: $scope.buscarOperacoes('texto')
                },
                {
                    nomeAtributo: 'dptnf.cpfcnpj',
                    tipo: 'cpfcnpj',
                    descricao: 'Tomador - CPF/CNPJ',
                    operacoes: $scope.buscarOperacoes('cpfcnpj')
                },
                {
                    nomeAtributo: 'nf.numero',
                    tipo: 'inteiro',
                    descricao: 'Número da Nota',
                    operacoes: $scope.buscarOperacoes('inteiro')
                },
                {
                    nomeAtributo: 'coalesce(nf.homologacao, false)',
                    tipo: 'logico',
                    descricao: 'Homologação',
                    operacoes: $scope.buscarOperacoes('logico')
                },
                {
                    nomeAtributo: 'dps.totalservicos',
                    tipo: 'decimal',
                    descricao: 'Valor dos Serviços',
                    operacoes: $scope.buscarOperacoes('decimal')
                },
                {
                    nomeAtributo: 'dps.isscalculado',
                    tipo: 'decimal',
                    descricao: 'Valor do Iss',
                    operacoes: $scope.buscarOperacoes('decimal')
                },
                {
                    nomeAtributo: 'rps.numero',
                    tipo: 'texto',
                    descricao: 'Número do RPS',
                    operacoes: $scope.buscarOperacoes('inteiro')
                },
                {
                    nomeAtributo: "date_trunc('day', nf.emissao)",
                    tipo: 'data',
                    descricao: 'Emissão',
                    operacoes: $scope.buscarOperacoes('data')
                },
                {
                    nomeAtributo: "dps.situacao",
                    tipo: 'enum',
                    descricao: 'Situação',
                    operacoes: $scope.buscarOperacoes('enum'),
                    values: $scope.situacoes
                }
            ];

            $scope.filtrosSelecionados = [];

            $scope.validarFiltros = function () {
                for (var i = 0; i < $scope.filtrosSelecionados.length; i++) {
                    if ($scope.filtrosSelecionados[i].atributo == null) {
                        SweetAlert.warning("Filtro Personalizado inválido!", "Selecione uma opção de filtro.");
                        return false;
                    }
                    if (!$scope.filtrosSelecionados[i].operacao) {
                        SweetAlert.warning("Filtro Personalizado inválido!", "Selecione um operador de filtro.");
                        return false;
                    }
                }
                return true;
            };

            $scope.montarConsultaNotaFiscal = function () {
                var campos = [];

                campos.push({
                    campo: "nf.prestador_id",
                    operador: "IGUAL",
                    valorLong: $scope.prestadorPrincipal.prestador.id
                });

                var contemHomologacao = false;
                angular.forEach($scope.filtrosSelecionados, function (filtro, index) {
                    if (filtro.atributo.nomeAtributo === 'coalesce(nf.homologacao, false)')
                        contemHomologacao = true;
                    if (filtro.atributo.tipo === 'texto' && !filtro.valorString)
                        filtro.valorString = "";
                    campos.push(
                        {
                            operador: filtro.operacao,
                            campo: filtro.atributo.nomeAtributo,
                            valorString: (filtro.operacao !== 'LIKE' ? filtro.valorString : '%' + filtro.valorString.trim().toLowerCase() + '%'),
                            valorDate: filtro.valorData,
                            valorLogico: filtro.valorLogico,
                            valorDecimal: filtro.valorDecimal,
                            valorInteger: filtro.valorInteger
                        }
                    );
                });

                if (!contemHomologacao)
                    campos.push({
                        operador: 'IGUAL',
                        campo: 'coalesce(nf.homologacao, false)',
                        valorLogico: false
                    });

                if (!$scope.orderBy) {
                    $scope.orderBy = "nf.numero";
                    $scope.sortReverse = true;
                }

                return {
                    offset: $scope.page,
                    limit: $scope.per_page,
                    parametrosQuery: [{
                        juncao: " and ",
                        parametroQueryCampos: campos
                    }],
                    orderBy: " order by " + $scope.orderBy + " " + ($scope.sortReverse ? 'desc' : 'asc')
                };
            };

            $scope.getTotalIss = function () {
                var totalIss = 0;
                angular.forEach($scope.notaFiscals, function (value, key) {
                    totalIss += value.iss;
                });
                return totalIss;
            };

            $scope.loadAll = function () {
                if (!$scope.validarFiltros()) {
                    return;
                }
                var consultaNotaFiscal = $scope.montarConsultaNotaFiscal();

                NotaFiscal.buscarNotasFiscais(consultaNotaFiscal,
                    function (result, headers) {
                        if (headers)
                            $scope.links = ParseLinks.parse(headers('link'));
                        $scope.notaFiscals = result;
                        $scope.getTotalIss();
                    });
            };

            $scope.loadAll();

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };


            $scope.cancelarNota = function (nota) {
                NotaFiscal.cancelarNota(nota, function () {
                    $scope.loadAll();
                });
            };

            $scope.copiarNota = function (nota) {
                SweetAlert.swal({
                        title: "Confirme a cópia da Nota Fiscal",
                        text: "Tem certeza que quer copiar a Nota Fiscal de N° " + nota.numero + " para a competência atual? ",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#F7A54A", confirmButtonText: "Sim, Copiar Nota Fiscal",
                        cancelButtonText: "Não, Cancelar",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $state.go('notaFiscal.copy', ({id: nota.id}));
                            SweetAlert.info("Nota Fiscal Copiada!", "Prossiga para a emissão da Nota Fiscal.", "success");
                        } else {
                            SweetAlert.info("Cancelado", "A Nota Fiscal não foi copiada.", "error");
                        }
                        SweetAlert.close();
                    }
                )
            };

            $scope.delete = function (id) {
                NotaFiscal.get({id: id}, function (result) {
                    $scope.notaFiscal = result;
                    SweetAlert.swal({
                            title: "Confirme a exclusão",
                            text: "Você tem certeza que quer excluir a Nota Fiscal?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55", confirmButtonText: "Sim, Excluir",
                            cancelButtonText: "Não, Cancelar",
                            closeOnConfirm: false,
                            closeOnCancel: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $scope.confirmDelete(id);
                            } else {
                                SweetAlert.info("Cancelado", "A Nota Fiscal não foi removida :)", "error");
                            }
                        });

                });
            };

            $scope.imprime = function (id) {
                NotaFiscal.imprime({id: id});
            };

            $scope.confirmDelete = function (id) {
                NotaFiscal.delete({id: id},
                    function () {
                        $scope.loadAll();
                        SweetAlert.info("Pronto!", "A Nota Fiscal foi removida com sucesso.", "success");
                    });
            };

            $scope.refresh = function () {
                $scope.loadAll();
                $scope.clear();
            };

            $scope.clear = function () {
                $scope.notaFiscal = {
                    numero: null, codigoVerificacao: null, id: null,
                    declaracaoPrestacaoServico: {prestador: localStorageService.get("prestadorPrincipal")}
                };
            };


            $scope.imprimirNota = function (id) {
                ImpressaoPdf.imprimirPdfViaUrl('/publico/notaFiscal/imprime/' + id);
            };

            $scope.baixarXMlNotaFiscal = function (idNotaFiscal) {
                NotaFiscal.downloadXmlNotaFiscal(idNotaFiscal);
            };

            $scope.baixarXMl = function () {
                NotaFiscal.baixarXml($scope.montarConsultaNotaFiscal(), function (result) {
                    var a = document.createElement('a');
                    a.href = 'data:application/xml;base64,' + result.conteudo;
                    a.target = '_blank';
                    a.download = 'lote-notas-fiscais.xml';
                    document.body.appendChild(a);
                    a.click();
                });
            };

            $scope.baixarPDFResumido = function () {
                $scope.baixarPDF("RESUMIDO");
            };

            $scope.baixarPDFDetalhado = function () {
                $scope.baixarPDF("DETALHADO");
            };

            $scope.baixarPDF = function (tipoRelatorio) {
                $scope.orderBy = "dptnf.nomerazaosocial";
                $scope.sortReverse = false;
                var emissao = {
                    consultaNotaFiscal: $scope.montarConsultaNotaFiscal(),
                    criteriosUtilizados: "",
                    tipoNota: "EMITIDA",
                    tipoRelatorio: tipoRelatorio
                };
                ImpressaoPdf.imprimirPdfViaPost('/api/notaFiscals/relatorio-notas-fiscais', emissao);
            };

            $scope.baixarXls = function () {
                $scope.orderBy = "dptnf.nomerazaosocial";
                $scope.sortReverse = false;
                var emissao = {

                    consultaNotaFiscal: $scope.montarConsultaNotaFiscal(),
                    criteriosUtilizados: "",
                    tipoNota: "EMITIDA",
                    tipoRelatorio: "RESUMIDO"
                };
                $http.post('api/notaFiscals/relatorio-notas-fiscais-xls', emissao, {responseType: 'arraybuffer'}).then(function (response) {
                    if (response.data.byteLength == 0) {
                        Notificacao.warn('Atenção', 'Nenhum registro para ser impresso.');
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/excel"
                        });
                        saveAs(blob, "NotasFiscais.xls");
                    }
                });
            };

            $scope.enviarNota = function (nota) {
                var modalEnviarNota = $modal.open({
                    templateUrl: 'app/entities/notaFiscal/enviar-nota-email.html',
                    controller: 'EnviarNotaEmailController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return nota
                        }
                    }
                });
                modalEnviarNota.result.then(function (data) {
                    if (data) {
                    }
                });
            };


            $scope.cartaCorrecaoNota = function (nota) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/entities/notaFiscal/carta-correcao/carta-correcao.html',
                    controller: 'CartaCorrecaoController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return nota
                        }
                    }
                });
                modalInstance.result.then(function (notaFiscal) {
                    SweetAlert.success("Operação realizada", "Carta de Correção enviada!");
                    $scope.loadAll();
                });
            };


            $scope.adicionarFiltro = function () {
                var filtro = {};
                $scope.filtrosSelecionados.push(filtro);
            };

            $scope.removerFiltro = function (index) {
                $scope.filtrosSelecionados.splice(index, 1);
            };

            $scope.selecionouCampo = function (filtro) {
                filtro.valorString = null;
                filtro.valorDecimal = null;
                filtro.valorInteger = null;
                filtro.valorLogico = false;
                filtro.valorData = null;
            };

            $scope.getTotalServicos = function () {
                var totalIss = 0;
                angular.forEach($scope.notaFiscals, function (value, key) {
                    totalIss += value.totalServicos;
                });
                return totalIss;
            };

            $scope.getTotalBaseCalculo = function () {
                var totalIss = 0;
                angular.forEach($scope.notaFiscals, function (value, key) {
                    totalIss += value.baseCalculo;
                });
                return totalIss;
            }

            $scope.emitirNotaFiscal = function () {
                NotaFiscal.emitirNotaFiscal();
            }
        });
